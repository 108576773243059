import React, { forwardRef, useEffect, useState } from 'react'
import { BoldContentTitle, ContentTitle, ContentWrapper, DescriptionText, TextAreaWrapper } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { TextInput } from 'react-native'
import { CustomTextArea } from 'components/shared/customTextArea'
import { useAppSelector } from 'hooks/redux'

interface ICancelModalProps {
  onPress: () => void
  isLoading: boolean
  isVisible: boolean
}
const CancelModalContent = forwardRef<TextInput, ICancelModalProps>(({ isLoading, isVisible, onPress }, ref) => {
  const modalData = useAppSelector((store) => store.waitlist.selectedTurn)
  const customerName = modalData && `${modalData.customer?.firstName} ${modalData.customer?.lastName}`.trim()

  const [textAreaValue, setTextAreaValue] = useState('')
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)

  useEffect(() => {
    isVisible && setTextAreaValue('')
    isVisible && setIsConfirmDisabled(false)
  }, [isVisible])

  const handleConfirmPress = () => {
    setIsConfirmDisabled(true)
    onPress()
  }

  return (
    <ContentWrapper space="24px">
      <ContentTitle>
        <BoldContentTitle>{customerName}</BoldContentTitle>
        ´s turn will be cancelled.
      </ContentTitle>
      <DescriptionText>
        The system will notify about the cancel action and remove the customer from the waitlist. Please send a message.
      </DescriptionText>
      <TextAreaWrapper>
        <CustomTextArea
          value={textAreaValue}
          onChangeText={setTextAreaValue}
          refProp={ref}
        />
      </TextAreaWrapper>
      <ModalButton isLoading={isLoading} onPress={handleConfirmPress} text='Confirm' isDisabled={isConfirmDisabled} />
    </ContentWrapper>
  )
})

CancelModalContent.displayName = 'CancelModalContent'

export default CancelModalContent
