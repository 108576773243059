import React from 'react'
import Svg, { Ellipse, Rect, SvgProps } from 'react-native-svg'
import colors from '../../themes/colors'
interface props {
  iconProps?: SvgProps
}

const LogoIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...iconProps}>
    <Rect
      width="7.73866"
      height="7.73866"
      transform="matrix(0.716332 -0.69776 0.716332 0.69776 1.64258 7)"
      fill={iconProps?.fill || colors.primary}
    />
    <Ellipse
      cx="12.413"
      cy="1.90909"
      rx="1.9599"
      ry="1.90909"
      fill={iconProps?.fill || colors.primary}
    />
    <Ellipse
      cx="1.9599"
      cy="12.0909"
      rx="1.9599"
      ry="1.90909"
      fill={iconProps?.fill || colors.primary}
    />
  </Svg>
)

LogoIcon.defaultProps = {
  iconProps: {}
}

export default LogoIcon
