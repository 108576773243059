import colors from '@instaseat/lib/themes/colors'
import { Text, VStack, WarningIcon } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding-inline: 24px;
  padding-block: 32px;
  align-items: center;
`

export const ContentTitle = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const BoldContentTitle = styled(ContentTitle)`
  font-weight: 700;
`

export const DescriptionText = styled(ContentTitle)`
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 85%;
`

export const WarnIcon = styled(WarningIcon)`
  color: ${colors.tertiary};
  width: 40px;
  height: 40px;
`
