import React from 'react'
import { ActionRowContainer } from './styled'
import { Pressable, View } from 'native-base'
import WaitlistActionButton from 'components/waitlistActionButton'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import { useDispatch } from 'react-redux'
import { showModal } from 'store/modules/Modals'
import { setSelectedTurn } from 'store/modules/Waitlist'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { TurnStatus } from '@instaseat/lib/enums/turn'

interface Props {
  hideActionRow: () => void,
  turnData: PopulatedWaitlistItem
}

const ActionRow = ({ hideActionRow, turnData }: Props) => {
  const dispatch = useDispatch()

  const handleNotify = () => {
    dispatch(setSelectedTurn(turnData))
    dispatch(showModal('notify'))
  }

  const handleCancel = () => {
    dispatch(setSelectedTurn(turnData))
    dispatch(showModal('cancel'))
  }

  const handleSeat = () => {
    dispatch(setSelectedTurn(turnData))
    dispatch(showModal('confirmation'))
  }

  return (
    <ActionRowContainer>
      <WaitlistActionButton
        buttonType='notify'
        onPress={() => handleNotify()}
        isDisabled={turnData.notifyCount >= 5 || turnData.status === TurnStatus.customerOnItsWay}
      />
      <WaitlistActionButton buttonType='cancel' onPress={() => handleCancel()} />
      <WaitlistActionButton buttonType='seat' onPress={() => handleSeat()} />
      <Pressable
        onPress={() => {
          hideActionRow()
        }}
      >
        <View
          padding="10px"
        >
          <CustomIcons
            iconName={IconName.CancelledIcon}
            iconProps={{
              fill: colors.webModalGreyText,
              height: '14px',
              width: '14px'
            }}
          />
        </View>
      </Pressable>
    </ActionRowContainer>
  )
}

export default ActionRow
