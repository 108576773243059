/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'native-base'
import { FiltersButton, FiltersButtonText } from './styled'
import colors from '@instaseat/lib/themes/colors'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import 'react-dates/initialize'
import './react_dates_styles.css'
import { DateRangePicker } from 'react-dates'
import { setHistoryFilter } from 'store/modules/Waitlist'
import moment from 'moment'

interface IDatesProps {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

const HistoryDatePicker = () => {
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const initialValues = {
    startDate: null,
    endDate: null
  }

  const handleFiltersPress = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      setFocusedInput(null)
      setStartDate(null)
      setEndDate(null)
      dispatch(setHistoryFilter(initialValues))
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setFocusedInput(null)
      setStartDate(null)
      setEndDate(null)
      dispatch(setHistoryFilter(initialValues))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen])

  useEffect(() => {
    const payload = getDateRange()
    dispatch(setHistoryFilter(payload || initialValues))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [startDate, endDate, dispatch])

  const getDateRange = () => {
    if (!startDate || !endDate) { return }
    const dateRange = {
      startDate: startDate.toDate().toUTCString(),
      endDate: endDate.toDate().toUTCString()
    }
    return dateRange || ''
  }

  return (
    <View zIndex={3} width="100%" flexDir="row" marginBottom={30}>
      <FiltersButton onPress={() => handleFiltersPress()}>
        <FiltersButtonText>
          {isOpen ? 'Clear filters' : 'Create filter'}
        </FiltersButtonText>
        <View mt="2px">
          <CustomIcons
            iconName={isOpen ? IconName.CancelledIcon : IconName.PlusIcon2}
            iconProps={{ fill: colors.black, height: '16px', width: '16px' }}
          />
        </View>
      </FiltersButton>
      {isOpen && (
      <DateRangePicker
        startDate={startDate}
        startDateId="start-date"
        endDate={endDate}
        endDateId="end-date"
        minimumNights={0}
        isOutsideRange={() => false}
        onDatesChange={({ startDate, endDate }: IDatesProps) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
        editable={false}
        readOnly
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: 'startDate' | 'endDate' | null) => setFocusedInput(focusedInput)}
      />
      )}
    </View>
  )
}

export default HistoryDatePicker
