import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
  viewBox?: string
}

const WaitlistIcon = ({ iconProps, viewBox }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg viewBox={viewBox} fill="transparent" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M6.7899 10.0013L8.39557 8.3967L10.0004 6.79186L11.6042 8.39567L13.2109 10.0013L19.4011 3.81118C19.6987 3.48566 19.8953 3.08065 19.967 2.64543C20.0386 2.21022 19.9823 1.76355 19.8048 1.35977C19.6273 0.955986 19.3362 0.6125 18.9671 0.371091C18.5979 0.129682 18.1666 0.00075692 17.7255 0H2.27348C1.83423 0.000100717 1.40441 0.127411 1.03597 0.366549C0.667527 0.605687 0.376227 0.946416 0.197275 1.34756C0.0183233 1.7487 -0.040618 2.19308 0.027568 2.627C0.095754 3.06092 0.288152 3.46581 0.581511 3.79273L0.754953 3.96638L1.33657 4.548L6.7899 10.0013Z"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#A19A98'}
      fill={iconProps?.fill}
    />
    <Path
      // eslint-disable-next-line max-len
      d="M13.2109 10.0015L11.606 11.6071L10.0004 13.212L8.39369 11.6063L6.78988 10.0015L1.33839 15.4527L0.775835 16.0163L0.564059 16.2289L0.559548 16.2334C0.272904 16.5625 0.0870112 16.9671 0.0240742 17.3989C-0.0388627 17.8307 0.0238156 18.2715 0.204618 18.6687C0.38542 19.0658 0.676705 19.4026 1.0437 19.6387C1.4107 19.8748 1.83789 20.0002 2.27427 20.0001H17.7263C18.1649 20.0002 18.5942 19.8734 18.9623 19.6349C19.3304 19.3965 19.6217 19.0567 19.8011 18.6564C19.9804 18.2561 20.0401 17.8125 19.973 17.379C19.9059 16.9456 19.7148 16.5408 19.4228 16.2135L13.2109 10.0015Z"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#A19A98'}
      strokeMiterlimit="10"
      fill={iconProps?.fill}
    />
  </Svg>
)

WaitlistIcon.defaultProps = {
  iconProps: {},
  viewBox: '-0.4 0 24 24'
}

export default WaitlistIcon
