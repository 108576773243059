export enum IconName {
  MessageIcon = 'MessageIcon',
  ProfileIcon = 'ProfileIcon',
  RestaurantIcon = 'RestaurantIcon',
  WaitlistIcon = 'WaitlistIcon',
  CheckIcon = 'CheckIcon',
  WarningIcon = 'WarningIcon',
  GoToIcon = 'GoToIcon',
  ClockIcon = 'ClockIcon',
  CustomersIcon = 'CustomersIcon',
  AverageTimeIcon = 'AverageTimeIcon',
  NoTurnsIcon = 'NoTurnsIcon',
  BackButtonIcon = 'BackButtonIcon',
  ShowMoreIcon = 'ShowMoreIcon',
  HeartIcon = 'HeartIcon',
  NoRestaurantsIcon = 'NoRestaurantsIcon',
  SearchIcon = 'SearchIcon',
  CancelledIcon = 'CancelledIcon',
  DragIcon = 'DragIcon',
  LogoIcon = 'LogoIcon',
  SeeMoreIcon = 'SeeMoreIcon',
  HostOnItsWayIcon = 'HostOnItsWayIcon',
  EyeClosedIcon = 'EyeClosedIcon',
  EyeOpenedIcon = 'EyeOpenedIcon',
  UnlockIcon = 'UnlockIcon',
  PlusIcon = 'PlusIcon',
  PlusIcon2 = 'PlusIcon2',
  NotifyIcon = 'NotifyIcon',
  RemoveIcon = 'RemoveIcon'
}
