/* eslint-disable no-redeclare */
/* eslint-disable no-useless-escape */
const regex = {
  e164: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
}

const formatPhoneNumber = (number: string) => {
  if (number) {
    const formattedNumber = number.replace(
      /[`~!@#$%^&*()_|+\-=?;:'",.<>( )A-Za-z]/gi,
      ''
    )
    return formattedNumber
  }
}

const formatSecondsToMinutes = (seconds: number) => {
  const countdownText = `${Math.floor(seconds / 60)}:${
    seconds % 60 >= 10 ? seconds % 60 : '0' + (seconds % 60)
  }`
  return countdownText
}

interface IRoundCustomerWaitTime {
  minutes: number
  hours?: number
}

function roundCustomerWaitTime (waitTime: number, roundBase: number): number;
function roundCustomerWaitTime
  (waitTime: IRoundCustomerWaitTime, roundBase: number): {rMinutes: number, rHours: number};
function roundCustomerWaitTime (waitTime: number | IRoundCustomerWaitTime, roundBase = 10) {
  if (!waitTime || !roundBase) return 0

  if (typeof waitTime === 'number') return Math.ceil(waitTime / roundBase) * roundBase
  const { minutes, hours = 0 } = waitTime
  let rMinutes = Math.ceil(minutes / roundBase) * roundBase
  let rHours = hours
  if (rMinutes > 60) {
    rHours += 1
    rMinutes -= 60
  }
  return { rMinutes, rHours }
}

export { regex, formatPhoneNumber, formatSecondsToMinutes, roundCustomerWaitTime }
