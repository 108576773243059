import React from 'react'
import { CustomModal } from '../shared/modal'
import ReturnToWaitlistContent from './content/Content'
import { useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import { ReturnIcon } from './styled'
import { useDispatch } from 'store'
import { returnTurnToWaitlist } from 'store/modules/Turns'
import { ToastStatus } from 'components/customToast'
import { showToast } from 'utils/toastHandler'

const header = {
  title: 'Return to waitlist',
  IconComponent: <ReturnIcon />
}

const ReturnToWaitlistModal = () => {
  const showCancelModal = useAppSelector((store) => store.modals.showModal) === 'return'
  const dispatch = useDispatch()

  const customerName = useAppSelector((store) => store.modals.modalText.contentText)
  const turnID = useAppSelector((store) => store.turns.turnID)
  const isLoading = useAppSelector((store) => store.turns.isFetching)

  const handleReturn = async () => {
    const response = await dispatch(returnTurnToWaitlist(turnID))
    if (response === 'RETURNED TO THE WAITLIST') {
      showToast({ description: 'Turn returned to the waitlist', title: 'Success', status: ToastStatus.success })
    } else {
      showToast({
        description: 'There was an error returning the turn to the waitlist',
        title: 'Error',
        status: ToastStatus.error
      })
    }
    dispatch(hideModal())
  }

  return (
    <CustomModal
      showModal={showCancelModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={(
        <ReturnToWaitlistContent
          customerName={customerName}
          onPress={handleReturn}
          isLoading={isLoading}
          isVisible={showCancelModal}
        />
      )}
      closeOnOverlayClick={false}
    />
  )
}

export { ReturnToWaitlistModal }
