import styled from 'styled-components'
import colors from '@instaseat/lib/themes/colors'
import { Text, View, Image, Pressable } from 'native-base'
import { Linking } from 'react-native'

export const Container = styled(View).attrs({
  bg: colors.white,
  h: '100%',
  alignItems: 'center',
  justifyContent: 'center'
})``

export const BadgesContainer = styled(View).attrs({
  w: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap'
})``

export const TitleText = styled(Text).attrs({
  fontFamily: 'Lato',
  fontSize: '24px',
  marginBottom: '5%'
})``

export const AppleBadgeContainer = styled(Pressable).attrs({
  bg: colors.black,
  width: 134,
  borderRadius: '5px',
  onPress: () => Linking.openURL(process.env.REACT_APP_APP_STORE_URL as string)
})``

export const GooglePlayBadgeContainer = styled(Pressable).attrs({
  onPress: () => Linking.openURL(process.env.REACT_APP_PLAY_STORE_URL as string)
})``

export const LogoImg = styled(Image).attrs({
  accessibilityLabel: 'Logo',
  width: '100%',
  resizeMode: 'contain',
  height: '45px',
  alt: 'InstaSeat',
  marginBottom: '20%'
})`
`
