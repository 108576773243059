import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { H1, Subtitle, SignInForm } from '@instaseat/lib/components'
import { IHostSubmitData } from '@instaseat/lib/interfaces/host'
import { RootState } from 'store'
import { signInHostWithCredentials } from 'store/modules/auth/thunks'
import { clearSignInHostFlags } from 'store/modules/auth'
import { showModal } from 'store/modules/Modals'
import { Container, Header, BoxStyled } from './styled'
import { useWindowDimensions } from 'react-native'

const MOBILE_WIDTH_LIMIT = 505

const SignIn = () => {
  const dispatch = useDispatch()
  const { error, isFetching } = useSelector((store: RootState) => store.auth)
  const { width } = useWindowDimensions()

  const isMobile = width < MOBILE_WIDTH_LIMIT

  const handleSubmit = (params: IHostSubmitData) => {
    dispatch(signInHostWithCredentials({ email: params.email, password: params.password }))
  }

  const handleForgotPasswordClick = () => {
    dispatch(showModal('forgotPassword'))
  }

  const handleClearError = () => {
    dispatch(clearSignInHostFlags())
  }

  return (
    <Container>
      <BoxStyled>
        <Header>
          <H1 style={isMobile && { fontSize: 40 }}>Welcome.</H1>
          <Subtitle style={isMobile && { fontSize: 20 }}>Sign In</Subtitle>
        </Header>
        <SignInForm
          isFetching={isFetching}
          error={error}
          submit={handleSubmit}
          forgotPasswordClick={handleForgotPasswordClick}
          clearError={handleClearError}
        />
      </BoxStyled>
    </Container>
  )
}

export default SignIn
