import React from 'react'
import Svg, { Path, SvgProps, G } from 'react-native-svg'
import colors from '../../themes/colors'
interface props {
  iconProps?: SvgProps
}

const RemoveIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg viewBox="0 0 24 24" {...iconProps}>
    <G>
      <Path
        fill={iconProps?.fill || colors.primary}
        d={'M5.11111 19.7778C5.11111 21 6.11111 22 7.33333 22H16.2222C17.4444 22 18.4444 21 18.4444 19.7778V6.' +
        '44444H5.11111V19.7778ZM19.5556 3.11111H15.6667L14.5556 2H9L7.88889 3.11111H4V5.33333H19.5556V3.11111Z'}
      />
    </G>
  </Svg>
)

RemoveIcon.defaultProps = {
  iconProps: {}
}

export default RemoveIcon
