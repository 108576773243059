import React from 'react'
import { CloseIcon, Toast, Alert } from 'native-base'
import {
  CloseButton,
  StyledAlert,
  ToastDescription,
  ToastHeaderWrapper,
  ToastTitle,
  ToastTitleWrapper,
  ToastWrapper
} from './styled'

export enum ToastStatus {
  info = 'info',
  success= 'success',
  warning = 'warning',
  error = 'error'
}

interface IToastAlertProps {
  id?: string,
  status?: ToastStatus,
  title: string,
  description: string,
}

export const CustomToast = ({
  id,
  status,
  title,
  description
}: IToastAlertProps) => (
  <StyledAlert
    status={status}
  >
    <ToastWrapper>
      <ToastHeaderWrapper>
        <ToastTitleWrapper>
          <Alert.Icon />
          <ToastTitle>
            {title}
          </ToastTitle>
        </ToastTitleWrapper>
        <CloseButton
          icon={<CloseIcon size="3" />}
          onPress={() => id && Toast.close(id)}
        />
      </ToastHeaderWrapper>
      <ToastDescription>
        {description}
      </ToastDescription>
    </ToastWrapper>
  </StyledAlert>
)

CustomToast.defaultProps = {
  id: undefined,
  status: ToastStatus.info
}
