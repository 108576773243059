import { UserTypes } from '@instaseat/lib/enums/users'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPLICATION_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const getCurrentFirebaseToken = async (forceRefresh = false): Promise<string> => {
  const token = await auth.currentUser?.getIdToken(forceRefresh)
  return token || ''
}

const useGetUserType = () => {
  const [userType, setUserType] = useState<UserTypes | ''>('')

  const getCurrentUser = async () => {
    const user = await getAuth().currentUser?.getIdTokenResult()
    setUserType((user?.claims?.userType as UserTypes) || '')
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  return userType
}

export { auth, getCurrentFirebaseToken, useGetUserType }
