/* eslint-disable max-len */
import React from 'react'
import Svg, { Path } from 'react-native-svg'

const EditIcon = () => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill="#B21B55" />
    <Path d="M11.3327 6.41342C11.3329 6.36955 11.3245 6.32606 11.3079 6.28545C11.2914 6.24484 11.2669 6.2079 11.236 6.17675L9.82269 4.76342C9.79154 4.73253 9.7546 4.70808 9.71399 4.6915C9.67338 4.67491 9.62989 4.6665 9.58602 4.66675C9.54215 4.6665 9.49866 4.67491 9.45805 4.6915C9.41744 4.70808 9.3805 4.73253 9.34935 4.76342L8.40602 5.70675L4.76269 9.35009C4.73179 9.38123 4.70735 9.41817 4.69076 9.45878C4.67418 9.4994 4.66577 9.54288 4.66602 9.58675V11.0001C4.66602 11.0885 4.70114 11.1733 4.76365 11.2358C4.82616 11.2983 4.91095 11.3334 4.99935 11.3334H6.41269C6.45933 11.336 6.50599 11.3287 6.54963 11.312C6.59327 11.2954 6.63292 11.2697 6.66602 11.2368L10.2894 7.59342L11.236 6.66675C11.2664 6.63445 11.2912 6.59726 11.3094 6.55675C11.3126 6.53018 11.3126 6.50332 11.3094 6.47675C11.3109 6.46124 11.3109 6.4456 11.3094 6.43009L11.3327 6.41342ZM6.27602 10.6668H5.33269V9.72342L8.64269 6.41342L9.58602 7.35675L6.27602 10.6668ZM10.056 6.88675L9.11269 5.94342L9.58602 5.47342L10.526 6.41342L10.056 6.88675Z" fill="#E9E4E3" />
  </Svg>

)

export default EditIcon
