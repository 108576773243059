import colors from '@instaseat/lib/themes/colors'
import { SmallCloseIcon, Text, View } from 'native-base'
import styled from 'styled-components/native'

export const BusinessTitle = styled(Text)`
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 36px;
  color: ${colors.primary}
`
export const Label = styled(Text)`
  font-family: Lato;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
`
export const ButtonWrapperLarge = styled(View)`
  height: 100px;
  width: 465px;
  padding-bottom: 24px;
  padding-right: 24px;
`
export const BusinessContainer = styled(View)`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 272px;
  width: 100%;
  padding: 24px;
  margin-top: 25px;
  display: flex;
`
export const ButtonContainer = styled(View)`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
export const ButtonWrapper = styled(View)`
  height: 100px;
  padding-bottom: 24px;
  padding-right: 24px;
`
export const ButtonList = styled(View)`
  display: flex;
  flex-wrap: wrap;
  width: 76%;
  flex-direction: row;
  justify-content: flex-end;
`
export const ButtonListBusiness = styled(View)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
`
export const InputRightAddonStyled = styled(Text)`
  display: "flex";
  flex-direction: "row";
  padding-right: 8px;
  font-style: italic;
  opacity: 0.6;
`

export const BusinessScheduleList = styled(View)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

export const AddBusinessSchedule = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const ScheduleContainer = styled(View)`
  padding-bottom: 24px;
  margin-right: 25px;
  width: 200px;
  display: flex;
  align-items: flex-start;
`

export const ScheduleTitle = styled(Text)`
font-family: Lato;
font-size: 18px;
font-style: italic;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
padding-bottom: 8px;
text-align: center;
color: "#210F0B";
opacity: 0.5;
`

export const ScheduleItems = styled(Text)`
font-family: Lato;
font-size: 15px;
color: "#210F0B";
opacity: 0.5;
font-style: italic;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
padding-bottom: 4px;
padding-left: 16px;
`

export const DeleteIcon = styled(SmallCloseIcon).attrs({
  color: '#FFF'
})`
  width: 18px;
  height: 18px;
`
