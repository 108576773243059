import React, { useEffect } from 'react'
import { Control, Controller, Path, PathValue, UseFormSetValue } from 'react-hook-form'
import { CheckButtonPressable, PreferenceBox, PreferenceBoxContainer } from './styled'

export interface CheckButtonProps<TFormValues> {
  control: Control<TFormValues>
  name: Path<TFormValues>
  isVisible?: boolean
  preferenciesWatch: {[k: string]: boolean};
  setValue: UseFormSetValue<TFormValues>;
}

const helperDays = {
  Mon: false,
  Tue: false,
  Wed: false,
  Thu: false,
  Fri: false,
  Sat: false,
  Sun: false
}

const CheckButton = <TFormValues extends
{[key: string] : string | number | null | { [k: string]: boolean}}>({
    control,
    name,
    isVisible,
    preferenciesWatch,
    setValue
  }:CheckButtonProps<TFormValues>) => {
  const [checked, setChecked] = React.useState(false)
  useEffect(() => {
    isVisible && setChecked(false)
  }, [isVisible])

  useEffect(() => {
    setChecked(preferenciesWatch[name.slice(12)])
  }, [name, preferenciesWatch])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <CheckButtonPressable
            onPress={() => {
              setChecked((prevValue) => {
                setValue('preferences' as Path<TFormValues>,
                {
                  ...helperDays,
                  [name.slice(12)]: !prevValue
                } as PathValue<TFormValues, Path<TFormValues>>)
                onChange(!prevValue)
                return !checked
              })
            }}
          >
            <PreferenceBoxContainer selected={checked}>
              <PreferenceBox selected={checked}>{name.split('.')[1]}</PreferenceBox>
            </PreferenceBoxContainer>
          </CheckButtonPressable>
        )
      }}
    />

  )
}

CheckButton.defaultProps = {
  isVisible: undefined
}

export default CheckButton
