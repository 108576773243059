import styled from 'styled-components/native'
import { Center, Text } from 'native-base'
import { ModalPressable } from 'components/shared/modalButton'

export const Wrapper = styled(Center)`
  width: 100%;
  position: absolute;
  bottom: 5%;
`

export const Title = styled(Text)`
  background-color: #FFFA;
  font-family: "Lato";
  width: 60%;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding-bottom: 24px;
`

export const SignOutButton = styled(ModalPressable).attrs({
  borderRadius: '16px',
  size: 'lg'
})`
  width: 100px;
`

export const ButtonText = styled(Text)`
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`
