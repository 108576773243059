import { Reducer } from 'redux'
import {
  HIDE_MODAL,
  SHOW_MODAL,
  SET_MODAL_TEXT
} from './constants'
import { ModalsActionTypes, ModalsState } from './types'

const initialState = {
  showModal: null,
  modalText: {
    headerText: '',
    contentText: '',
    buttonText: ''
  }
}

export const modalsReducer: Reducer<ModalsState, ModalsActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload
      }
    case HIDE_MODAL:
      return {
        ...state,
        showModal: null,
        modalText: {
          headerText: '',
          contentText: '',
          buttonText: ''
        }
      }
    case SET_MODAL_TEXT:
      return {
        ...state,
        modalText: {
          ...state.modalText,
          ...action.payload
        }
      }
    default:
      return state
  }
}
