import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Text, View } from 'native-base'
import { formatDistanceToNowStrict } from 'date-fns'
import { TurnStatus, TurnTypes } from '@instaseat/lib/enums/turn'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { ElapsedTimeText } from './styled'
import colors from '@instaseat/lib/themes/colors'
import { roundCustomerWaitTime } from '@instaseat/lib/utils/helpers/helpers'
import { useAppSelector } from 'hooks/redux'

interface IQuoteAndElapsedTimeCellProps {
  turn: PopulatedWaitlistItem
  shouldUpdate: boolean
}

const QuoteAndElapsedTimeCell = ({ turn, shouldUpdate }:IQuoteAndElapsedTimeCellProps) => {
  const roundingValue = useAppSelector((store) => store.host.profileData.unit.roundingValue)
  const [elapsedTime, setElapsedTime] = useState<number>()

  const secToReadableFormat = useCallback((seconds: number | undefined) => {
    if (seconds === undefined) return '--:--'

    let result = ''
    if (seconds < 0) result = '-'

    seconds = Math.abs(seconds)
    const minutes = Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60)
    result += `${minutes}:`
    result += `${('0' + (seconds - Math.floor(seconds / 60) * 60)).slice(-2)} min`
    return result
  }, [])

  useEffect(() => {
    let secondsDiff
    if (turn.status === TurnStatus.customerWaiting) {
      const refTime = new Date(turn.reinsertedAt || turn.createdAt)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    if (turn.status === TurnStatus.customerWaiting && turn.type === TurnTypes.VIP && turn.vipCreationDate) {
      const refTime = new Date(turn.vipCreationDate)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    if (turn.status === TurnStatus.pendingConfirmation && turn.courtesyTimeExpiration) {
      const refTime = new Date(turn.courtesyTimeExpiration)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    setElapsedTime(secondsDiff)
  }, [turn.courtesyTimeExpiration, turn.reinsertedAt,
    turn.createdAt, turn.status, shouldUpdate])

  if (turn.status === TurnStatus.customerWaiting) {
    return (
      <View>
        <Text fontSize="12px">
          {
            turn.type === TurnTypes.VIP
              ? (turn.originalWaitTime + ' min')
              : (roundCustomerWaitTime(turn.originalWaitTime, roundingValue) + ' min')
          }
        </Text>
        <View
          justifyContent="center"
          alignItems="center"
        >
          <Divider backgroundColor={colors.textColor} opacity="0.3" width="50%" />
        </View>
        <ElapsedTimeText
          overtime={turn.originalWaitTime <= Math.floor((elapsedTime ?? 0) / 60)}
        >
          {secToReadableFormat(elapsedTime)}
        </ElapsedTimeText>
      </View>
    )
  }
  if (turn.status === TurnStatus.pendingConfirmation) {
    return (
      <ElapsedTimeText
        overtime={(elapsedTime ?? 0) < 0}
      >
        {'CT ' + secToReadableFormat(elapsedTime)}
      </ElapsedTimeText>
    )
  }
  return <>---</>
}

export default QuoteAndElapsedTimeCell
