import { Reducer } from 'redux'
import { socket } from 'utils/socketIO'
import { AuthActionTypes, AuthState, AuthActionConsts } from './types'

export const initialState: AuthState = {
  isFetching: false,
  isSignedIn: null,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    firebaseUID: ''
  },
  forgotPassword: {
    error: '',
    success: false,
    isFetching: false
  },
  error: '',
  currentUser: undefined
}

export const authReducer: Reducer<AuthState, AuthActionTypes> = (
  state = initialState,
  action
): AuthState => {
  switch (action.type) {
    case AuthActionConsts.SET_IS_SIGNED_IN:
      return {
        ...state,
        isSignedIn: action.payload
      }
    case AuthActionConsts.SAVE_USER_INFORMATION:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case AuthActionConsts.GET_USER_DATA_PENDING:
    case AuthActionConsts.SIGN_IN_HOST_PENDING:
      return {
        ...state,
        isFetching: true,
        error: ''
      }
    case AuthActionConsts.SIGN_IN_HOST_FULFILLED:
      return {
        ...state,
        isFetching: false,
        error: '',
        isSignedIn: true,
        user: {
          ...action.payload
        }
      }
    case AuthActionConsts.GET_USER_DATA_REJECTED:
    case AuthActionConsts.SIGN_IN_HOST_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case AuthActionConsts.RESET_SIGN_IN_HOST_FLAGS:
      return {
        ...state,
        error: ''
      }
    case AuthActionConsts.GET_USER_DATA_FULFILLED:
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload
      }
    case AuthActionConsts.FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        forgotPassword: {
          isFetching: true,
          error: '',
          success: false
        }
      }
    case AuthActionConsts.FORGOT_PASSWORD_REJECTED:
      return {
        ...state,
        forgotPassword: {
          isFetching: false,
          error: action.payload,
          success: false
        }
      }
    case AuthActionConsts.FORGOT_PASSWORD_FULFILLED:
      return {
        ...state,
        forgotPassword: {
          isFetching: false,
          error: '',
          success: true
        }
      }
    case AuthActionConsts.RESET_FORGOT_PASSWORD_FLAGS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          error: '',
          success: false
        }
      }
    case AuthActionConsts.SIGN_OUT:
      socket?.disconnect()
      return {
        ...initialState,
        isSignedIn: false
      }
    default:
      return state
  }
}
