
import colors from '@instaseat/lib/themes/colors'
import styled from 'styled-components'
import { Text, View } from 'native-base'
import { store } from 'store'
import { getTurnDetails } from 'store/modules/Turns'
import { showModal } from 'store/modules/Modals'
import { isOutdatedIOSVersion } from 'utils/helpers'

const customColors = {
  tdTextColor: '#707070'
}

interface TrProps {
  display?: string
    shadow?: boolean
}

interface TdProps {
  width?: string,
  justifyContent?: string,
  paddingLeft?: string,
}

export const TableWrapper = styled.div`
  height: 440px;
  box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  margin-top: ${isOutdatedIOSVersion() ? '35px' : 0}
`

export const StyledHistoryTable = styled.table`
  position: relative;
  width: 100%;
  height: fit-content;
  border-collapse: collapse;
  font-size: 15px;
  font-weight: 400;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${colors.white};
  text-align: center;
`

export const WaitlistTHead = styled.thead`
  position: sticky;
  width: 100%;
  top: 0;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 15px;
  background-color: ${colors.bone};
  z-index: 1;
  text-align: center;
  line-height: 1.5;
`

export const WaitlistTbody = styled.tbody`
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
`

export const WaitlistTr = styled.tr<TrProps>`
  display: ${(props) => props.display || 'table-row'};
  color: ${colors.textColor};
  text-transform: capitalize;
  box-shadow: ${(props) => props.shadow ? '0px 0px 3px 0px rgba(0, 0, 0, 0.1)' : 'none'};
`

export const WaitlistTd = styled.td<TdProps>`
  width: ${(props) => props.width || '100%'};
  height: 50px;
  font-family: 'Lato';
  color: ${customColors.tdTextColor};
  justify-content: ${(props) => props.justifyContent || 'center'};
  text-align: ${(props) => props.justifyContent || 'center'};
  padding-left: ${(props) => props.paddingLeft || '0'};
`

export const PressableWaitlistTd = styled(WaitlistTd).attrs(props => ({
  onClick: async () => {
    store.dispatch(getTurnDetails(props.id || ''))
    store.dispatch(showModal('turnDetail'))
  }
}))<{id?: string}>`
  cursor: pointer;
`

export const CustomerNameText = styled(Text).attrs({
  textAlign: 'left',
  fontFamily: 'Lato',
  color: customColors.tdTextColor
})``

export const NoTurnsText = styled.p`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: rgba(33, 15, 11, 0.15);
`

export const HoursText = styled(Text).attrs({
  fontFamily: 'Lato',
  color: colors.textColor
})``

export const ArrowsContainer = styled(View).attrs({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: '20px',
  alignItems: 'center'
})``

WaitlistTr.defaultProps = {
  shadow: true
}
