import { extendTheme } from 'native-base'
import colors from './colors'

const newColorTheme = {
  colors,
  primary: {
    50: '#ffe5f2',
    100: '#fabbd3',
    200: '#f08fb4',
    300: '#e86397',
    400: '#e13879',
    500: '#b21b55',
    600: '#9c164a',
    700: '#710d35',
    800: '#450520',
    900: '#1d000d'
  },
  secondary: {
    50: '#f4f1f1',
    100: '#d7d7d7',
    200: '#bebdbc',
    300: '#a6a3a0',
    400: '#908984',
    500: '#77716b',
    600: '#5c5853',
    700: '#403f3c',
    800: '#272624',
    900: '#0e0e0b'
  },
  brand: {
    900: '#8287af',
    800: colors.secondary,
    700: colors.primary
  }
}
const nativeBase = extendTheme({ colors: newColorTheme })

export { nativeBase }
