import React, { useCallback, useEffect, useState } from 'react'
import {
  TextArea
} from './styled'
import { NativeSyntheticEvent, TextInput, TextInputFocusEventData } from 'react-native'

export interface CustomTextAreaProps {
  value?: string,
  onChangeText: ((text: string) => void),
  refProp?: React.ForwardedRef<TextInput>,
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void,
  onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
}

const CustomTextArea = ({
  value,
  onChangeText,
  refProp,
  onFocus,
  onBlur
}: CustomTextAreaProps) => {
  const [focus, setFocus] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const onChangeTextHandler = useCallback((value: string) => {
    setIsFilled(!!value)
    onChangeText && onChangeText(value)
  }, [onChangeText])

  useEffect(() => { if (!value) setIsFilled(false) }, [value])

  return (
    <TextArea
      value={value}
      onChangeText={onChangeTextHandler}
      onFocus={(e) => {
        setFocus(true)
        onFocus && onFocus(e)
      }}
      onBlur={(e) => {
        setFocus(false)
        onBlur && onBlur(e)
      }}
      focus={focus}
      ref={refProp}
      isFilled={isFilled}
    />
  )
}

CustomTextArea.defaultProps = {
  value: '',
  refProp: undefined,
  onBlur: undefined,
  onFocus: undefined
}

export { CustomTextArea }
