import { Image } from 'native-base'
import styled from 'styled-components/native'

export const LogoImg = styled(Image)`
  height: 72px;
  width: 146px;
  margin-right: 42px;
  resize-mode: contain;
`

export const Container = styled.View`
  height: 100vh;
  width: '100%';
`
