/* eslint-disable max-len */
import React from 'react'
import Svg, { Path, SvgProps, G } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
}

const OfflineIcon = ({ iconProps }: props) => {
  return (
    <Svg
      width="280"
      height="280"
      fill="none"
      viewBox="0 0 280 280"
      {...iconProps}
    >
      <G opacity="0.5">
        <Path
          fill="#F1F0F0"
          fillOpacity="0.8"
          d="M84.556 73.886s30.024 22.784 75.668-19.818c40.536-37.834 73.635 21.86 73.866 50.445.3 37.03-40.536 66.66-20.718 90.982 19.818 24.322-39.302 64.486-71.164 29.727-39.636-43.239-50.373-8.108-72.966-8.108-16.214 0-49.504-40.289-27.024-70.263 18.917-25.222 8.6-33.593 3.604-42.338-7.207-12.61 9.908-46.842 38.734-30.627z"
        />
        <Path
          fill="#E9E4E3"
          d="M205.092 124.235c1.614.6 3.377.639 5.055.254 7.03-1.613 31.203-5.886 27.003 12.846-.915 3.716-15.072 24.529 5.089 32.127 1.478.564 3.04.872 4.621.91 6.208.128 18.509-1.338 23.657-6.579 6.22-6.33-.575-3.328-.575-3.328s-23.644 10.318-31.996-.629c-.929-1.218-1.521-2.987-1.69-4.51-.294-2.64.878-6.129 2.418-9.167 2.085-4.111 12.092-22.604-9.635-27.991-5.006-1.192-12.276-2.263-21.649 1.684-5.468 2.302-4.257 3.655-2.298 4.383z"
        />
        <Path
          fill="#E9E4E3"
          d="M209.93 117.331l-6.293 2.356a4.2 4.2 0 00-2.461 5.405l.312.834a4.2 4.2 0 005.405 2.461l6.294-2.355a4.2 4.2 0 002.461-5.406l-.312-.833a4.2 4.2 0 00-5.406-2.462z"
        />
        <Path
          fill="#210F0B"
          fillOpacity="0.1"
          d="M171.301 124.147l-20.755 7.767a2.101 2.101 0 00-1.231 2.703l.082.219a2.1 2.1 0 002.703 1.23l20.755-7.767a2.1 2.1 0 001.23-2.703l-.082-.219a2.098 2.098 0 00-2.702-1.23zM177.093 139.624l-20.755 7.767a2.1 2.1 0 00-1.23 2.703l.081.219a2.1 2.1 0 002.703 1.23l20.755-7.767a2.101 2.101 0 001.231-2.703l-.082-.219a2.1 2.1 0 00-2.703-1.23z"
        />
        <Path
          fill="#B4AEAC"
          d="M196.255 113.584l-16.821 6.296a4.2 4.2 0 00-2.462 5.406l5.744 15.345a4.2 4.2 0 005.405 2.461l16.822-6.296a4.2 4.2 0 002.461-5.405l-5.743-15.345a4.2 4.2 0 00-5.406-2.462z"
        />
        <Path
          fill="#C8C4C3"
          d="M181.172 117.61l-16.822 6.296a4.2 4.2 0 00-2.461 5.405l6.852 18.308a4.2 4.2 0 005.405 2.461l16.822-6.295a4.201 4.201 0 002.461-5.406l-6.852-18.307a4.2 4.2 0 00-5.405-2.462z"
        />
        <Path
          fill="#E9E4E3"
          d="M183.805 113.895l-.132.049a2.8 2.8 0 00-1.641 3.604l9.599 25.647a2.8 2.8 0 003.603 1.641l.132-.05a2.8 2.8 0 001.641-3.604l-9.598-25.646a2.8 2.8 0 00-3.604-1.641zM12.282 130.292s33.783-9.494 28.878 12.38c-.852 3.46-13.113 21.281 1.492 29.837 3.136 1.837 6.755 2.651 10.39 2.632 6.515-.034 16.845-1.288 21.486-6.011 6.22-6.332-.575-3.329-.575-3.329s-19.403 7.981-29.384 1.619c-3.409-2.172-4.954-6.378-4.028-10.313a23.65 23.65 0 012.144-5.612c2.17-4.066 12.092-22.604-9.636-27.991-5.005-1.192-12.275-2.263-21.649 1.684s.882 5.104.882 5.104z"
        />
        <Path
          fill="#E9E4E3"
          d="M79.07 160.906l-6.293 2.356a4.2 4.2 0 00-2.461 5.405l.312.834a4.2 4.2 0 005.406 2.461l6.293-2.355a4.2 4.2 0 002.461-5.406l-.312-.833a4.2 4.2 0 00-5.406-2.462z"
        />
        <Path
          fill="#B4AEAC"
          d="M89.703 150.552l-9.327 3.49a4.2 4.2 0 00-2.461 5.406l4.497 12.017a4.2 4.2 0 005.406 2.461l9.327-3.49a4.201 4.201 0 002.461-5.406l-4.497-12.017a4.2 4.2 0 00-5.406-2.461z"
        />
        <Path
          fill="#C8C4C3"
          d="M105.23 141.3l-13.909 5.206a4.2 4.2 0 00-2.461 5.406l6.852 18.307a4.2 4.2 0 005.405 2.461l13.909-5.205a4.2 4.2 0 002.462-5.406l-6.852-18.307a4.2 4.2 0 00-5.406-2.462z"
        />
        <Path
          fill="#E9E4E3"
          d="M108.177 137.509l-.132.049a2.8 2.8 0 00-1.641 3.604l9.598 25.646a2.8 2.8 0 003.604 1.641l.132-.049a2.8 2.8 0 001.641-3.604l-9.599-25.646a2.799 2.799 0 00-3.603-1.641z"
        />
      </G>
      <Path
        fill="#B21B55"
        d="M138.323 121.935a1.943 1.943 0 01-1.726-2.079l1.038-18.956c.061-1.132 1.018-1.992 2.103-1.892l1.512.14c1.085.1 1.862 1.12 1.71 2.244l-2.55 18.816a1.963 1.963 0 01-2.087 1.727zM129.362 123.57a1.242 1.242 0 01-1.312-.411 1.213 1.213 0 01-.223-.427l-3.706-11.792a1.326 1.326 0 01.856-1.672l.96-.29a1.24 1.24 0 011.563.943l2.747 12.081a1.33 1.33 0 01-.885 1.568zM124.361 130.37c-.664.823-2 .813-3.028-.023l-18.252-14.829c-1.09-.885-1.412-2.352-.707-3.224l.982-1.216c.704-.873 2.151-.801 3.183.157l17.27 16.046c.972.903 1.216 2.266.552 3.089zM132.797 168.672a1.943 1.943 0 011.138 2.45l-5.844 18.062c-.35 1.079-1.494 1.666-2.517 1.293l-1.427-.521c-1.024-.374-1.515-1.559-1.08-2.607l7.271-17.541a1.957 1.957 0 012.459-1.136zM141.879 169.38a1.241 1.241 0 011.163.732c.066.148.102.308.107.47l.571 12.348a1.329 1.329 0 01-1.255 1.398l-1.002.035a1.24 1.24 0 01-1.27-1.311l.43-12.382a1.326 1.326 0 011.256-1.29zM148.45 164.083c.853-.626 2.141-.275 2.922.795l13.859 18.998c.828 1.135.765 2.634-.14 3.298l-1.26.925c-.904.664-2.284.225-3.037-.965l-12.6-19.924c-.708-1.122-.596-2.501.256-3.127z"
      />
    </Svg>
  )
}

OfflineIcon.defaultProps = {
  iconProps: {}
}

export default OfflineIcon
