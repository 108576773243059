import React from 'react'
import { SvgProps } from 'react-native-svg'
import { IconName } from './types'

import ProfileIcon from './ProfileIcon'
import MessageIcon from './MessageIcon'
import RestaurantIcon from './RestaurantIcon'
import WaitlistIcon from './WaitlistIcon'
import CheckIcon from './CheckIcon'
import WarningIcon from './WarningIcon'
import GoToIcon from './GoToIcon'
import ClockIcon from './ClockIcon'
import CustomersIcon from './CustomersIcon'
import AverageTimeIcon from './AverageTimeIcon'
import NoTurnsIcon from './NoTurnsIcon'
import BackButtonIcon from './BackButtonIcon'
import HeartIcon from './HeartIcon'
import NoRestaurantsIcon from './NoRestaurantsIcon'
import SearchIcon from './SearchIcon'
import CancelledIcon from './CanceledIcon'
import DragIcon from './DragIcon'
import LogoIcon from './LogoIcon'
import SeeMoreIcon from './SeeMoreIcon'
import HostOnItsWay from './HostOnItsWayIcon'
import EyeClosedIcon from './EyeClosedIcon'
import EyeOpenedIcon from './EyeOpenedIcon'
import PlusIcon from './PlusIcon'
import PlusIcon2 from './PlusIcon2'
import NotifyIcon from './NotifyIcon'
import RemoveIcon from './RemoveIcon'

interface props {
  iconName: string
  iconProps?: SvgProps
}

const CustomIcons = ({ iconName, iconProps }: props) => {
  switch (iconName) {
    case IconName.MessageIcon:
      return <MessageIcon iconProps={iconProps} />
    case IconName.ProfileIcon:
      return <ProfileIcon iconProps={iconProps} />
    case IconName.RestaurantIcon:
      return <RestaurantIcon iconProps={iconProps} />
    case IconName.WaitlistIcon:
      return <WaitlistIcon iconProps={iconProps} />
    case IconName.CheckIcon:
      return <CheckIcon iconProps={iconProps} />
    case IconName.WarningIcon:
      return <WarningIcon iconProps={iconProps} />
    case IconName.GoToIcon:
      return <GoToIcon iconProps={iconProps} />
    case IconName.ClockIcon:
      return <ClockIcon iconProps={iconProps} />
    case IconName.CustomersIcon:
      return <CustomersIcon iconProps={iconProps} />
    case IconName.AverageTimeIcon:
      return <AverageTimeIcon iconProps={iconProps} />
    case IconName.NoTurnsIcon:
      return <NoTurnsIcon iconProps={iconProps} />
    case IconName.BackButtonIcon:
      return <BackButtonIcon iconProps={iconProps} />
    case IconName.HeartIcon:
      return <HeartIcon iconProps={iconProps} />
    case IconName.NoRestaurantsIcon:
      return <NoRestaurantsIcon iconProps={iconProps} />
    case IconName.SearchIcon:
      return <SearchIcon iconProps={iconProps} />
    case IconName.CancelledIcon:
      return <CancelledIcon iconProps={iconProps} />
    case IconName.DragIcon:
      return <DragIcon iconProps={iconProps} />
    case IconName.LogoIcon:
      return <LogoIcon iconProps={iconProps} />
    case IconName.SeeMoreIcon:
      return <SeeMoreIcon iconProps={iconProps} />
    case IconName.HostOnItsWayIcon:
      return <HostOnItsWay iconProps={iconProps} />
    case IconName.EyeClosedIcon:
      return <EyeClosedIcon iconProps={iconProps} />
    case IconName.EyeOpenedIcon:
      return <EyeOpenedIcon iconProps={iconProps} />
    case IconName.PlusIcon:
      return <PlusIcon iconProps={iconProps} />
    case IconName.PlusIcon2:
      return <PlusIcon2 iconProps={iconProps} />
    case IconName.NotifyIcon:
      return <NotifyIcon iconProps={iconProps} />
    case IconName.RemoveIcon:
      return <RemoveIcon iconProps={iconProps} />
    default:
      return <MessageIcon iconProps={iconProps} />
  }
}

CustomIcons.defaultProps = {
  iconProps: {}
}

export default CustomIcons
