import React from 'react'
import { CustomModal } from '../shared/modal'
import ConfirmDragModalContent from './content/Content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import EditIcon from '@instaseat/lib/assets/icons/EditIcon'

const header = {
  title: 'Drag and drop',
  IconComponent: <EditIcon />
}

const ConfirmDragModal = () => {
  const showConfirmDragModal = useAppSelector((store) => store.modals.showModal) === 'confirmDrag'
  const dispatch = useAppDispatch()
  return (
    <CustomModal
      showModal={showConfirmDragModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={<ConfirmDragModalContent isVisible={showConfirmDragModal} />}
    />
  )
}

export default ConfirmDragModal
