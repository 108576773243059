import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const HeartIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...iconProps}>
    <Path
      fill="#ffffff"
      stroke-dashoffset=""
      fillRule="evenodd"
      marker-start=""
      marker-mid=""
      marker-end=""
      id="svg_2"
      // eslint-disable-next-line max-len
      d="M7.936440184624443,5.60120656459214 C9.931499627554663,1.0433058190739306 17.815157951033363,6.8196435384011425 8.003388047735939,12.679806378140464 C-1.8083736541817106,6.8196435384011425 5.941380741694221,1.0433058190739306 7.936440184624443,5.60120656459214 z"
      fillOpacity="1"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.6737 8.45759C12.1123 7.9313 12.3459 7.20658 12.2423 6.42577C12.0981 5.32575 11.2594 4.43925 10.2237 4.29043C9.31798 4.16101 8.49551 4.56867 8 5.25888C7.50449 4.56867 6.68202 4.16101 5.7763 4.29043C4.7406 4.43925 3.90189 5.32575 3.7577 6.42362C3.69272 6.91539 3.78004 7.33383 3.83487 7.52364C3.88158 7.68973 4.03389 8.16425 4.44004 8.59132C4.54158 8.69701 4.89088 9.02701 4.89088 9.02701L7.93502 12.2602L7.94111 12.2667H8.0528L10.9731 9.16506C10.9731 9.16506 11.5579 8.59563 11.6737 8.45759Z"
      fill="#B21B55"
      id="svg_1"
    />
  </Svg>
)

HeartIcon.defaultProps = {
  iconProps: {}
}

export default HeartIcon
