import React from 'react'
import { Center } from 'native-base'
import colors from '@instaseat/lib/themes/colors'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { ModalButton } from 'components/shared/modalButton'
import { ContentWrapper, Label } from './styled'

interface ForgotPasswordSuccessProps {
  onTryAgainClick: () => void;
}

const ForgotPasswordError = ({ onTryAgainClick }: ForgotPasswordSuccessProps) => {
  return (
    <ContentWrapper space="24px">
      <Center h="70%">
        <CustomIcons
          iconName={IconName.CancelledIcon}
          iconProps={{
            fill: colors.errorColor,
            width: 40,
            height: 40
          }}
        />
        <Label style={{ marginTop: 20 }}>
          There was an error trying to send the email to reset your password.
        </Label>
      </Center>
      <ModalButton onPress={() => onTryAgainClick()} text='Try again' />
    </ContentWrapper>
  )
}

ForgotPasswordError.displayName = 'ForgotPasswordError'

export default ForgotPasswordError
