import { Reducer } from 'redux'
import { WaitlistActionType, WaitlistState } from './types'
import * as Constants from './constants'

const initialState = {
  isLoading: false,
  error: '',
  waitlistData: {
    waitingTimes: {
      fourteenTopsOrLessQuote: 0,
      fifteenTopsOrMoreQuote: 0,
      fourTopsOrLessDeviationTime: 0,
      fourToFifteenTopsDeviationTime: 0,
      fifteenTopsOrMoreDeviationTime: 0
    },
    dailyTotals: {
      assigned: 0,
      seated: 0,
      canceled: 0
    },
    waitlist: [],
    waitlistInfo: {
      newWaitTime: 0,
      peopleQtyAhead: 0,
      peopleQtySkippedWithVIP: 0,
      savedTimeWithVIP: 0,
      VIPSQty: 0,
      VIPSOccupied: 0,
      VIPSAvailable: 0
    }
  },
  selectedTurn: undefined,
  selectedHistoryTurn: undefined,
  historyFilter: {
    startDate: '',
    endDate: ''
  }
}

export const waitlistReducer: Reducer<WaitlistState, WaitlistActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_WAITLIST_DATA_PENDING:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case Constants.GET_WAITLIST_DATA_COMPLETED:{
      return {
        ...state,
        isLoading: false,
        waitlistData: action.payload
      }
    }
    case Constants.GET_WAITLIST_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload as string
      }
    case Constants.SELECT_TURN:
      return {
        ...state,
        selectedTurn: action.payload
      }
    case Constants.SET_SELECTED_HISTORY_TURN:
      return {
        ...state,
        selectedHistoryTurn: action.payload
      }
    case Constants.SET_HISTORY_FILTER:
      return {
        ...state,
        historyFilter: action.payload
      }
    case Constants.SORT_WAITLIST_ORDER_PENDING:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case Constants.SORT_WAITLIST_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false
      }
    case Constants.SORT_WAITLIST_ORDER_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload as string
      }

    default:
      return state
  }
}
