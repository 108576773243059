import styled from 'styled-components/native'
import { Center, HStack } from 'native-base'
import colors from '@instaseat/lib/themes/colors'
import { isOutdatedIOSVersion } from 'utils/helpers'

export const ScreenContainer = styled(Center).attrs({
  width: '100%',
  height: '100%',
  bg: colors.white,
  justifyContent: 'center',
  alignContent: 'center'
})``

export const ContentContainer = styled(HStack).attrs({
  space: '6%',
  pt: '1.5%'
})`
  width: ${isOutdatedIOSVersion() ? '95vw' : '95%'};
  height: ${isOutdatedIOSVersion() ? '85vh' : '100%'};
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
`
