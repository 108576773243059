import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const WarningIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M9.98632 0.00223255C7.31454 0.0459863 4.76729 1.13976 2.89523 3.04711C1.95854 3.97997 1.21949 5.09217 0.722254 6.31724C0.225023 7.5423 -0.0201816 8.85508 0.00129856 10.1771C-0.000238381 11.4689 0.253081 12.7483 0.746739 13.942C1.2404 15.1356 1.96469 16.2201 2.87811 17.1333C3.79152 18.0464 4.87611 18.7703 6.06971 19.2634C7.2633 19.7565 8.54245 20.0092 9.83383 20.0069H10.0121C12.6847 19.9794 15.2373 18.8924 17.1097 16.9846C18.9821 15.0768 20.0214 12.5038 19.9997 9.83034V9.83034C20.0027 8.52289 19.7444 7.22801 19.2399 6.02187C18.7354 4.81573 17.9949 3.72267 17.062 2.80697C16.129 1.89127 15.0225 1.17143 13.8073 0.689761C12.5922 0.208096 11.2931 -0.0256627 9.98632 0.00223255V0.00223255ZM8.75058 13.7904C8.74458 13.627 8.7712 13.464 8.82891 13.311C8.88662 13.158 8.97424 13.018 9.08667 12.8993C9.19909 12.7805 9.33405 12.6854 9.48366 12.6194C9.63327 12.5535 9.79452 12.518 9.95799 12.5151H9.98048C10.3093 12.5158 10.6251 12.6437 10.8617 12.8721C11.0983 13.1006 11.2373 13.4117 11.2495 13.7404C11.2557 13.9039 11.2291 14.0669 11.1715 14.22C11.1138 14.3731 11.0262 14.5131 10.9137 14.6318C10.8013 14.7506 10.6663 14.8457 10.5166 14.9116C10.367 14.9776 10.2056 15.0129 10.0421 15.0157H10.0196C9.69096 15.0147 9.37539 14.8866 9.13889 14.6583C8.90239 14.4299 8.76327 14.119 8.75058 13.7904V13.7904ZM9.16722 10.4213V5.42016C9.16722 5.19909 9.25501 4.98708 9.41127 4.83076C9.56754 4.67445 9.77949 4.58663 10.0005 4.58663C10.2215 4.58663 10.4334 4.67445 10.5897 4.83076C10.746 4.98708 10.8337 5.19909 10.8337 5.42016V10.4213C10.8337 10.6424 10.746 10.8544 10.5897 11.0107C10.4334 11.167 10.2215 11.2548 10.0005 11.2548C9.77949 11.2548 9.56754 11.167 9.41127 11.0107C9.25501 10.8544 9.16722 10.6424 9.16722 10.4213Z"
      fill={iconProps?.fill || '#210f0b9e'}
      fillOpacity={iconProps?.fillOpacity || '0.6'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
  </Svg>
)

WarningIcon.defaultProps = {
  iconProps: {}
}

export default WarningIcon
