import React from 'react'
import Svg, { Path, SvgProps, Line } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const NoTurnsIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="43" height="40" viewBox="0 0 43 40" fill="none" {...iconProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M20.9879 37.9759C25.3675 37.9759 29.3815 36.4107 32.5011 33.8093L30.9299 32.1927C28.3019 34.261 24.9863 35.4948 21.3825 35.4948C12.8495 35.4948 5.93205 28.5774 5.93205 20.0443C5.93205 11.5113 12.8495 4.59386 21.3825 4.59386C28.737 4.59386 34.8913 9.73239 36.451 16.615L31.5954 15.909L35.8617 26.6151L43.0003 17.5673L38.7196 16.9449C37.2738 8.45964 29.8852 2 20.9879 2C11.0535 2 3 10.0535 3 19.9879C3 29.9224 11.0535 37.9759 20.9879 37.9759Z"
      fillOpacity="0.15"
      fill={iconProps.fill || '#210f0b24'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M20.4039 22.5818C20.8037 23.0614 21.5404 23.0614 21.9402 22.5818L23.4551 20.7645L29.3935 27.8887C29.9364 28.54 29.4733 29.529 28.6254 29.529H13.7187C12.8708 29.529 12.4077 28.54 12.9506 27.8887L18.8891 20.7645L20.4039 22.5818ZM18.8891 20.7645L12.9506 13.6403C12.4077 12.989 12.8708 12 13.7187 12H28.6254C29.4733 12 29.9364 12.989 29.3935 13.6403L23.4551 20.7645L21.9402 18.9472C21.5404 18.4675 20.8037 18.4675 20.4039 18.9472L18.8891 20.7645Z"
      fill={iconProps.fill || '#210f0b24'}
      fillOpacity="0.15"
    />
    <Line
      x1="1.93934"
      y1="37.4706"
      x2="38.1555"
      y2="1.25443"
      stroke="transparent"
      strokeWidth={3}
    />
    <Line
      x1="0"
      y1="38.5355"
      x2="41.3508"
      y2="0"
      stroke={iconProps.fill || '#D2CECE'}
      strokeOpacity="0.35"
      strokeWidth={2}
    />
  </Svg>
)

NoTurnsIcon.defaultProps = {
  iconProps: {}
}

export default NoTurnsIcon
