import React from 'react'
import { CustomModal } from '../shared/modal'
import { AddTurnSuccessModalContent } from './content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import PlusIcon from '@instaseat/lib/assets/icons/PlusIcon2'

const header = {
  title: 'Add Customer',
  IconComponent: <PlusIcon />
}

const AddTurnSuccessModal = () => {
  const showAddTurnSuccessModal = useAppSelector((store) => store.modals.showModal) === 'addTurnSuccess'
  const customerName = useAppSelector((store) => store.modals.modalText.contentText)
  const dispatch = useAppDispatch()
  return (
    <CustomModal
      showModal={showAddTurnSuccessModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={<AddTurnSuccessModalContent content={customerName || ''} onPress={() => dispatch(hideModal())} />}
    />
  )
}

export { AddTurnSuccessModal }
