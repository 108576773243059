import React from 'react'
import { ContentWrapper, DescriptionText } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modules/Modals'
import { SmallCloseIcon } from 'native-base'
import { View } from 'react-native'
import colors from '@instaseat/lib/themes/colors'

const ErrorModalContent = () => {
  const dispatch = useDispatch()
  const onPressHandle = () => dispatch(hideModal())

  return (
    <ContentWrapper space="40px">
      <View
        style={{
          backgroundColor: colors.errorColor,
          borderRadius: 90,
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <SmallCloseIcon color="#FFF" size="md" />
      </View>
      <DescriptionText>
        The information has not been changed successfully .
      </DescriptionText>
      <ModalButton onPress={onPressHandle} text="Try again" />
    </ContentWrapper>
  )
}

export default ErrorModalContent
