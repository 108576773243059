import React, { useCallback, useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { CustomerNameText, HoursText, PressableWaitlistTd, WaitlistTd, WaitlistTr } from './styled'
import colors from '@instaseat/lib/themes/colors'
import { TurnStatus, TurnTypes } from '@instaseat/lib/enums/turn'
import { HStack, Pressable, View, Center, Text } from 'native-base'
import ReturnToWaitlistButton from './ReturnToWaitlistButton'
import { sioHistoricalTurnsItem } from '@instaseat/lib/interfaces/sioSubscriptions'
import { useAppSelector } from 'hooks/redux'

interface ITableRowProps {
  turn: sioHistoricalTurnsItem & {rejoinBlocked?: boolean}
}

const formatDate = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return (
    <HStack space={5}>
      <Text>
        {month}
        /
        {day}
        /
        {year}
      </Text>
    </HStack>
  )
}

const formatHours = (date: Date) => {
  const hours = date.getHours()
  const mins = date.getMinutes()
  return (
    <HStack space={5}>
      <Text>
        {hours < 10 ? '0' : ''}
        {hours}
        :
        {mins < 10 ? '0' : ''}
        {mins}
      </Text>
    </HStack>
  )
}

const TableRow = ({ turn }: ITableRowProps) => {
  const [showActionRow, setShowActionRow] = useState(false)
  const [canRejoin, setCanRejoin] = useState<boolean>(false)
  const customersOnWaitlist = useAppSelector((store) => store.waitlist.waitlistData.waitlist)
  const date = new Date(turn.updatedAt)

  const canBeRejoined = () => {
    if (turn.rejoinBlocked) return setCanRejoin(false)

    if (turn.status === TurnStatus.canceledByCustomer ||
      turn.status === TurnStatus.canceledByHost ||
      TurnStatus.seated) {
      const today = new Date()
      let turnFinishedTime = new Date(turn?.cancelTime || '')
      if (turn.status === TurnStatus.seated) turnFinishedTime = new Date(turn?.seatTime || '')
      const minutesDiff = Math.abs(today.getTime() - turnFinishedTime.getTime()) / (1000 * 60)

      setCanRejoin(!(minutesDiff >= 60))
    }

    customersOnWaitlist.forEach(element => {
      if (element.customer?.phone === turn.customer.phone) return setCanRejoin(false)
    })
  }

  const getStatusIcon = useCallback((status: string) => {
    switch (status) {
      case TurnStatus.canceledByCustomer:
      case TurnStatus.canceledByHost:
        return (
          <CustomIcons
            iconName={IconName.CancelledIcon}
            iconProps={{ fill: colors.errorColor, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.seated:
        return (
          <CustomIcons
            iconName={IconName.CheckIcon}
            iconProps={{ fill: colors.success, height: '24px', width: '24px' }}
          />
        )
      default:
        return (
          <CustomIcons
            iconName={IconName.CheckIcon}
            iconProps={{ fill: colors.success, height: '24px', width: '24px' }}
          />
        )
    }
  }, [])

  useEffect(() => {
    canBeRejoined()
  }
  , [])

  return (
    <WaitlistTr style={{
      opacity: canRejoin ? 1 : 0.5
    }}
    >
      <PressableWaitlistTd
        id={turn._id.toString()}
        width="180px"
      >
        <Center>
          <HStack pl="5%" space="10%" width="100%" justifyContent="space-around">
            <HoursText>
              {formatDate(date)}
            </HoursText>
            <HoursText textTransform="lowercase">
              {formatHours(date)}
              {' '}
              {' hs'}
            </HoursText>
          </HStack>
        </Center>
      </PressableWaitlistTd>
      <PressableWaitlistTd
        id={turn._id.toString()}
        width="180px"
      >
        <HStack justifyContent="flex-start" pl='40px'>
          <View alignSelf="center" pr="2px">
            {turn.type === TurnTypes.VIP && (
            <CustomIcons
              iconName={IconName.LogoIcon}
              iconProps={{
                width: '14px',
                height: '14px',
                fill: colors.primary
              }}
            />
            )}
          </View>
          <CustomerNameText>
            {`${turn?.customer?.firstName} ${turn?.customer?.lastName}`.trim()}
          </CustomerNameText>
        </HStack>
      </PressableWaitlistTd>
      <PressableWaitlistTd
        id={turn._id.toString()}
        width="180px"
        justifyContent='initial'
        paddingLeft='10px'
      >
        {turn?.customer?.phone}

      </PressableWaitlistTd>
      {!showActionRow
        ? (
          <>
            <PressableWaitlistTd
              id={turn._id.toString()}
              width="80px"
            >
              {turn.party}

            </PressableWaitlistTd>
            <PressableWaitlistTd
              id={turn._id.toString()}
              width="80px"
            >
              {turn.status && getStatusIcon(turn.status)}
            </PressableWaitlistTd>
            <WaitlistTd width="40px">
              <Pressable onPress={() => {
                canRejoin && setShowActionRow(!showActionRow)
              }}
              >
                {canRejoin
                  ? (
                    <CustomIcons
                      iconName={IconName.SeeMoreIcon}
                      iconProps={{
                        width: '24px',
                        height: '24px',
                        fill: colors.textColor
                      }}
                    />
                    )
                  : (
                    <Text />
                    )}
              </Pressable>
            </WaitlistTd>
          </>
          )
        : (
          <ReturnToWaitlistButton
            firstName={turn.customer.firstName}
            lastName={turn.customer.lastName}
            turnId={turn._id.toString()}
            visible={showActionRow}
            onPress={() => setShowActionRow(false)}
          />
          )}
    </WaitlistTr>
  )
}

export default TableRow
