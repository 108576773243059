/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import { SignIn } from './sign-in'
import { LogoImg, Container } from './styled'
import linking from '../auth/linking'
import DownloadScreen from 'routes/download'
import TermsAndConditions from 'routes/terms-and-conditions'
import PrivacyPolicy from 'routes/privacy-policy'

const Stack = createStackNavigator()

const LogoTitle = () => (
  <LogoImg
    accessibilityLabel="Logo"
    source={require('assets/logos/logoTitle.png')}
    alt="InstaSeat"
  />
)
const Auth = () => {
  return (
    <Container>
      <NavigationContainer linking={linking}>
        <Stack.Navigator
          initialRouteName="Auth"
        >
          <Stack.Screen
            name="Auth"
            component={SignIn}
            options={{
              title: 'InstaSeat | Sign In',
              headerTitle: '',
              headerShadowVisible: false,
              headerRight: () => (<LogoTitle />)
            }}
          />

          <Stack.Screen
            name="Download"
            component={DownloadScreen}
            options={{
              title: 'InstaSeat | Download',
              headerTitle: '',
              headerShadowVisible: false,
              header: () => (null)
            }}
          />

          <Stack.Screen
            name="Terms"
            component={TermsAndConditions}
            options={{
              title: 'InstaSeat | Terms and Conditions',
              headerTitle: '',
              headerShadowVisible: false,
              header: () => (null)
            }}
          />

          <Stack.Screen
            name="Privacy"
            component={PrivacyPolicy}
            options={{
              title: 'InstaSeat | Privacy policy',
              headerTitle: '',
              headerShadowVisible: false,
              header: () => (null)
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </Container>
  )
}

export default Auth
