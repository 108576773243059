import React, { ReactElement } from 'react'
import { Modal } from 'native-base'
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalHeaderTitleWrapper
} from './styled'

export interface CustomModalProps {
  showModal: boolean,
  onClose: () => void,
  header: {
    IconComponent?: ReactElement,
    title: string
  },
  BodyComponent: ReactElement,
  FooterComponent?: React.ReactElement,
  initialRef?: React.RefObject<unknown>,
  closeOnOverlayClick?: boolean
}

const CustomModal = ({
  showModal,
  onClose,
  header,
  BodyComponent,
  FooterComponent,
  initialRef,
  closeOnOverlayClick
}: CustomModalProps) => {
  return (
    <Modal
      isOpen={showModal}
      onClose={onClose}
      avoidKeyboard
      closeOnOverlayClick={closeOnOverlayClick}
      initialFocusRef={initialRef}
    >
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
          <ModalHeaderTitleWrapper space="10px">
            {header.IconComponent}
            {header.title}
          </ModalHeaderTitleWrapper>
        </ModalHeader>
        <ModalBody>
          {BodyComponent}
        </ModalBody>
        {FooterComponent && (
        <Modal.Footer>
          {FooterComponent}
        </Modal.Footer>
        )}
      </ModalContent>
    </Modal>
  )
}

CustomModal.defaultProps = {
  FooterComponent: null,
  initialRef: undefined,
  closeOnOverlayClick: true
}

export { CustomModal }
