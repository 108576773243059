import colors from '@instaseat/lib/themes/colors'
import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const CancelledIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="20" height="20" viewBox="0 0 16 16" fill="none" {...iconProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16ZM6.0303 4.96967C5.73743 4.67679 5.26257 4.67679 4.96967 4.96967C4.67676 5.26257 4.67676 5.73743 4.96967 6.0303L6.9393 8L4.96967 9.9697C4.67676 10.2626 4.67676 10.7374 4.96967 11.0303C5.26257 11.3232 5.73743 11.3232 6.0303 11.0303L8 9.0607L9.9697 11.0303C10.2626 11.3232 10.7374 11.3232 11.0303 11.0303C11.3232 10.7374 11.3232 10.2626 11.0303 9.9697L9.0607 8L11.0303 6.0303C11.3232 5.73743 11.3232 5.26257 11.0303 4.96967C10.7374 4.67679 10.2626 4.67679 9.9697 4.96967L8 6.9393L6.0303 4.96967Z"
      fill={iconProps?.fill || '#210f0b9e'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M 4.808 4.819 C 5.073 4.557 5.502 4.557 5.767 4.819 L 7.998 7.018 L 10.23 4.819 C 10.494 4.557 10.923 4.557 11.188 4.819 C 11.452 5.08 11.452 5.503 11.188 5.763 L 8.956 7.963 L 11.188 10.163 C 11.452 10.424 11.452 10.847 11.188 11.108 C 10.923 11.369 10.494 11.369 10.23 11.108 L 7.998 8.908 L 5.767 11.108 C 5.502 11.369 5.073 11.369 4.808 11.108 C 4.544 10.847 4.544 10.424 4.808 10.163 L 7.04 7.963 L 4.808 5.763 C 4.544 5.503 4.544 5.08 4.808 4.819 Z"
      fill={colors.white}
    />

  </Svg>
)

CancelledIcon.defaultProps = {
  iconProps: {}
}

export default CancelledIcon
