import styled from 'styled-components/native'
import { View, Text, Divider } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

const textColor = 'rgba(33, 15, 11, 0.5)'

export const Container = styled(View)`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: space-between;
  width: 150px;
  max-height: 530px;
  margin-left: 20px;
  gap: 15px;
`

export const SideBarInfoContainer = styled(View).attrs({
  shadow: 3
})`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: space-evenly;
  background-color: ${colors.bgGrey};
  width: 150px;
  height: 360px;
`
export const TitleText = styled(Text)`
  max-width: 85px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0.5px;
  color: ${colors.textColor};
`

export const DataContainer = styled(View)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-evenly;
`

export const InfoText = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: ${(props) => props.fontSize};
  text-align: center;
  color: ${textColor};
`

export const DataDivider = styled(Divider).attrs({
  bg: colors.textColor,
  opacity: 0.5,
  width: '20%',
  alignSelf: 'center'
})``

export const SectionDivider = styled(Divider).attrs({
  bg: colors.textColor,
  opacity: 0.1,
  width: '70%',
  alignSelf: 'center'
})``

export const WidgetsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: space-evenly;
`
