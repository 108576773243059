import styled from 'styled-components/native'
import { Center } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

export const ScreenContainer = styled(Center)`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  justify-content: center;
  align-content: center;
  padding-inline: 15px;
`
