import colors from '@instaseat/lib/themes/colors'
import { VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  justify-content: space-between;
  min-height: 244px;
  padding: 42px;
`

export const Label = styled.Text`
  color: ${colors.primaryBlack};
  font-style: italic;
  margin-bottom: 8px;
  text-align: center;
`
