import { useEffect } from 'react'

interface Props {
  navigation: {
    replace: (value: string) => void
  }
}

const TermsAndConditions = ({ navigation }: Props) => {
  useEffect(() => {
    window.open(
      'https://s3.amazonaws.com/instaseat.com/terms-and-conditions.pdf',
      '_blank'
    )
    navigation.replace('Auth')
  }, [])
  return null
}

export default TermsAndConditions
