import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
}

const PlusIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M5 0C4.44772 0 4 0.447715 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4V9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1C6 0.447715 5.55228 0 5 0Z"
      fill="#210F0B"
      fillOpacity="0.5"
    />
  </Svg>
)

PlusIcon.defaultProps = {
  iconProps: {}
}

export default PlusIcon
