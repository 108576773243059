
import colors from '@instaseat/lib/themes/colors'
import styled from 'styled-components'
import { Text } from 'native-base'

const customColors = {
  tdTextColor: '#707070'
}

interface TrProps {
  display?: string
  shadow?: boolean
  bordered?: boolean
}

interface TdProps {
  width?: string,
  justifyContent?: string
}

export const TableWrapper = styled.div`
  flex: 1;
  box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  margin-bottom: 15px;
  width: 100%;
`

export const StyledWaitlistTable = styled.table`
  position: relative;
  height: fit-content;
  border-collapse: collapse;
  font-size: 15px;
  font-weight: 400;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${colors.white};
  text-align: center;
  width: 100%;
`

export const WaitlistTHead = styled.thead`
  position: sticky;
  width: 100%;
  top: 0;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 15px;
  background-color: ${colors.bone};
  z-index: 1;
  text-align: center;
  line-height: 1.5;
`

export const WaitlistTbody = styled.tbody`
  height: fit-content;
  overflow-y: scroll;
`

export const WaitlistTr = styled.tr<TrProps>`
  display: ${(props) => props.display || 'table-row'};
  border: ${(props) => props.bordered ? `1px solid ${colors.tertiary}` : 0};
  color: ${colors.textColor};
  text-transform: capitalize;
  box-shadow: ${(props) => props.shadow ? '0px 0px 3px 0px rgba(0, 0, 0, 0.1)' : 'none'};
  box-sizing: border-box;
`

export const WaitlistTd = styled.td<TdProps>`
  justify-content: ${(props) => props.justifyContent || 'center'};
  height: 50px;
  font-family: 'Lato';
  text-align: ${(props) => props.justifyContent || 'center'};
  color: ${customColors.tdTextColor};
`

export const CustomerNameText = styled(Text).attrs({
  textAlign: 'initial',
  fontFamily: 'Lato',
  color: customColors.tdTextColor
})``

export const NoTurnsText = styled.p`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: rgba(33, 15, 11, 0.15);
`

export const IconWrapper = styled.div`
  height: 24px;
`

WaitlistTr.defaultProps = {
  shadow: true,
  bordered: false
}
