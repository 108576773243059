/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { WaitlistTd } from '../styled'
import colors from '@instaseat/lib/themes/colors'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { Center, HStack } from 'native-base'
import { EmptyVipText, VipWaitlistTr } from './styled'

interface IVipRowProps {
  turn: PopulatedWaitlistItem,
}

const VipRow = ({ turn }: IVipRowProps) => {
  return (
    <VipWaitlistTr
      display="table-row"
      bordered={!!turn.movedWithDragAndDrop}
    >
      <WaitlistTd>
        <Center pl={1}>
          <CustomIcons
            iconName={IconName.LogoIcon}
            iconProps={{
              width: '30px',
              height: '30px',
              fill: colors.primary
            }}
          />
        </Center>
      </WaitlistTd>
      <WaitlistTd colSpan={3} justifyContent='initial'>
        <HStack h='100%' alignItems='center' textAlign="start">
          <EmptyVipText>
            Empty VIP Turn
          </EmptyVipText>
        </HStack>
      </WaitlistTd>
    </VipWaitlistTr>
  )
}

export default VipRow
