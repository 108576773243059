import styled from 'styled-components/native'
import { View, Text, Button } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

export const Container = styled(View)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: ${colors.white};
`

export const ButtonWrapper = styled(View)`
  width: 270px;
  padding: 16px;
`

export const ButtonContainer = styled(View)`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  padding-bottom: 50px;
`

export const Label = styled(Text)`
  font-family: "Lato";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
`

export const SaveChangesButton = styled(View)`
  width: 260px;
  height: 40px;
  background: ${colors.primary};
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 7px;
`

export const SaveChangesText = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: ${colors.white};
`

export const SaveChangesButtonContainer = styled(View)`
  display: flex;
  justify-content: center;
  width: 100%;
`
export const SaveChangesButtonWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 91%;
`

export const ContentContainer = styled(View)`
  width: 30%;
  display: flex;
  align-items: center;
`

export const LogOutButton = styled(View)`
  width: 238px;
  height: 40px;
  background: ${colors.white};
  border-width: 0.5px;
  border-color: "#210F0B";
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 7px;
`

export const LogOutText = styled(Text)`
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`

export const ChangePassword = styled(Text)`
  font-family: "Lato";
  color: ${colors.primary};
  text-decoration-line: underline;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

export const SaveButton = styled(Button)`
  border-radius: 12px;
  margin-bottom: 25px;
  font-size: 30px;
  width: 238px;
  height: 40px;
  font-family: Lato;
  color: ${colors.primary};
  text-decoration-line: underline;
  font-style: italic;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`

export const ChangePasswordWrapper = styled(View)`
padding-bottom: 45px;
`

export const PhoneLeftComponent = styled(Text).attrs({ children: '+1' })`
  font-family: 'Lato';
  font-size: 13px;
  line-height: 200%;
  padding-inline: 10px;
  color: #0004;
  border-right-color: ${colors.secondary};
  border-right-width: 1px;
  margin-right: 10px;
  cursor: default;
`
