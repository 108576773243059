/* eslint-disable no-unreachable */
import { ModalsActionTypes } from './../Modals/types'
import { setModalText } from './../Modals/actions'
import { ThunkAction } from '@instaseat/lib/types/redux-thunk'
import axios from 'axios'
import { TurnsActionTypes } from './types'
import {
  customerPendingConfirmationPending,
  customerPendingConfirmationFulfilled,
  customerPendingConfirmationRejected,
  cancelByHostPending,
  cancelByHostFulfilled,
  cancelByHostRejected,
  seatCustomerPending,
  seatCustomerFulfilled,
  seatCustomerRejected,
  addNewTurnPending,
  addNewTurnFulfilled,
  addNewTurnRejected,
  getTurnDetailsPending,
  getTurnDetailsFulfilled,
  getTurnDetailsRejected,
  setTurnToDrag,
  returnTurnToWaitlistPending,
  returnTurnToWaitlistFulfilled,
  returnTurnToWaitlistRejected,
  getEstimatedQuoteByPartyPending,
  getEstimatedQuoteByPartyFulfilled,
  getEstimatedQuoteByPartyRejected,
  getCustomerNameByPhoneNumberPending,
  getCustomerNameByPhoneNumberFulfilled,
  getCustomerNameByPhoneNumberRejected,
  removeEmptyVIPPending,
  removeEmptyVIPFulfilled,
  removeEmptyVIPRejected
} from './actions'
import { RootState } from 'store'
import { YUPTurn } from '@instaseat/lib/interfaces/turn'

export const addNewTurn = (
  data: Omit<YUPTurn, 'unit' | 'host'>
): ThunkAction<void, RootState, unknown, TurnsActionTypes | ModalsActionTypes> =>
  async (dispatch, getState) => {
    dispatch(addNewTurnPending())
    dispatch(setModalText({ contentText: `${data.customerFirstName} ${data.customerLastName}`.trim() }))
    try {
      const unit = getState().host.profileData.unit._id
      await axios
        .post(`${process.env.REACT_APP_API_URL}/turns`, { ...data, unit }, {
          headers: {
            'content-type': 'application/json'
          }
        })
      dispatch(addNewTurnFulfilled())
      return { success: true }
    } catch (ex) {
      let errorMsg = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          errorMsg = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(addNewTurnRejected(errorMsg as string))
      return { success: false, errorMsg }
    }
  }

export const customerPendingConfirmation = (turnId: string): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(customerPendingConfirmationPending())
    try {
      await axios
        .patch(`${process.env.REACT_APP_API_URL}/turns/pending-confirmation/${turnId}`, {}, {
          headers: {
            'content-type': 'application/json'
          }
        })
      dispatch(customerPendingConfirmationFulfilled())
      return true
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(customerPendingConfirmationRejected(error as string))
      return false
    }
  }

export const getTurnDetails = (turnId: string): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(getTurnDetailsPending())
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/turns/${turnId}`, {
          headers: {
            'content-type': 'application/json'
          }
        })
      dispatch(getTurnDetailsFulfilled(response.data))
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(getTurnDetailsRejected(error as string))
    }
  }

export const cancelByHost = (
  turnId: string,
  canceledReason?: string
): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(cancelByHostPending())
    try {
      await axios
        .patch(`${process.env.REACT_APP_API_URL}/turns/cancel-by-host/${turnId}`, { canceledReason }, {
          headers: {
            'content-type': 'application/json'
          }
        })
      dispatch(cancelByHostFulfilled())
      return true
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(cancelByHostRejected(error as string))
      return false
    }
  }

export const seatCustomer = (
  turnId: string,
  canceledReason?: string
): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(seatCustomerPending())
    try {
      await axios
        .patch(`${process.env.REACT_APP_API_URL}/turns/seated/${turnId}`, { canceledReason }, {
          headers: {
            'content-type': 'application/json'
          }
        })
      dispatch(seatCustomerFulfilled())
      return true
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(seatCustomerRejected(error as string))
      return false
    }
  }

export const selectTurnToDrag = (
  id: string,
  newPosition: number
): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    const turnToDrag = {
      id,
      newPosition
    }
    dispatch(setTurnToDrag(turnToDrag))
  }

export const returnTurnToWaitlist = (
  id: string
): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(returnTurnToWaitlistPending())
    try {
      const response = await axios({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/turns/return-to-waitlist/${id}`,
        headers: {
        }
      })
      dispatch(returnTurnToWaitlistFulfilled(response.data))
      return response.data.message
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(returnTurnToWaitlistRejected(error as string))
    }
  }

export const getEstimatedQuoteByParty = (party: string): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(getEstimatedQuoteByPartyPending())
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/turns/estimated-quote`, {
          headers: {
            'content-type': 'application/json'
          },
          params: {
            party
          }
        })
      dispatch(getEstimatedQuoteByPartyFulfilled())
      return response.data
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(getEstimatedQuoteByPartyRejected(error as string))
    }
  }

export const getCustomerNameByPhoneNumber = (phone: string): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(getCustomerNameByPhoneNumberPending())
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/customers/customer-name-by-phone`, {
          headers: {
            'content-type': 'application/json'
          },
          params: {
            phone
          }
        })
      dispatch(getCustomerNameByPhoneNumberFulfilled())
      return response.data
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(getCustomerNameByPhoneNumberRejected(error as string))
    }
  }

export const removeEmptyVIP = (waitlistId: string): ThunkAction<void, RootState, unknown, TurnsActionTypes> =>
  async (dispatch) => {
    dispatch(removeEmptyVIPPending())
    try {
      await axios
        .delete(`${process.env.REACT_APP_API_URL}/turns/remove-free-vip/${waitlistId}`, {
          headers: {
            'content-type': 'application/json'
          }
        })
      dispatch(removeEmptyVIPFulfilled())
      return true
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(removeEmptyVIPRejected(error as string))
      return false
    }
  }
