import React, { forwardRef, useEffect, useState } from 'react'
import { BoldContentTitle, ContentTitle, ContentWrapper, DescriptionText } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { TextInput } from 'react-native'

interface IReturnModalProps {
  customerName: string
  onPress: () => void
  isLoading: boolean
  isVisible: boolean
}

const ReturnToWaitlistContent = forwardRef<TextInput, IReturnModalProps>(
  ({ isVisible, customerName, onPress, isLoading }) => {
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)

    useEffect(() => {
      isVisible && setIsConfirmDisabled(false)
    }, [isVisible])

    const handleConfirmPress = () => {
      setIsConfirmDisabled(true)
      onPress()
    }
    return (
      <ContentWrapper space="24px">
        <ContentTitle>
          <BoldContentTitle>
            Return
            {' '}
            {customerName}
            {' '}
            to the waiting list?
          </BoldContentTitle>
        </ContentTitle>
        <DescriptionText>
          The system will return the customer at the last position of the waitlist.
        </DescriptionText>
        <ModalButton isLoading={isLoading} onPress={handleConfirmPress} text='Confirm' isDisabled={isConfirmDisabled} />
      </ContentWrapper>
    )
  })

ReturnToWaitlistContent.displayName = 'ReturnToWaitlistContent'

export default ReturnToWaitlistContent
