import { combineReducers, compose, createStore, applyMiddleware, AnyAction } from 'redux'
import { useSelector as useSelectorRedux, useDispatch as useDispatchRedux, TypedUseSelectorHook } from 'react-redux'
import thunk from 'redux-thunk'
import { ThunkAction } from '@instaseat/lib/types/redux-thunk'

import { AuthActionTypes, authReducer, AuthState } from './modules/auth'
import { ModalsActionTypes, modalsReducer, ModalsState } from './modules/Modals'
import { hostReducer, HostState } from './modules/Hosts'
import { TurnsActionTypes, turnsReducer, TurnsState } from './modules/Turns'
import { waitlistReducer, WaitlistState } from './modules/Waitlist'
import type {} from 'redux-thunk/extend-redux'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export interface RootState {
  auth: AuthState;
  host: HostState;
  modals: ModalsState;
  turns: TurnsState;
  waitlist: WaitlistState;
}

export type RootAction =
  AuthActionTypes |
  ModalsActionTypes |
  TurnsActionTypes |
  ThunkAction<void, RootState, unknown, AnyAction>

const rootReducer = combineReducers<RootState>({
  modals: modalsReducer,
  auth: authReducer,
  host: hostReducer,
  turns: turnsReducer,
  waitlist: waitlistReducer
})

const mainReducer: typeof rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}

const enhancer = composeEnhancers(applyMiddleware(thunk))

export const generateStore = () => {
  const store = createStore(mainReducer, enhancer)
  return store
}

export const store = generateStore()

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux

type Dispatch = <TReturnType>(action: RootAction) => TReturnType
export const useDispatch = () => useDispatchRedux<Dispatch>()
