import React from 'react'
import { Heading, HStack, Spinner } from 'native-base'

export type SpinnerProps = {
  children?: React.ReactNode | string
  spinnerColor?: string
}

const LoadingSpinner: React.FC<SpinnerProps> = ({ spinnerColor, children, ...props }) => {
  return (
    <HStack space={2} justifyContent="center" {...props}>
      <Spinner
        color={spinnerColor || '#FFFF'}
        accessibilityLabel="Loading posts"
      />
      <Heading color={spinnerColor || '#FFFF'} fontSize="md">
        {children}
      </Heading>
    </HStack>
  )
}

LoadingSpinner.defaultProps = {
  children: '',
  spinnerColor: null
}

export default LoadingSpinner
