import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
}

const ProfileIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg viewBox="0 0 24 24" fill="transparent" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M15.7813 5.7973C15.7783 6.74256 15.4954 7.66575 14.9681 8.45029C14.4408 9.23483 13.6929 9.84553 12.8187 10.2052C11.9446 10.565 10.9834 10.6576 10.0567 10.4714C9.12994 10.2852 8.27917 9.82852 7.61179 9.15908C6.94442 8.48965 6.49038 7.63746 6.30704 6.71014C6.1237 5.78283 6.21927 4.82198 6.58167 3.94894C6.94408 3.07591 7.55708 2.32985 8.34324 1.80499C9.1294 1.28013 10.0535 1 10.9987 1C11.6278 1.00089 12.2505 1.12569 12.8313 1.36727C13.4121 1.60885 13.9396 1.96249 14.3837 2.40797C14.8279 2.85346 15.1799 3.38207 15.4196 3.96362C15.6594 4.54516 15.7823 5.16825 15.7813 5.7973"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#A19A98'}
      strokeMiterlimit="10"
      fill={iconProps?.fill}
    />
    <Path
      // eslint-disable-next-line max-len
      d="M20.9984 18.7189C20.9989 19.0179 20.9405 19.3141 20.8265 19.5906C20.7125 19.8671 20.5451 20.1184 20.334 20.3302C20.1229 20.542 19.8721 20.7102 19.596 20.8251C19.3199 20.94 19.0239 20.9994 18.7248 20.9998H3.2728C2.97404 21.0001 2.67818 20.9412 2.40229 20.8265C2.12639 20.7119 1.87592 20.5438 1.6653 20.3319V20.3319C1.25876 19.9232 1.02163 19.3759 1.00141 18.7998C0.981184 18.2237 1.17936 17.6612 1.55623 17.2249C1.55698 17.2236 1.55788 17.2224 1.5589 17.2213L1.56362 17.2167L1.77519 17.0041L7.79005 10.9702H7.79107L9.39386 12.5787H9.39488L11.0005 14.1899L12.6046 12.5804L14.2086 10.971L20.4205 17.2022C20.4231 17.2047 20.4255 17.2074 20.4276 17.2104C20.7959 17.6267 20.9987 18.1638 20.9974 18.7197"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#A19A98'}
      strokeMiterlimit="10"
      fill={iconProps?.fill}
    />
  </Svg>
)

ProfileIcon.defaultProps = {
  iconProps: {}
}

export default ProfileIcon
