import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const AverageTimeIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M4.52679 6.66783L5.59727 5.59803L6.66721 4.52809L7.73646 5.59735L8.80763 6.66783L12.9346 2.54089C13.133 2.32387 13.2641 2.05385 13.3119 1.7637C13.3596 1.47354 13.3221 1.17575 13.2037 0.906548C13.0854 0.63735 12.8913 0.40835 12.6452 0.247404C12.3991 0.0864581 12.1115 0.000504634 11.8175 0H1.51571C1.22287 6.71472e-05 0.936314 0.0849442 0.690675 0.244376C0.445036 0.403808 0.250828 0.63097 0.131522 0.898408C0.012216 1.16585 -0.0270798 1.46211 0.0183794 1.75141C0.0638386 2.0407 0.192109 2.31064 0.38769 2.52859L0.503323 2.64436L0.891082 3.03212L4.52679 6.66783V6.66783Z"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Path
      // eslint-disable-next-line max-len
      d="M8.80741 6.66797L7.73747 7.73845L6.66699 8.80839L5.59582 7.73791L4.52657 6.66797L0.892091 10.3023L0.517041 10.678L0.375851 10.8198L0.372844 10.8228C0.18174 11.0422 0.0578064 11.3119 0.0158467 11.5998C-0.026113 11.8877 0.0156743 12.1815 0.136214 12.4463C0.256754 12.7111 0.450952 12.9356 0.695625 13.093C0.940299 13.2504 1.22511 13.3341 1.51604 13.334H11.8178C12.1102 13.3341 12.3964 13.2495 12.6418 13.0905C12.8873 12.9316 13.0815 12.705 13.2011 12.4381C13.3206 12.1713 13.3604 11.8755 13.3157 11.5866C13.2709 11.2976 13.1435 11.0277 12.9488 10.8095L8.80741 6.66797Z"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
  </Svg>
)

AverageTimeIcon.defaultProps = {
  iconProps: {}
}

export default AverageTimeIcon
