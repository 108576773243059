import colors from '@instaseat/lib/themes/colors'
import { Text, VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding: 24px 32px;
  align-items: center;
`

export const ContentTitle = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-block: 30px;
`

export const BoldContentTitle = styled(ContentTitle)`
  font-weight: 700;
`
