import React, { useCallback, useEffect } from 'react'
import {
  Container, FieldContainer, InformationWrapper, Label,
  LogOutButton, LogOutText
} from './styled'
import { getProfileData } from 'store/modules/Hosts'
import { useAppSelector } from 'hooks/redux'
import { signOutThunk } from 'store/modules/auth'
import { useDispatch } from 'store'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'
import { CustomTextInput } from 'components/shared/customTextInput'

const MobileProfileScreen = () => {
  const dispatch = useDispatch()
  const { host: { firstName, lastName, email } } = useAppSelector((store) => store?.host?.profileData)

  useEffect(() => {
    dispatch(getProfileData())
    dispatch(setSelectedHistoryTurn(''))
  }, [dispatch])

  const logOut = useCallback(() => {
    dispatch(signOutThunk())
  }, [])

  return (
    <Container>
      <InformationWrapper>
        <FieldContainer>
          <Label>Name</Label>
          <CustomTextInput value={firstName + ' ' + lastName} isDisabled />
        </FieldContainer>
        <FieldContainer>
          <Label>E-mail</Label>
          <CustomTextInput value={email} isDisabled />
        </FieldContainer>
      </InformationWrapper>
      <LogOutButton onPress={logOut}>
        <LogOutText>
          Log Out
        </LogOutText>
      </LogOutButton>
    </Container>
  )
}

export default MobileProfileScreen
