import React from 'react'
import { NotifyModal } from 'components/notifyModal'
import { CancelModal } from 'components/cancelModal'
import { SeatModal } from 'components/seatModal'
import { TurnDetailModal } from 'components/turnDetailModal'
import { AddTurnModal } from 'components/addTurnModal'
import SuccessModal from 'components/successModal/SuccessModal'
import ErrorModal from 'components/errorModal/ErrorModal'
import { AddTurnSuccessModal } from 'components/addTurnSuccessModal'
import { AddTurnErrorModal } from 'components/addTurnErrorModal'
import { ForgotPasswordModal } from 'components/forgotPasswordModal'
import { BusinessScheduleModal } from 'components/businessScheduleModal'
import ConfirmDragModal from 'components/confirmDragModal/ConfirmDragModal'
import { ReturnToWaitlistModal } from 'components/returnToWaitlistModal'
import { RemoveVIPModal } from 'components/removeVIPModal'

const AllModals = () => {
  return (
    <>
      <CancelModal />
      <NotifyModal />
      <SeatModal />
      <TurnDetailModal />
      <AddTurnModal />
      <AddTurnSuccessModal />
      <AddTurnErrorModal />
      <SuccessModal />
      <ErrorModal />
      <ForgotPasswordModal />
      <BusinessScheduleModal />
      <ConfirmDragModal />
      <ReturnToWaitlistModal />
      <RemoveVIPModal />
    </>
  )
}

export default AllModals
