import styled from 'styled-components/native'
import { View, Text } from 'native-base'
import { Platform } from 'react-native'
import colors from '../../themes/colors'

export const Container = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: ${Platform.OS === 'web' ? '100vh' : '100%'};
`
export const IconWrapper = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
`
export const Title = styled(Text)`
  font-family: "Lato";
  width: 60%;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding-bottom: 24px;
`

export const Subtitle = styled(Text)`
  font-family: "Lato";
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`

export const SkipText = styled(Text)`
  color: ${colors.primary};
  font-family: "Lato";
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  padding-top: 50px;
`
