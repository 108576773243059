const linking = {
  enabled: true,
  prefixes: [],
  config: {
    screens: {
      WaitlistScreen: 'waitlist',
      HistoryScreen: 'history',
      SettingsScreen: 'settings',
      ProfileScreen: 'profile'
    }
  }
}

export default linking
