/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import {
  BoldContentTitle,
  ContentParty,
  ContentTitle,
  NotesText,
  ContentWrapper,
  PreferencePill,
  PreferenceContainer,
  TinyText,
  InnerContentWrapper,
  NotesTitle
} from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { useAppSelector } from 'hooks/redux'
import { Text } from 'native-base'

interface INotifyModalProps {
  onPress: () => void
  isLoading: boolean
  isVisible: boolean
}

const NotifyModalContent = ({ onPress, isLoading, isVisible }: INotifyModalProps) => {
  const modalData = useAppSelector((store) => store.waitlist.selectedTurn)
  const filteredData = modalData && {
    customerName: modalData.customer && `${modalData.customer.firstName} ${modalData.customer.lastName}`.trim(),
    partySize: modalData.party,
    notes: modalData.notes,
    preferences: modalData.turnOptions || []
  }
  const [isNotifyDisabled, setIsNotifyDisabled] = useState(false)

  useEffect(() => {
    isVisible && setIsNotifyDisabled(false)
  }, [isVisible])

  const onPressHandle = () => {
    setIsNotifyDisabled(true)
    onPress()
  }

  return (
    <ContentWrapper space="24px">
      <ContentTitle>
        <Text>
          Is
        </Text>
        <BoldContentTitle>
          {' '}
          {filteredData?.customerName}
        </BoldContentTitle>
        <Text>´s table ready?</Text>
      </ContentTitle>
      <InnerContentWrapper>
        <ContentParty>
          Party of
          {' '}
          {filteredData?.partySize}
        </ContentParty>
        <PreferenceContainer>
          {filteredData?.preferences.map((el, id) => (
            <PreferencePill key={id}>{el}</PreferencePill>
          ))}
        </PreferenceContainer>
        {filteredData?.notes && (
          <>
            <NotesTitle />
            <NotesText>{filteredData?.notes}</NotesText>
          </>
        )}
      </InnerContentWrapper>
      <TinyText>
        The system will notify the customer to confirm that are coming and
        announce with the host.
      </TinyText>
      <ModalButton isLoading={isLoading} onPress={onPressHandle} text="Notify" isDisabled={isNotifyDisabled} />
    </ContentWrapper>
  )
}

export default NotifyModalContent
