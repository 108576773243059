import React, { useRef } from 'react'
import { CustomModal } from '../shared/modal'
import CancelModalContent from './content/Content'
import { useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import { CancelIcon } from './styled'
import { TextInput } from 'react-native'
import { cancelByHost } from 'store/modules/Turns'
import { useDispatch } from 'store'
import { showToast } from 'utils/toastHandler'
import { ToastStatus } from 'components/customToast'

const header = {
  title: 'Cancel',
  IconComponent: <CancelIcon />
}

const CancelModal = () => {
  const showCancelModal = useAppSelector((store) => store.modals.showModal) === 'cancel'
  const turnId = useAppSelector((store) => store.waitlist.selectedTurn?._id)?.toString()
  const dispatch = useDispatch()

  const textInputRef = useRef<TextInput>(null)
  const isLoading = useAppSelector((store) => store.turns.isFetching)

  const onPress = async () => {
    const textInputAsTextArea = textInputRef.current as unknown as HTMLTextAreaElement
    const canceledReason = textInputAsTextArea.value
    const success = turnId && await dispatch(cancelByHost(turnId, canceledReason))
    if (!success) {
      showToast({
        title: 'You can\'t cancel this turn right now!',
        description: 'This action cannot be done.',
        status: ToastStatus.error
      })
    }
    dispatch(hideModal())
  }

  return (
    <CustomModal
      showModal={showCancelModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={(
        <CancelModalContent
          onPress={onPress}
          isLoading={isLoading}
          isVisible={showCancelModal}
          ref={textInputRef}
        />
)}
      initialRef={textInputRef}
      closeOnOverlayClick={false}
    />
  )
}

export { CancelModal }
