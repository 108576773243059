import { ActionCreator, PayloadActionCreator } from 'typesafe-actions'
import {
  HIDE_MODAL,
  SHOW_MODAL,
  SET_MODAL_TEXT
} from './constants'
import { ModalText, showModalPayload } from './types'

export const showModal: PayloadActionCreator<typeof SHOW_MODAL, showModalPayload> = (payload) => ({
  type: SHOW_MODAL,
  payload
})

export const hideModal: ActionCreator<typeof HIDE_MODAL> = () => ({
  type: HIDE_MODAL
})

export const setModalText: PayloadActionCreator<typeof SET_MODAL_TEXT, Partial<ModalText>> = (payload) => ({
  type: SET_MODAL_TEXT,
  payload
})
