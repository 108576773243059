import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const BackButtonIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.35359 5.24244C8.54885 5.04718 8.54885 4.7306 8.35359 4.53534C8.15833 4.34007 7.84174 4.34007 7.64648 4.53534L4.53537 7.64645C4.4416 7.74021 4.38892 7.86739 4.38892 8C4.38892 8.13261 4.4416 8.25978 4.53537 8.35355L7.64648 11.4647C7.84174 11.6599 8.15833 11.6599 8.35359 11.4647C8.54885 11.2694 8.54885 10.9528 8.35359 10.7576L6.09603 8.5H11.1111C11.3873 8.5 11.6111 8.27614 11.6111 8C11.6111 7.72386 11.3873 7.5 11.1111 7.5H6.09603L8.35359 5.24244Z"
      fill={iconProps?.fill || 'white'}
      stroke={iconProps?.stroke ? iconProps?.stroke : '#210f0b9e'}
    />
  </Svg>
)

BackButtonIcon.defaultProps = {
  iconProps: {}
}

export default BackButtonIcon
