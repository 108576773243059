import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { auth } from 'utils/helpers/firebase'
import axiosInterceptors from 'utils/axiosInterceptors'
import { setIsSignedIn, signOutThunk } from 'store/modules/auth'
import { socket } from 'utils/socketIO'
import { onAuthStateChanged } from 'firebase/auth'

const useAuthToken = () => {
  const dispatch = useDispatch()
  const { isSignedIn } = useSelector((store: RootState) => store.auth)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        axiosInterceptors()
        return dispatch(setIsSignedIn(true))
      } else {
        return dispatch(signOutThunk())
      }
    })
    return () => unsubscribe()
  }, [dispatch])

  useEffect(() => {
    if (!isSignedIn) return

    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken()
        if (socket) socket.auth = { token }
      }
      if (!user) {
        dispatch(signOutThunk())
      }
    })

    return () => { unsubscribe() }
  }, [dispatch, isSignedIn])
}

export default useAuthToken
