import colors from '@instaseat/lib/themes/colors'
import { CheckCircleIcon, Text, VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding-inline: 24px;
  padding-block: 32px;
  align-items: center;
`

export const ContentText = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`

export const SuccessIcon = styled(CheckCircleIcon)`
  color: ${colors.success};
  width: 40px;
  height: 40px;
`
