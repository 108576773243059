import styled from 'styled-components/native'
import { Center, HStack, View } from 'native-base'
import colors from '@instaseat/lib/themes/colors'
import { isOutdatedIOSVersion } from 'utils/helpers'

export const ScreenContainer = styled(Center).attrs({
  width: '100%',
  height: '100%',
  bg: colors.white,
  justifyContent: 'center',
  alignContent: 'center'
})``

export const ContentContainer = styled(HStack).attrs({
  space: '6%'
})`
  width: ${isOutdatedIOSVersion() ? '95vw' : '95%'};
  height: ${isOutdatedIOSVersion() ? '80vh' : '100%'};
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
`

export const LeftSideContainer = styled(View).attrs({
  width: '77%',
  height: '82%',
  flexDirection: 'column',
  alignContent: 'flex-end'
})``
