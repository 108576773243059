import React, { useEffect, useState } from 'react'
import { HStack, Skeleton, View } from 'native-base'
import { TouchableOpacity } from 'react-native'
import {
  HeaderContainer,
  HostInitials,
  StyledDivider,
  TitleText,
  UnitName,
  NavBarLogoImage,
  TitleUnderline,
  HostContainer
} from './styled'
import colors from '@instaseat/lib/themes/colors'
import { useDispatch } from 'react-redux'
import { getProfileData } from 'store/modules/Hosts'
import { useAppSelector } from 'hooks/redux'
import { useNavigation, useRoute } from '@react-navigation/native'
import { RootStackNavigationType } from 'types/NavigationTypes'
import { RootStackParamList } from 'types/RootStackParamList'
import { useGetUserType } from 'utils/helpers/firebase'
import { UserTypes } from '@instaseat/lib/enums/users'

const tableTitles = [
  {
    title: 'Waitlist',
    order: 1
  },
  {
    title: 'History',
    order: 2
  },
  {
    title: 'Settings',
    order: 3
  },
  {
    title: 'Profile',
    order: 4
  }
]

const NavBar = () => {
  const [selectedTitle, setSelectedTitle] = useState('Waitlist')
  const unitName = useAppSelector(state => state.host.profileData.unit.name)
  const hostData = useAppSelector(state => state.host.profileData.host)
  const navigation = useNavigation<RootStackNavigationType>()
  const userType = useGetUserType()
  const dispatch = useDispatch()
  const route = useRoute()

  useEffect(() => {
    dispatch(getProfileData())
  }, [dispatch])

  useEffect(() => {
    const currentRoute = route.name.replace('Screen', '')
    setSelectedTitle(currentRoute)
  }, [navigation, route.name])

  return (
    <HeaderContainer>
      <HStack justifyContent="flex-start" alignItems="center">
        <Skeleton
          w="170px"
          isLoaded={!!unitName}
        >
          <NavBarLogoImage />
          <UnitName>
            {unitName}
          </UnitName>
        </Skeleton>
        <StyledDivider style={{ marginTop: '1%', transform: [{ rotate: '90deg' }], right: '5.5%', width: '50px' }} />
      </HStack>
      {tableTitles.map((title) => {
        if (userType !== UserTypes.manager && title.title === 'Settings') return null
        return (
          <TouchableOpacity
            key={title.order}
            style={{
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
            onPress={() => {
              navigation.navigate(
                  `${title.title + 'Screen'}` as keyof RootStackParamList
              )
            }}
          >
            <TitleText selected={title.title === selectedTitle}>
              {title.title}
            </TitleText>
            <TitleUnderline
              bgColor={
                  title.title === selectedTitle
                    ? colors.itemSelected
                    : colors.white
                }
            />
          </TouchableOpacity>
        )
      })}
      <View
        justifyContent="center"
        gap="5%"
      >
        <Skeleton rounded="full" size="35px" alignSelf="flex-end" isLoaded={hostData.firstName !== ''}>
          <HostContainer>
            <HostInitials>
              {hostData?.firstName?.charAt(0) + ' ' + hostData?.lastName?.charAt(0)}
            </HostInitials>
          </HostContainer>
        </Skeleton>
      </View>
    </HeaderContainer>
  )
}

export default NavBar
