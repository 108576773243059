import { TurnDetailsPopulatedTurn } from '@instaseat/lib/interfaces/turn'
import { action } from 'typesafe-actions'
import { ITurnToDrag, TurnsActionConsts } from './types'

export const customerPendingConfirmationPending = () =>
  action(TurnsActionConsts.CUSTOMER_PENDING_CONFIRMATION_PENDING)

export const customerPendingConfirmationFulfilled = () =>
  action(TurnsActionConsts.CUSTOMER_PENDING_CONFIRMATION_FULFILLED)

export const customerPendingConfirmationRejected = (error: string) =>
  action(TurnsActionConsts.CUSTOMER_PENDING_CONFIRMATION_REJECTED, error)

export const cancelByHostPending = () =>
  action(TurnsActionConsts.CANCEL_BY_HOST_PENDING)

export const cancelByHostFulfilled = () =>
  action(TurnsActionConsts.CANCEL_BY_HOST_FULFILLED)

export const cancelByHostRejected = (error: string) =>
  action(TurnsActionConsts.CANCEL_BY_HOST_REJECTED, error)

export const seatCustomerPending = () =>
  action(TurnsActionConsts.SEAT_CUSTOMER_PENDING)

export const seatCustomerFulfilled = () =>
  action(TurnsActionConsts.SEAT_CUSTOMER_FULFILLED)

export const seatCustomerRejected = (error: string) =>
  action(TurnsActionConsts.SEAT_CUSTOMER_REJECTED, error)
export const addNewTurnPending = () =>
  action(TurnsActionConsts.ADD_NEW_TURN_PENDING)

export const addNewTurnFulfilled = () =>
  action(TurnsActionConsts.ADD_NEW_TURN_FULFILLED)

export const addNewTurnRejected = (error: string) =>
  action(TurnsActionConsts.ADD_NEW_TURN_REJECTED, error)

export const getTurnDetailsPending = () =>
  action(TurnsActionConsts.GET_TURN_DETAILS_PENDING)

export const getTurnDetailsFulfilled = (payload: TurnDetailsPopulatedTurn) =>
  action(TurnsActionConsts.GET_TURN_DETAILS_FULFILLED, payload)

export const getTurnDetailsRejected = (error: string) =>
  action(TurnsActionConsts.GET_TURN_DETAILS_REJECTED, error)

export const setTurnToDrag = (payload: ITurnToDrag) =>
  action(TurnsActionConsts.SET_TURN_TO_DRAG, payload)

export const returnTurnToWaitlistPending = () =>
  action(TurnsActionConsts.RETURN_TURN_TO_WAILIST_PENDING)

export const returnTurnToWaitlistFulfilled = (payload: string) =>
  action(TurnsActionConsts.RETURN_TURN_TO_WAILIST_FULFILLED, payload)

export const returnTurnToWaitlistRejected = (error: string) =>
  action(TurnsActionConsts.RETURN_TURN_TO_WAILIST_REJECTED, error)

export const saveTurnID = (id: string) =>
  action(TurnsActionConsts.SAVE_TURN_ID, id)

export const getEstimatedQuoteByPartyPending = () =>
  action(TurnsActionConsts.GET_ESTIMATED_QUOTE_BY_PARTY_PENDING)

export const getEstimatedQuoteByPartyFulfilled = () =>
  action(TurnsActionConsts.GET_ESTIMATED_QUOTE_BY_PARTY_FULFILLED)

export const getEstimatedQuoteByPartyRejected = (error: string) =>
  action(TurnsActionConsts.GET_ESTIMATED_QUOTE_BY_PARTY_REJECTED, error)

export const getCustomerNameByPhoneNumberPending = () =>
  action(TurnsActionConsts.GET_CUSTOMER_NAME_BY_PHONE_NUMBER_PENDING)

export const getCustomerNameByPhoneNumberFulfilled = () =>
  action(TurnsActionConsts.GET_CUSTOMER_NAME_BY_PHONE_NUMBER_FULFILLED)

export const getCustomerNameByPhoneNumberRejected = (error: string) =>
  action(TurnsActionConsts.GET_CUSTOMER_NAME_BY_PHONE_NUMBER_REJECTED, error)

export const removeEmptyVIPPending = () =>
  action(TurnsActionConsts.REMOVE_EMPTY_VIP_PENDING)

export const removeEmptyVIPFulfilled = () =>
  action(TurnsActionConsts.REMOVE_EMPTY_VIP_FULFILLED)

export const removeEmptyVIPRejected = (error: string) =>
  action(TurnsActionConsts.REMOVE_EMPTY_VIP_REJECTED, error)
