import { Reducer } from 'redux'
import { HostState, HostActionType } from './types'
import * as Constants from './constants'
import { ApprovalStatus } from '@instaseat/lib/enums/approvalStatus'
import { Status } from '@instaseat/lib/enums/status'

const initialState = {
  isLoading: false,
  error: '',
  isScheduleEdited: false,
  scheduleData: [
    {
      businessOpeningHour: 0,
      businessClosingHour: 0,
      splitShiftBusinessOpeningHour: 0,
      splitShiftBusinessClosingHour: 0,
      days: ''
    }
  ],
  profileData: {
    host: {
      _id: '',
      firstName: '',
      lastName: '',
      phone: '',
      address: '',
      shift: '',
      email: ''
    },
    unit: {
      businessHours: [
        {
          businessOpeningHour: 0,
          businessClosingHour: 0,
          splitShiftBusinessOpeningHour: 0,
          splitShiftBusinessClosingHour: 0,
          days: ''
        }
      ],
      waitlistInfo: {
        newWaitTime: 0,
        peopleQtyAhead: 0,
        peopleQtySkippedWithVIP: 0,
        savedTimeWithVIP: 0,
        VIPSQty: 0,
        VIPSOccupied: 0,
        VIPSAvailable: 0
      },
      _id: '',
      name: '',
      description: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      address: '',
      phone: '',
      approvalStatus: ApprovalStatus.approved,
      status: Status.active,
      logoUrl: '',
      turnPreferences: {
        _id: '',
        courtesyTime: 0,
        fourteenTopsOrLessQuote: 0,
        fifteenTopsOrMoreQuote: 0,
        fourTopsOrLessDeviationTime: 0,
        fourToFifteenTopsDeviationTime: 0,
        fifteenTopsOrMoreDeviationTime: 0,
        maxVipPlaces: 0,
        vipPrice: {
          amount: 0,
          currency: ''
        }
      },
      restaurantGroup: '',
      VIPpercentage: 0,
      waitlist: [],
      turnOptions: [],
      roundingValue: 0
    }
  }
}

export const hostReducer: Reducer<HostState, HostActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_PROFILE_DATA_PENDING:
    case Constants.UPDATE_TURN_PREFERENCES_DATA_PENDING:
    case Constants.UPDATE_UNIT_DATA_PENDING:
    case Constants.UPDATE_HOST_DATA_PENDING:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case Constants.GET_PROFILE_DATA_COMPLETED:
    {
      const payload = action.payload
      return {
        ...state,
        isLoading: false,
        scheduleData: payload.unit.businessHours,
        profileData: {
          ...state.profileData,
          host: payload.host || payload.manager,
          unit: payload.unit
        }
      }
    }
    case Constants.UPDATE_UNIT_DATA_COMPLETED:
    case Constants.UPDATE_TURN_PREFERENCES_DATA_COMPLETED:
    case Constants.UPDATE_HOST_DATA_COMPLETED:
    {
      return {
        ...state,
        isLoading: false,
        isScheduleEdited: false
      }
    }
    case Constants.GET_PROFILE_DATA_REJECTED:
    case Constants.UPDATE_TURN_PREFERENCES_DATA_REJECTED:
    case Constants.UPDATE_UNIT_DATA_REJECTED:
    case Constants.UPDATE_HOST_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload as string
      }
    case Constants.SAVE_SCHEDULE_DATA: {
      return {
        ...state,
        scheduleData: [...action.payload]
      }
    }
    case Constants.DELETE_SCHEDULE: {
      const payload = state.scheduleData.filter((day) => day.days !== action.payload)
      return {
        ...state,
        scheduleData: payload
      }
    }
    case Constants.IS_SCHEDULE_EDITED: {
      return {
        ...state,
        isScheduleEdited: true
      }
    }
    default:
      return state
  }
}
