import colors from '@instaseat/lib/themes/colors'
import { Text, VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding: 42px;
  min-height: 244px;
  justify-content: space-between;
`

export const ContentTitle = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const BoldContentTitle = styled(ContentTitle)`
  font-weight: 700;
`

export const Label = styled.Text`
  color: ${colors.primaryBlack};
  font-style: italic;
  margin-bottom: 8px;
`

export const Error = styled.Text`
  color: ${colors.errorColor};
  font-size: 12px;
  font-style: italic;
  margin-top: 15px;
`
