import { Dimensions } from 'react-native'

export const formatNumber = (number: string) => {
  const parsedNumber = number.toString()
  const formattedNumber = parsedNumber?.replace(/[^\d.]/g, '')
  return formattedNumber
}

export const GetScreenDimensions = () => {
  const dimensions = {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width
  }

  return dimensions
}

export const isOutdatedIOSVersion = () => {
  const ua = navigator.userAgent
  const iPad4Res = {
    width: 820,
    height: 1106
  }

  // In order to test this on emulators we must negate this expression
  return /iPad/.test(ua) &&
    GetScreenDimensions().width <= iPad4Res.width &&
    GetScreenDimensions().height <= iPad4Res.height
}
