/* eslint-disable react/require-default-props */
import React, { forwardRef, useEffect } from 'react'
import { ContentWrapper, Label, Error } from './styled'
import { TextInput } from 'react-native'
import { FormControl, Input, WarningIcon, Button } from 'native-base'
import { Controller, useForm, useFormState } from 'react-hook-form'
import colors from '@instaseat/lib/themes/colors'
import { IHostPasswordSubmitData } from '@instaseat/lib/interfaces/host'
import { useAppSelector } from 'hooks/redux'

interface props {
  buttonText: string;
  isFetching: boolean;
  error?: string;
  submit?: (payload: IHostPasswordSubmitData) => void;
}

const ForgotPasswordContent = forwardRef<TextInput, props>((props, ref) => {
  const profileData = useAppSelector((store) => store?.host?.profileData?.host)

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: ''
    }
  })

  useEffect(() => {
    if (profileData.email) {
      return reset({ email: profileData.email })
    }
    reset({ email: '' })
  }, [profileData.email, reset])

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty && !profileData.email

  const handleInputFocus = () => {
    clearErrors()
  }

  const onSubmit = (data: IHostPasswordSubmitData) => {
    clearErrors()
    props.submit && props.submit(data)
  }

  return (
    <ContentWrapper space="24px">
      <FormControl isInvalid={'email' in errors}>
        <Label>e-mail</Label>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              ref={ref}
              backgroundColor={colors.secondary}
              borderWidth={0}
              elevation={4}
              borderRadius="xl"
              placeholder="john.doe@instaseat.com"
              fontStyle="italic"
              fontSize={13}
              size="xl"
              paddingLeft={5}
              height="40px"
              paddingTop={1}
              shadow={4}
              testID="emailInput"
              onBlur={onBlur}
              onFocus={() => handleInputFocus()}
              onChangeText={value => onChange(value)}
              value={value}
              isDisabled={profileData.email}
              autoCapitalize="none"
              keyboardType="email-address"
              w="100%"
            />
          )}
          rules={{ required: true }}
        />
        <FormControl.ErrorMessage
          testID="host-email-error-message"
          leftIcon={<WarningIcon size={5} />}
          _text={{ style: { color: colors.errorColor, fontStyle: 'italic', fontSize: 12, fontFamily: 'Lato' } }}
        >
          {errors.email?.message}
        </FormControl.ErrorMessage>
        <Error
          testID="host-forgot-password-errors-messages"
        >
          {props.error}
        </Error>
      </FormControl>
      <Button
        size="lg"
        borderRadius="xl"
        onPress={handleSubmit(onSubmit)}
        isDisabled={isDisabled || props.isFetching}
        loading={props.isFetching}
      >
        {props.buttonText || 'Continue'}
      </Button>
    </ContentWrapper>
  )
})

ForgotPasswordContent.displayName = 'ForgotPasswordContent'

export default ForgotPasswordContent
