import React from 'react'
import { ContentText, ContentWrapper, ErrorIcon } from './styled'
import { ModalButton } from 'components/shared/modalButton'

interface IAddTurnErrorModalContentProps {
  content: string
  onPress: () => void
}

const AddTurnErrorModalContent = ({ content, onPress }: IAddTurnErrorModalContentProps) => (
  <ContentWrapper space="40px">
    <ErrorIcon />
    <ContentText>
      {`Unable to enter ${content} at the waitlist.`}
    </ContentText>
    <ModalButton onPress={onPress} text="Try Again" />
  </ContentWrapper>
)

export default AddTurnErrorModalContent
