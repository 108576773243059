import styled from 'styled-components/native'
import colors from '@instaseat/lib/themes/colors'
import { View, Text } from 'native-base'

export const Container = styled(View).attrs({
  shadow: 3
})`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${colors.bgGrey};
  width: 70px;
  height: 70px;
`

export const DataText = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: ${(props) => props.color};
  text-align: center;
`

export const CardText = styled(Text)`
  margin-top: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.3px;
  text-align: center;
  align-self: center;
  white-space: normal;
  color: ${(props) => props.color};
`
