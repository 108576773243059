import React, { useEffect, useState } from 'react'
import { BoldContentTitle, ContentTitle, ContentWrapper, DescriptionText } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { useAppSelector } from 'hooks/redux'
import { seatCustomer } from 'store/modules/Turns'
import { useDispatch } from 'store'
import { hideModal } from 'store/modules/Modals'
import { showToast } from 'utils/toastHandler'
import { ToastStatus } from 'components/customToast'

interface ISeatModalContent {
  isVisible: boolean
}

const SeatModalContent = ({ isVisible }: ISeatModalContent) => {
  const turnId = useAppSelector((store) => store.waitlist.selectedTurn?._id)?.toString()
  const modalData = useAppSelector((store) => store.waitlist.selectedTurn)
  const customerName = modalData && `${modalData.customer?.firstName} ${modalData.customer?.lastName}`.trim()
  const isLoading = useAppSelector((store) => store.turns.isFetching)
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)
  const dispatch = useDispatch()

  const onPressHandle = async () => {
    setIsConfirmDisabled(true)
    const success = turnId && await dispatch(seatCustomer(turnId))
    if (!success) {
      showToast({
        title: 'You can\'t assign this seat right now!',
        description: 'This action cannot be done.',
        status: ToastStatus.error
      })
    }
    dispatch(hideModal())
  }

  useEffect(() => {
    isVisible && setIsConfirmDisabled(false)
  }, [isVisible])

  return (
    <ContentWrapper space="40px">
      <ContentTitle>
        Is
        {' '}
        <BoldContentTitle>{customerName}</BoldContentTitle>
        ´s here?
      </ContentTitle>
      <DescriptionText>
        The system will remove the customer from the Waitlist.
      </DescriptionText>
      <ModalButton onPress={onPressHandle} text="Confirm" isLoading={isLoading} isDisabled={isConfirmDisabled} />
    </ContentWrapper>
  )
}

export default SeatModalContent
