import React, { useEffect } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import { PillPressable, PreferencePill } from './styled'

export interface PillButtonProps<TFormValues> {
  control: Control<TFormValues>
  name: Path<TFormValues>
  isVisible?: boolean
}

const PillButton = <TFormValues extends
{[key: string] : string | { [k: string]: boolean}}>({
    control,
    name,
    isVisible
  }:PillButtonProps<TFormValues>) => {
  const [checked, setChecked] = React.useState(false)
  useEffect(() => {
    isVisible && setChecked(false)
  }, [isVisible])
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <PillPressable
            onPress={() => {
              setChecked((prevValue) => {
                onChange(!prevValue)
                return !checked
              })
            }}
          >
            <PreferencePill selected={checked}>{name.split('.')[1]}</PreferencePill>
          </PillPressable>
        )
      }}
      rules={{ required: true }}
    />

  )
}

PillButton.defaultProps = {
  isVisible: undefined
}

export default PillButton
