import colors from '@instaseat/lib/themes/colors'
import { FormControl } from 'native-base'
import styled from 'styled-components/native'

export const ControllerTextAreaWrapper = styled(FormControl)`
  margin-inline: auto;
  flex: 1;
  margin-bottom: ${(props) => props.isInvalid ? '30' : '56'}px;
`

export const Label = styled(FormControl.Label)`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.labelDisabled};
`

export const ErrorLabel = styled(FormControl.ErrorMessage)`
  color: ${colors.errorColor};
  font-family: 'Lato';
  font-style: 'italic';
  font-size: 12px;
`
