import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  ButtonWrapper, ContentWrapper, DescriptionText, Label,
  ButtonContainer, ContentContainer, ErrorLabel, ButtonWrapperRadio, CustomTimeText, DayErrorContainer
} from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { ControllerInput } from 'components/addTurnModal/controllerInput'
import { useForm } from 'react-hook-form'
import { Checkbox, Radio, Text, View } from 'native-base'
import { CheckButton } from 'components/businessScheduleModal/checkButton'
import { hideModal } from 'store/modules/Modals'
import { useDispatch } from 'react-redux'
import { isScheduleEdited, saveScheduleData } from 'store/modules/Hosts'
import { useAppSelector } from 'hooks/redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
// import colors from '@instaseat/lib/themes/colors'

export type ScheduleValues = {
  businessOpeningHour: number | null,
  businessClosingHour: number | null,
  splitShiftBusinessOpeningHour: number | null,
  splitShiftBusinessClosingHour: number | null,
  preferences: {
    [key: string]: boolean,
  }
}

const allDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const daysTable = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
}

const validation = yup.object({
  businessOpeningHour: yup.string()
    .required('Opening Hour is required.')
    .nullable()
    .matches(/\b(0?[1-9]|1[0-2])\b/g, 'Opening Hour should between 1 and 12.'),
  businessClosingHour: yup.string()
    .required('Closing Hour is required.')
    .nullable()
    .matches(/\b(0?[1-9]|1[0-2])\b/g, 'Closing Hour should between 1 and 12.'),
  splitShiftBusinessOpeningHour: yup
    .string()
    .nullable()
    .optional()
    .notRequired()
    .matches(/\b(0?[1-9]|1[0-2])\b/g, 'Opening Hour should between 1 and 12.'),
  splitShiftBusinessClosingHour: yup
    .string()
    .nullable()
    .optional()
    .notRequired()
    .matches(/\b(0?[1-9]|1[0-2])\b/g, 'Opening Hour should between 1 and 12.'),
  preferences: yup.object().shape({
    Sun: yup.bool(),
    Mon: yup.bool(),
    Tue: yup.bool(),
    Wed: yup.bool(),
    Thu: yup.bool(),
    Fri: yup.bool(),
    Sat: yup.bool()
  })
    .test({
      name: 'one-true',
      message: 'Select a day.',
      test: (val) => Object.values(val).some((day) => day === true)
    })
})

const BusinessScheduleModalContent = ({ isVisible }: { isVisible: boolean }) => {
  const dispatch = useDispatch()

  const initialValues = useMemo(() => ({
    openingHour: 'am',
    closingHour: 'am',
    openingHourShift: 'am',
    closingHourShift: 'am'
  }), [])
  const scheduleData = useAppSelector((store) => store?.host?.scheduleData)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [radioValues, setRadioValues] = useState(initialValues)
  const [daysArray, setDaysArray] = useState([''])
  const preferences = allDays.filter((day) => !daysArray.includes(day) && day)

  const filterDays = useCallback(() => {
    const parsedArray = scheduleData.map((thing) => {
      return thing?.days?.slice(0, 3)
    })
    setDaysArray(parsedArray)
  }, [scheduleData])

  useEffect(() => {
    filterDays()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData])

  useEffect(() => {
    if (!isVisible) {
      setCheckboxValue(false)
      setRadioValues(initialValues)
    }
  }, [initialValues, isVisible])

  const {
    control,
    formState: { errors, isSubmitting, isSubmitted },
    clearErrors,
    handleSubmit,
    reset,
    watch,
    setValue
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      businessOpeningHour: null,
      businessClosingHour: null,
      splitShiftBusinessOpeningHour: null,
      splitShiftBusinessClosingHour: null,
      preferences: Object.fromEntries(preferences.map((el) => [el, false]))
    },
    resolver: yupResolver(validation)
  })

  const handleHours = (hour: number | null, time: string) => {
    let finalHour = Number(hour && hour)
    if (time === 'am' && finalHour === 12) {
      finalHour = 0
    }
    if (time === 'pm' && finalHour !== 12) {
      finalHour = finalHour + 12
    }

    return finalHour || 0
  }

  const onSubmit = (data: ScheduleValues) => {
    const parseDay = Object.entries(data.preferences).filter((data) => data[1] === true)
    const payload = {
      businessOpeningHour: handleHours(data.businessOpeningHour && data.businessOpeningHour, radioValues.openingHour),
      businessClosingHour: handleHours(data.businessClosingHour && data.businessClosingHour, radioValues.closingHour),
      splitShiftBusinessOpeningHour:
        handleHours(data.splitShiftBusinessOpeningHour &&
          data.splitShiftBusinessOpeningHour, radioValues.openingHourShift),
      splitShiftBusinessClosingHour:
        handleHours(data.splitShiftBusinessClosingHour &&
          data.splitShiftBusinessClosingHour, radioValues.closingHourShift),
      days: daysTable[parseDay[0][0] as keyof typeof daysTable]
    }
    scheduleData.push(payload)
    dispatch(saveScheduleData(scheduleData))
    filterDays()
    dispatch(isScheduleEdited())
    dispatch(hideModal())
  }

  const preferenciesWatch = watch('preferences')

  useEffect(() => {
    isVisible && reset()
  }, [isVisible, reset])

  const handleCheckbox = () => {
    setCheckboxValue(!checkboxValue)
    clearErrors('splitShiftBusinessClosingHour')
    clearErrors('splitShiftBusinessOpeningHour')
    setValue('splitShiftBusinessClosingHour', null)
    setValue('splitShiftBusinessOpeningHour', null)
  }

  useEffect(() => {
    const isTrue = Object.values(preferenciesWatch).some((value) => value === true)
    if (isTrue) {
      clearErrors('preferences')
    }
  }, [clearErrors, preferenciesWatch])

  return (
    <ContentWrapper>
      <ContentContainer>
        <DescriptionText>
          By selecting the days of the week, the opening and closing times can be set.
        </DescriptionText>
        <View
          display="flex"
          flexDirection="row"
        >
          {preferences.map((el) => (
            <CheckButton
              preferenciesWatch={preferenciesWatch}
              setValue={setValue}
              control={control}
              name={`preferences.${el}`}
              isVisible={isVisible}
              key={el}
            />
          ))}
        </View>
        <DayErrorContainer>
          {errors.preferences?.message && (
            <View mt="1.5px">
              <CustomIcons
                iconName={IconName.WarningIcon}
                iconProps={{
                  fill: '#dc2626',
                  fillOpacity: 1,
                  width: '16px',
                  height: '16px'
                }}
              />
            </View>
          )}
          <ErrorLabel>{errors.preferences?.message || ' '}</ErrorLabel>
        </DayErrorContainer>
        <ButtonContainer>
          <View
            display="flex"
            flexDirection="row"
            paddingBottom={2}
          >
            <Checkbox
              isChecked={checkboxValue}
              onChange={() => handleCheckbox()}
              value=""
            />
            <Text
              paddingLeft={2}
              fontFamily="Lato"
              fontStyle="italic"
            >
              Splitted shift?
            </Text>
          </View>
          <View
            display="flex"
            flexDirection="column"
          >
            <View
              display="flex"
              flexDirection="row"
            >
              <ButtonWrapper>
                <Label>Business Opening hour</Label>
                <ControllerInput
                  control={control}
                  name="businessOpeningHour"
                  placeholder="08:00am"
                  isInvalid={'businessOpeningHour' in errors}
                  errorMsg={errors.businessOpeningHour?.message}
                  onFocus={clearErrors}
                  numeric
                />
              </ButtonWrapper>
              <ButtonWrapperRadio>
                <Radio.Group
                  name="group1"
                  accessibilityLabel="Am"
                  defaultValue='am'
                  value={radioValues.openingHour}
                  onChange={(value) => setRadioValues((prevRadioValues) => ({
                    ...prevRadioValues,
                    openingHour: value
                  }))}
                >
                  <Radio
                    value="am"
                  >
                    <CustomTimeText>
                      Am
                    </CustomTimeText>
                  </Radio>
                  <Radio
                    value="pm"
                    marginTop="5px"
                  >
                    <CustomTimeText>
                      Pm
                    </CustomTimeText>
                  </Radio>
                </Radio.Group>
              </ButtonWrapperRadio>
              <ButtonWrapper>
                <Label>Business Closing hour</Label>
                <ControllerInput
                  control={control}
                  name="businessClosingHour"
                  placeholder="12:00 pm"
                  isInvalid={'businessClosingHour' in errors}
                  errorMsg={errors.businessClosingHour?.message}
                  onFocus={clearErrors}
                  numeric
                />
              </ButtonWrapper>
              <ButtonWrapperRadio>
                <Radio.Group
                  name="group2"
                  accessibilityLabel="Am"
                  defaultValue='am'
                  value={radioValues.closingHour}
                  onChange={(value) => setRadioValues((prevRadioValues) => ({
                    ...prevRadioValues,
                    closingHour: value
                  }))}
                >
                  <Radio
                    value="am"
                  >
                    <CustomTimeText>
                      Am
                    </CustomTimeText>
                  </Radio>
                  <Radio
                    value="pm"
                    marginTop="5px"
                  >
                    <CustomTimeText>
                      Pm
                    </CustomTimeText>
                  </Radio>
                </Radio.Group>
              </ButtonWrapperRadio>
            </View>
            {
              checkboxValue && (
                <View
                  display="flex"
                  flexDirection="row"
                  marginBottom="10px"
                >
                  <ButtonWrapper>
                    <Label>Business Opening hour</Label>
                    <ControllerInput
                      control={control}
                      name="splitShiftBusinessOpeningHour"
                      placeholder="08:00am"
                      isInvalid={'splitShiftBusinessOpeningHour' in errors}
                      errorMsg={errors.splitShiftBusinessOpeningHour?.message}
                      onFocus={clearErrors}
                      numeric
                    />
                  </ButtonWrapper>
                  <ButtonWrapperRadio>
                    <Radio.Group
                      name="group3"
                      accessibilityLabel="Am"
                      defaultValue='am'
                      value={radioValues.openingHourShift}
                      onChange={(value) => setRadioValues((prevRadioValues) => ({
                        ...prevRadioValues,
                        openingHourShift: value
                      }))}
                    >
                      <Radio
                        value="am"
                      >
                        <CustomTimeText>
                          Am
                        </CustomTimeText>
                      </Radio>
                      <Radio
                        value="pm"
                      >
                        <CustomTimeText>
                          Pm
                        </CustomTimeText>
                      </Radio>
                    </Radio.Group>
                  </ButtonWrapperRadio>
                  <ButtonWrapper>
                    <Label>Business Closing hour</Label>
                    <ControllerInput
                      control={control}
                      name="splitShiftBusinessClosingHour"
                      placeholder="12:00 pm"
                      isInvalid={'splitShiftBusinessClosingHour' in errors}
                      errorMsg={errors.splitShiftBusinessClosingHour?.message}
                      onFocus={clearErrors}
                      numeric
                    />
                  </ButtonWrapper>
                  <ButtonWrapperRadio>
                    <Radio.Group
                      name="group4"
                      accessibilityLabel="Am"
                      defaultValue='am'
                      value={radioValues.closingHourShift}
                      onChange={(value) => setRadioValues((prevRadioValues) => ({
                        ...prevRadioValues,
                        closingHourShift: value
                      }))}
                    >
                      <Radio
                        value="am"
                      >
                        <CustomTimeText>
                          Am
                        </CustomTimeText>
                      </Radio>
                      <Radio
                        value="pm"
                      >
                        <CustomTimeText>
                          Pm
                        </CustomTimeText>
                      </Radio>
                    </Radio.Group>
                  </ButtonWrapperRadio>
                </View>
              )
            }
          </View>
        </ButtonContainer>
        <View w="260px">
          <ModalButton
            onPress={handleSubmit(onSubmit)}
            text="Add"
            isLoading={isSubmitting}
            isDisabled={isSubmitted}
          />
        </View>
      </ContentContainer>
    </ContentWrapper>
  )
}

export default BusinessScheduleModalContent
