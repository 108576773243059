import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
import colors from '../../themes/colors'
interface props {
  iconProps?: SvgProps
}

const HostOnItsWayIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.1543 13.0286C11.9959 13.0286 12.6781 12.3463 12.6781 11.5048C12.6781 10.6632 11.9959 9.98095 11.1543 9.98095C10.3127 9.98095 9.63047 10.6632 9.63047 11.5048C9.63047 12.3463 10.3127 13.0286 11.1543 13.0286ZM18.6209 11.6952C18.6209 12.8524 17.6829 13.7905 16.5257 13.7905C15.3685 13.7905 14.4305 12.8524 14.4305 11.6952C14.4305 10.5381 15.3685 9.6 16.5257 9.6C17.6829 9.6 18.6209 10.5381 18.6209 11.6952ZM5.94286 12.6476C6.57404 12.6476 7.08571 12.1359 7.08571 11.5048C7.08571 10.8736 6.57404 10.3619 5.94286 10.3619C5.31167 10.3619 4.8 10.8736 4.8 11.5048C4.8 12.1359 5.31167 12.6476 5.94286 12.6476Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={iconProps.fill ? iconProps.fill : colors.tertiary}
    />
  </Svg>
)

HostOnItsWayIcon.defaultProps = {
  iconProps: {}
}

export default HostOnItsWayIcon
