import React, { useEffect } from 'react'
import {
  Container, ButtonWrapper, ButtonContainer, Label, SaveChangesButtonContainer,
  LogOutButton, LogOutText, SaveButton,
  ChangePassword, ChangePasswordWrapper, SaveChangesButtonWrapper, PhoneLeftComponent
} from './styled'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ControllerInput } from 'components/addTurnModal/controllerInput'
import { useForm, useFormState } from 'react-hook-form'
import { getProfileData, updateHostData } from 'store/modules/Hosts'
import { useAppSelector } from 'hooks/redux'
import { setModalText, showModal } from 'store/modules/Modals'
import { TouchableOpacity } from 'react-native'
import { signOutThunk } from 'store/modules/auth'
import { useDispatch } from 'store'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'
import { regex } from '@instaseat/lib/utils/helpers/helpers'

export interface Profile {
  firstName: string,
  lastName: string,
  shift: string,
  phone: string,
  address: string,
  email: string
}

const validator = yup.object({
  shift: yup
    .string()
    .nullable()
    .transform((value) => value || null)
    .min(2, 'Shift is too short.')
    .max(30, 'Shift is too long.')
    .matches(
      /^[a-zñáéíóúü\-'’´&]+(([ ][a-zñáéíóúü\-'’´&]+)*)?$/i,
      "Just one space between words and this special characters (-'’´&) are allowed."
    )
    .matches(
      /^(?!.*[-'’´&][-'’´&]).+$/,
      'Special characters are not allowed together.'
    ),
  phone: yup
    .string()
    .required('Phone Number is required.')
    .min(10, 'Phone number should have 10 or more digits')
    .max(12, 'Phone number should have 12 or less digits')
    .matches(regex.e164, 'Phone number badly formatted.'),
  address: yup
    .string()
    .nullable()
    .transform((value) => value || null)
    .min(2, 'Must be between 2 and 50 characters')
    .max(50, 'Must be between 2 and 50 characters'),
  firstName: yup.string()
    .required('First Name is required.')
    .min(2, 'Must be between 2 and 30 characters.')
    .max(30, 'Must be between 2 and 30 characters.')
    .matches(
      /^[a-zñáéíóúü\-'’´&]+(([ ][a-zñáéíóúü\-'’´&]+)*)?$/i,
      "Just one space between words and this special characters (-'’´&) are allowed."
    )
    .matches(
      /^(?!.*[-'’´&][-'’´&]).+$/,
      'Special characters are not allowed together.'
    ),
  lastName: yup.string()
    .required('Last Name is required.')
    .min(2, 'Must be between 2 and 30 characters.')
    .max(30, 'Must be between 2 and 30 characters.')
    .matches(
      /^[a-zñáéíóúü\-'’´&]+(([ ][a-zñáéíóúü\-'’´&]+)*)?$/i,
      "Just one space between words and this special characters (-'’´&) are allowed."
    )
    .matches(
      /^(?!.*[-'’´&][-'’´&]).+$/,
      'Special characters are not allowed together.'
    )
})

const ProfileScreen = () => {
  const dispatch = useDispatch()
  const isLoading = useAppSelector((store) => store?.host?.isLoading)
  const formData = useAppSelector((store) => store?.host?.profileData)

  useEffect(() => {
    dispatch(getProfileData())
    dispatch(setSelectedHistoryTurn(''))
  }, [dispatch])

  const logOut = () => {
    dispatch(signOutThunk())
  }

  const handleChangePassword = () => {
    dispatch(setModalText({ headerText: 'Change password', buttonText: 'Request change' }))
    dispatch(showModal('forgotPassword'))
  }

  const defaultValues = {
    firstName: formData?.host?.firstName || '',
    lastName: formData?.host?.lastName || '',
    shift: formData?.host?.shift || '',
    phone: formData?.host?.phone.slice(2) || '',
    address: formData?.host?.address || '',
    email: formData?.host?.email || ''
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(validator)
  })

  const { isDirty, isValid } = useFormState({
    control
  })

  useEffect(() => {
    reset(defaultValues)
  }, [formData])

  const handleScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const onSubmit = async (data: Profile) => {
    const payload = {
      ...data,
      phone: '+1' + data.phone
    }

    const responseHost: string = await dispatch(updateHostData(formData?.host?._id, payload))
    if (responseHost === 'UPDATED') {
      dispatch(showModal('success'))
    } else {
      dispatch(showModal('error'))
    }
    handleScroll()
  }

  const isDisabled = !isDirty || !isValid

  return (
    <Container>
      <ButtonContainer>
        <ButtonWrapper>
          <Label>First Name</Label>
          <ControllerInput
            control={control}
            name='firstName'
            placeholder="John"
            isInvalid={'firstName' in errors}
            errorMsg={errors.firstName?.message}
            onFocus={clearErrors}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Label>Last Name</Label>
          <ControllerInput
            control={control}
            name='lastName'
            placeholder="Doe"
            isInvalid={'lastName' in errors}
            errorMsg={errors.lastName?.message}
            onFocus={clearErrors}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Label>Shift</Label>
          <ControllerInput
            control={control}
            placeholder="Morning"
            name='shift'
            isInvalid={'shift' in errors}
            errorMsg={errors.shift?.message}
            onFocus={clearErrors}
          />
        </ButtonWrapper>
      </ButtonContainer>
      <ButtonContainer>
        <ButtonWrapper>
          <Label>Phone</Label>
          <ControllerInput
            control={control}
            name='phone'
            placeholder="12323456"
            isInvalid={'phone' in errors}
            errorMsg={errors.phone?.message}
            LeftComponent={<PhoneLeftComponent />}
            onFocus={clearErrors}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Label>Address</Label>
          <ControllerInput
            control={control}
            name='address'
            placeholder="Avenue 123"
            isInvalid={'address' in errors}
            errorMsg={errors.address?.message}
            onFocus={clearErrors}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Label>E-mail</Label>
          <ControllerInput
            control={control}
            name='email'
            isInvalid={'email' in errors}
            errorMsg={errors.email?.message}
            onFocus={clearErrors}
            isDisabled
          />
        </ButtonWrapper>
      </ButtonContainer>
      <SaveChangesButtonContainer>
        <SaveChangesButtonWrapper>
          <SaveButton
            _text={
              {
                fontSize: '18px'
              }
            }
            opacity={isDisabled ? '0.6' : 'none'}
            disabled={isDisabled}
            isLoading={isLoading}
            onPress={handleSubmit(onSubmit)}
          >
            Save changes
          </SaveButton>
          <ChangePasswordWrapper>
            <TouchableOpacity onPress={() => handleChangePassword()} testID="changePasswordID">
              <ChangePassword>Change password</ChangePassword>
            </TouchableOpacity>
          </ChangePasswordWrapper>
          <TouchableOpacity
            style={{
              justifyContent: 'flex-end'
            }}
          >
            <LogOutButton>
              <LogOutText onPress={() => logOut()}>
                Log Out
              </LogOutText>
            </LogOutButton>
          </TouchableOpacity>
        </SaveChangesButtonWrapper>
      </SaveChangesButtonContainer>
    </Container>
  )
}

export default ProfileScreen
