import React, { useEffect, useState } from 'react'
import AppStoreBadge from '@instaseat/lib/assets/badges/AppStoreBadge'
import GooglePlayBadgeSvg from '@instaseat/lib/assets/badges/GooglePlayBadge'
import { AppleBadgeContainer, BadgesContainer, Container, GooglePlayBadgeContainer, LogoImg, TitleText } from './styled'
import { getBaseOs } from 'react-native-device-info'

const DownloadScreen = () => {
  const [currentOS, setCurrentOS] = useState<string>('')

  useEffect(() => {
    getBaseOs().then((baseOs) => {
      setCurrentOS(baseOs)
    })
  }, [])

  const getBadges = () => {
    switch (currentOS) {
      case 'Android':
        return (
          <GooglePlayBadgeContainer>
            <GooglePlayBadgeSvg />
          </GooglePlayBadgeContainer>
        )
      case 'iOS':
        return (
          <AppleBadgeContainer>
            <AppStoreBadge />
          </AppleBadgeContainer>
        )
      default:
        return (
          <>
            <AppleBadgeContainer mr='6%'>
              <AppStoreBadge />
            </AppleBadgeContainer>
            <GooglePlayBadgeContainer>
              <GooglePlayBadgeSvg />
            </GooglePlayBadgeContainer>
          </>
        )
    }
  }

  return (
    <Container>
      <LogoImg
        source={require('assets/logos/logoTitle.png')}
      />
      <TitleText>
        Download our App!
      </TitleText>
      <BadgesContainer>
        {getBadges()}
      </BadgesContainer>
    </Container>
  )
}

export default DownloadScreen
