import React from 'react'
import { Select } from 'native-base'
import {
  ButtonContainer, ButtonList, ButtonWrapper, Label,
  WaitlistContainer, WaitlistTitle, InputRightAddonStyled
} from './styled'
import { useFormContext, Controller } from 'react-hook-form'
import { ControllerInput } from 'components/addTurnModal/controllerInput'
import { SettingsFormValues } from '..'

const WaitlistParameters = () => {
  const { control, clearErrors, formState: { errors } } = useFormContext<SettingsFormValues>()

  return (
    <WaitlistContainer>
      <WaitlistTitle>
        Waitlist parameters
      </WaitlistTitle>
      <ButtonContainer>
        <ButtonList>
          <ButtonWrapper>
            <Label>Courtesy Time</Label>
            <ControllerInput
              control={control}
              name="courtesyTime"
              placeholder='Time amount'
              isInvalid={'courtesyTime' in errors}
              errorMsg={errors.courtesyTime?.message}
              onFocus={clearErrors}
              numeric
              RightComponent={
                <InputRightAddonStyled>min</InputRightAddonStyled>
              }
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Label>Rounding of quotes</Label>
            <Controller
              name="roundingValue"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  selectedValue={value.toString()}
                  accessibilityLabel="Round quotes to"
                  minWidth="200px"
                  placeholder="Round quotes up to"
                  mt={3}
                  onValueChange={value => onChange(Number(value))}
                >
                  <Select.Item label="No rounding" value="1" />
                  <Select.Item label="5 minutes" value="5" />
                  <Select.Item label="10 minutes" value="10" />
                </Select>
              )}
            />
          </ButtonWrapper>
        </ButtonList>
        <ButtonList>
          <ButtonWrapper>
            <Label>14 tops or less quote</Label>
            <ControllerInput
              control={control}
              name="fourteenTopsOrLessQuote"
              placeholder='Time amount'
              isInvalid={'fourteenTopsOrLessQuote' in errors}
              errorMsg={errors.fourteenTopsOrLessQuote?.message}
              onFocus={clearErrors}
              numeric
              RightComponent={
                <InputRightAddonStyled>min</InputRightAddonStyled>
              }
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Label>15 or more tops quote</Label>
            <ControllerInput
              control={control}
              name="fifteenOrMoreTopsQuote"
              placeholder='Time amount'
              isInvalid={'fifteenOrMoreTopsQuote' in errors}
              errorMsg={errors.fifteenOrMoreTopsQuote?.message}
              onFocus={clearErrors}
              numeric
              RightComponent={
                <InputRightAddonStyled>min</InputRightAddonStyled>
              }
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Label>14 tops or less deviation</Label>
            <ControllerInput
              control={control}
              name="fourTopsOrLessDeviation"
              placeholder='Time amount'
              isInvalid={'fourTopsOrLessDeviation' in errors}
              errorMsg={errors.fourTopsOrLessDeviation?.message}
              onFocus={clearErrors}
              numeric
              RightComponent={
                <InputRightAddonStyled>min</InputRightAddonStyled>
              }
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Label>15 or more tops deviation</Label>
            <ControllerInput
              control={control}
              name="fifteenOrMoreTopsDeviation"
              placeholder='Time amount'
              isInvalid={'fifteenOrMoreTopsDeviation' in errors}
              errorMsg={errors.fifteenOrMoreTopsDeviation?.message}
              onFocus={clearErrors}
              numeric
              RightComponent={
                <InputRightAddonStyled>min</InputRightAddonStyled>
              }
            />
          </ButtonWrapper>
        </ButtonList>
      </ButtonContainer>
    </WaitlistContainer>
  )
}

export default WaitlistParameters
