import React from 'react'
import { ButtonText, ModalPressable } from './styled'

export interface ModalButtonProps {
  onPress: () => void;
  text: string | React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const ModalButton = ({
  onPress,
  text,
  isDisabled,
  isLoading
}: ModalButtonProps) => {
  return (
    <ModalPressable isDisabled={isDisabled} isLoading={isLoading} onPress={onPress}>
      <ButtonText>{text}</ButtonText>
    </ModalPressable>
  )
}

ModalButton.defaultProps = {
  isDisabled: false,
  isLoading: false
}

export { ModalButton }
