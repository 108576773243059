import colors from '@instaseat/lib/themes/colors'
import { Pressable, Text } from 'native-base'
import styled from 'styled-components/native'

export const PillPressable = styled(Pressable)`
  margin-inline: auto;
  margin-bottom: 24px;
`

export const PreferencePill = styled(Text)<{selected: boolean}>`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  opacity: ${(props) => props.selected ? 1 : 0.35};
  background-color: ${colors.white};
  line-height: 18px;
  text-align: center;
  color: ${colors.primaryBlack};
  width: 125px;
  padding-block: 3px;
  display: block;
  box-shadow: 0px 4px 8px ${colors.primaryBlack}19;
  border-color: #E4E4E4;
  border-width: 1px;
  border-radius: 100vmax;
  cursor: pointer;
`
