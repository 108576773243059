import React from 'react'
import { CustomModal } from '../shared/modal'
import SeatModalContent from './content/Content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import { SeatIcon } from './styled'

const header = {
  title: 'Seat',
  IconComponent: <SeatIcon />
}

const SeatModal = () => {
  const showNotifyModal = useAppSelector((store) => store.modals.showModal) === 'confirmation'
  const dispatch = useAppDispatch()

  return (
    <CustomModal
      showModal={showNotifyModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={<SeatModalContent isVisible={showNotifyModal} />}
    />
  )
}

export { SeatModal }
