import colors from '@instaseat/lib/themes/colors'
import { HStack, Modal } from 'native-base'
import styled from 'styled-components/native'

export const ModalHeader = styled(Modal.Header)`
  background-color: ${colors.bgGrey};
  padding-inline: 26px;
`

export const ModalContent = styled(Modal.Content)`
  max-width: none;
`

export const ModalCloseButton = styled(Modal.CloseButton)`
  padding-right: 12px;
`

export const ModalHeaderTitleWrapper = styled(HStack)`
  align-items: center;
`

export const ModalBody = styled(Modal.Body)`
  padding: 0;
`
