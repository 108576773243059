import React, { useEffect } from 'react'

import { ScreenContainer, ContentContainer, LeftSideContainer } from './styled'

import UnitSideBarInfo from 'components/unitInfoSidebar'
import HistoryTable from 'components/historyTable'
import HistoryDatePicker from 'components/historyDatePicker'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'
import { useDispatch } from 'react-redux'

const HistoryScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSelectedHistoryTurn(''))
  }, [dispatch])

  return (
    <ScreenContainer>
      <ContentContainer>
        <UnitSideBarInfo />
        <LeftSideContainer>
          <HistoryDatePicker />
          <HistoryTable />
        </LeftSideContainer>
      </ContentContainer>
    </ScreenContainer>
  )
}

export default HistoryScreen
