import React, { useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import {
  SideBarInfoContainer,
  Container,
  DataContainer,
  DataDivider,
  InfoText,
  SectionDivider,
  TitleText,
  WidgetsContainer
} from './styled'
import { Center } from 'native-base'
import { useAppSelector } from 'hooks/redux'
import WidgetCard from './widget'
import { roundCustomerWaitTime } from '@instaseat/lib/utils/helpers/helpers'

const UnitSideBarInfo = () => {
  const unitData = useAppSelector(state => state.host.profileData.unit.turnPreferences)
  const waitlistData = useAppSelector(state => state.waitlist.waitlistData.waitlist)
  const waitlistInfo = useAppSelector(state => state.waitlist.waitlistData.waitlistInfo)
  const dailyTotals = useAppSelector(state => state.waitlist.waitlistData.dailyTotals)
  const roundingValue = useAppSelector((store) => store.host.profileData.unit.roundingValue)
  const [groupsAhead, setGroupsAhead] = useState(0)

  const partyOf4FirstValue =
    roundCustomerWaitTime(waitlistInfo.newWaitTime + unitData.fourteenTopsOrLessQuote, roundingValue)
  const partyOf4SecondValue = roundCustomerWaitTime(waitlistInfo.newWaitTime +
    unitData.fourteenTopsOrLessQuote, roundingValue) + unitData.fourTopsOrLessDeviationTime

  const partyOf4PlusFirstValue = roundCustomerWaitTime(waitlistInfo.newWaitTime +
    (Math.ceil(14 / 4) * unitData.fourteenTopsOrLessQuote), roundingValue)
  const partyOf4PlusSecondValue = roundCustomerWaitTime(waitlistInfo.newWaitTime +
    (Math.ceil(14 / 4) * unitData.fourteenTopsOrLessQuote), roundingValue) +
    unitData.fourToFifteenTopsDeviationTime

  const partyOf15FirstValue = roundCustomerWaitTime(
    waitlistInfo.newWaitTime + unitData.fifteenTopsOrMoreQuote, roundingValue)
  const partyOf15SecondValue = roundCustomerWaitTime(waitlistInfo.newWaitTime +
    unitData.fifteenTopsOrMoreQuote, roundingValue) + unitData.fifteenTopsOrMoreDeviationTime

  const SideBarData = [
    {
      title: 'Party of 4',
      plusIcon: false,
      data: (partyOf4FirstValue ?? '--') +
        ' - ' + (partyOf4SecondValue ?? '--')
    },
    {
      title: 'Party of 4 ',
      plusIcon: true,
      data: (partyOf4PlusFirstValue ?? '--') +
        ' - ' + (partyOf4PlusSecondValue ?? '--')
    },
    {
      title: 'Party of 15',
      plusIcon: true,
      data: (partyOf15FirstValue ?? '--') +
        ' - ' + (partyOf15SecondValue ?? '--')
    }
  ]

  useEffect(() => {
    const filteredGroups = waitlistData.length && waitlistData.reduce((count, current) => {
      if (current._id) {
        count++
      }
      return count
    }
    , 0)
    setGroupsAhead(filteredGroups)
  }
  , [dailyTotals, waitlistData])

  return (
    <Container>
      <SideBarInfoContainer>
        <Center>
          <TitleText>
            Waiting time.
            {' '}
            <CustomIcons iconName={IconName.ClockIcon} iconProps={{ fill: colors.textColor }} />
          </TitleText>
        </Center>
        {SideBarData.map((data, index) => {
          return (
            <DataContainer key={data.title}>
              <InfoText fontSize="12px">
                {data.title + ' '}
                {data.plusIcon && <CustomIcons iconName={IconName.PlusIcon} iconProps={{ fill: colors.textColor }} />}
              </InfoText>
              <DataDivider />
              <InfoText fontSize="18px">
                {data.data}
              </InfoText>
              <InfoText fontSize="12px">
                min
              </InfoText>
              {index < SideBarData.length - 1 && <SectionDivider />}
            </DataContainer>
          )
        })}
      </SideBarInfoContainer>
      <WidgetsContainer>
        <WidgetCard
          icon={IconName.CustomersIcon}
          color={colors.textColor}
          data={groupsAhead}
          cardText="groups ahead"
        />
        <WidgetCard
          icon={IconName.ClockIcon}
          color={colors.tertiary}
          data={dailyTotals?.assigned}
          cardText="waiting"
        />
        <WidgetCard
          icon={IconName.CheckIcon}
          color={colors.success}
          data={dailyTotals?.seated}
          cardText="seated"
        />
        <WidgetCard
          icon={IconName.CancelledIcon}
          color={colors.errorColor}
          data={dailyTotals?.canceled}
          cardText="cancelled"
        />
      </WidgetsContainer>
    </Container>
  )
}

export default UnitSideBarInfo
