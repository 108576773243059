import React, { useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Button, FormControl, Input, WarningIcon } from 'native-base'
import { Controller, useForm, useFormState } from 'react-hook-form'
import colors from '@instaseat/lib/themes/colors'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { validationSignInSchema } from '@instaseat/lib/utils/validations/sign-in'
import { IHostSubmitData } from '@instaseat/lib/interfaces/host'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Error, InputsContainer, Label, PasswordErrorContainer, ForgotPasswordText } from './styled'

export type SignInFormProps = {
  isFetching: boolean;
  error?: string;
  submit?: (payload: IHostSubmitData) => void;
  clearError?: () => void;
  forgotPasswordClick: () => void;
}

const SignInForm = ({ submit, forgotPasswordClick, isFetching, clearError, error }: SignInFormProps) => {
  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors },
    clearErrors
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(validationSignInSchema)
  })

  const { isDirty } = useFormState({
    control
  })

  const isDisabled = !isDirty

  const [show, setShow] = useState(false)

  const handleInputFocus = () => {
    clearError && clearError()
    clearErrors()
  }

  const onSubmit = (data: IHostSubmitData) => {
    submit && submit(data)
  }

  return (
    <Container>
      { isFetching
        ? (<p>Loading...</p>)
        : (
          <>
            <InputsContainer>
              <FormControl isInvalid={'email' in errors}>
                <Label>e-mail</Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      backgroundColor={colors.secondary}
                      borderWidth={0}
                      elevation={4}
                      borderRadius="xl"
                      placeholder="john.doe@instaseat.com"
                      fontStyle="italic"
                      fontSize={13}
                      size="xl"
                      paddingLeft={5}
                      height="40px"
                      paddingTop={1}
                      shadow={4}
                      testID="emailInput"
                      onBlur={onBlur}
                      onFocus={() => handleInputFocus()}
                      onChangeText={value => onChange(value)}
                      value={value}
                      autoCapitalize="none"
                      keyboardType="email-address"
                      w="100%"
                    />
                  )}
                  rules={{ required: true }}
                />
                <FormControl.ErrorMessage
                  testID="host-email-error-message"
                  leftIcon={<WarningIcon size={5} />}
                  _text={{ style: { color: colors.errorColor, fontStyle: 'italic', fontSize: 12, fontFamily: 'Lato' } }}
                >
                  {errors.email?.message}
                </FormControl.ErrorMessage>
              </FormControl>
              <FormControl isInvalid={'password' in errors}>
                <Label>Password</Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      backgroundColor={colors.secondary}
                      borderRadius="xl"
                      borderWidth={0}
                      elevation={4}
                      fontSize={13}
                      fontStyle="italic"
                      height="40px"
                      paddingLeft={5}
                      paddingRight={5}
                      paddingTop={1}
                      placeholder="Password"
                      shadow={4}
                      size="xl"
                      testID="passwordInput"
                      type={show ? 'text' : 'password'}
                      w="100%"
                      onBlur={onBlur}
                      onFocus={() => handleInputFocus()}
                      onChangeText={value => onChange(value)}
                      value={value}
                      InputRightElement={(
                        <TouchableOpacity
                          style={styles.touchableOpacity}
                          onPress={() => setShow(!show)}
                        >
                          <CustomIcons
                            iconName={show ? IconName.EyeOpenedIcon : IconName.EyeClosedIcon}
                          />
                        </TouchableOpacity>
                )}
                    />
                  )}
                  rules={{ required: true }}
                />
                <PasswordErrorContainer>
                  <TouchableOpacity onPress={() => forgotPasswordClick()} testID="forgotPassword">
                    <ForgotPasswordText>Forgot password?</ForgotPasswordText>
                  </TouchableOpacity>
                  <FormControl.ErrorMessage
                    testID="host-password-error-message"
                    leftIcon={<WarningIcon size={5} />}
                    _text={{
                      style: {
                        color: colors.errorColor, fontStyle: 'italic', fontSize: 12, fontFamily: 'Lato'
                      }
                    }}
                  >
                    {errors.password?.message}
                  </FormControl.ErrorMessage>
                </PasswordErrorContainer>
                <Error
                  testID="host-sign-in-errors-messages"
                >
                  {error}
                </Error>
              </FormControl>
            </InputsContainer>

            <View style={styles.button}>
              <Button
                size="lg"
                borderRadius="xl"
                onPress={handleSubmitForm(onSubmit)}
                isDisabled={isDisabled}
                loading={isFetching}
              >
                Continue
              </Button>
            </View>
          </>
          )}
    </Container>
  )
}

const styles = StyleSheet.create({
  button: {
    marginTop: 65,
    height: 40,
    borderRadius: 12
  },
  touchableOpacity: {
    marginRight: 10
  }
})

SignInForm.defaultProps = {
  submit: {},
  error: '',
  clearError: {}
}

export default SignInForm
