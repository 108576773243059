import styled from 'styled-components/native'
import colors from '../../../themes/colors'

export const H1 = styled.Text`
  color: ${colors.primaryBlack};
  letter-spacing: -0.120rem;
  line-height: 60px;
  font-family: 'Lato';
  font-style: normal;
  font-size: 58px;
`
