import styled from 'styled-components/native'
import { Alert, HStack, IconButton, Text, VStack } from 'native-base'

export const StyledAlert = styled(Alert).attrs({
  variant: 'outline'
})`
  background-color: #F9F9F9;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
`

export const ToastWrapper = styled(VStack).attrs({
  space: 1
})`
  flex-shrink: 1;
  width: 100%;
`

export const ToastHeaderWrapper = styled(HStack)`
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
`

export const ToastTitleWrapper = styled(HStack).attrs({ space: 2 })`
  flex-shrink: 1;
  align-items: center;
`

export const ToastTitle = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  flex-shrink: 1;
`

export const CloseButton = styled(IconButton).attrs({
  variant: 'unstyled'
})``

export const ToastDescription = styled(Text)`
  padding-inline: 24px;
`
