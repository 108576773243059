import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components/native'
import { instaSeatTheme, nativeBaseTheme } from '@instaseat/lib/themes'
import { store } from 'store'
import Navigator from 'routes'
import { NativeBaseProvider } from 'native-base'
import { NetworkProvider } from 'react-native-offline'

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={instaSeatTheme}>
      <NativeBaseProvider theme={nativeBaseTheme}>
        <NetworkProvider
          pingTimeout={10000}
          pingServerUrl={process.env.REACT_APP_API_URL}
          pingInterval={40000}
          httpMethod="HEAD"
        >
          <Navigator />
        </NetworkProvider>
      </NativeBaseProvider>
    </ThemeProvider>
  </Provider>
)

export default App
