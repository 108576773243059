import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { sioHostWaitlist } from '@instaseat/lib/interfaces/sioSubscriptions'
import { Dispatch } from 'redux'
import {
  getWaitlistDataCompleted,
  getWaitlistDataPending,
  getWaitlistDataRejected,
  selectedHistoryTurn,
  selectTurn,
  historyFilter,
  sortWaitlistOrderCompleted,
  sortWaitlistOrderPending,
  sortWaitlistOrderRejected
} from './actions'
import axios from 'axios'
import { IHistoryFilter } from './types'

export const setWaitlistData =
  (payload: sioHostWaitlist) => async (dispatch: Dispatch) => {
    dispatch(getWaitlistDataPending())
    try {
      dispatch(getWaitlistDataCompleted(payload))
    } catch (error) {
      dispatch(getWaitlistDataRejected(error))
    }
  }

export const setSelectedTurn =
  (payload: PopulatedWaitlistItem) => async (dispatch: Dispatch) => {
    dispatch(selectTurn(payload))
  }

export const setSelectedHistoryTurn =
  (payload: string) => async (dispatch: Dispatch) => {
    dispatch(selectedHistoryTurn(payload))
  }

export const setHistoryFilter =
  (payload: IHistoryFilter) => async (dispatch: Dispatch) => {
    dispatch(historyFilter(payload))
  }

export const sortWaitlistOrder =
  (id: string, newPosition: number | null) => async (dispatch: Dispatch) => {
    dispatch(sortWaitlistOrderPending())
    try {
      if (newPosition === null) return
      const response = await axios({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/turns/update-position/${id}`,
        data: { newPosition }
      })
      dispatch(sortWaitlistOrderCompleted(response?.data))
      return true
    } catch (error) {
      dispatch(sortWaitlistOrderRejected(error))
      return false
    }
  }
