import React from 'react'
import { ButtonContainer, ButtonText, VipActionRowContainer } from './styled'
import { Pressable, View } from 'native-base'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import { useDispatch } from 'react-redux'
import { showModal } from 'store/modules/Modals'
import { setSelectedTurn } from 'store/modules/Waitlist'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'

interface Props {
  hideActionRow: () => void,
  turnData: PopulatedWaitlistItem
}

const VipActionRow = ({ hideActionRow, turnData }: Props) => {
  const dispatch = useDispatch()

  const handleRemove = () => {
    dispatch(setSelectedTurn(turnData))
    dispatch(showModal('removeVIP'))
  }

  return (
    <VipActionRowContainer>
      <ButtonContainer onPress={handleRemove}>
        <View>
          <CustomIcons
            iconName={IconName.RemoveIcon}
            iconProps={{
              width: '17px',
              height: '17px',
              fill: colors.errorColor
            }}
          />
        </View>
        <ButtonText>
          Delete
        </ButtonText>
      </ButtonContainer>
      <Pressable
        onPress={() => hideActionRow()}
      >
        <View padding="10px">
          <CustomIcons
            iconName={IconName.CancelledIcon}
            iconProps={{
              fill: colors.webModalGreyText,
              height: '15px',
              width: '14px'
            }}
          />
        </View>
      </Pressable>
    </VipActionRowContainer>
  )
}

export default VipActionRow
