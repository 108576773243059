import { ActionType } from 'typesafe-actions'
import * as actions from './actions'

export enum ModalActionConsts {
  SET_MODAL_TEXT='SET_MODAL_TEXT'
}

export type showModalPayload = 'notify' |
  'cancel' |
  'confirmation' |
  'turnDetail' |
  'forgotPassword' |
  'success' |
  'error' |
  'addTurn' |
  'addTurnSuccess' |
  'addTurnError' |
  'schedule' |
  'confirmDrag' |
  'return' |
  'removeVIP' |
  null

export type ModalsActionTypes = ActionType<typeof actions>

export interface ModalText {
  headerText: string
  contentText: string
  buttonText: string
}
export interface ModalsState {
  readonly showModal: showModalPayload
  readonly modalText: ModalText
}
