/* eslint-disable @typescript-eslint/prefer-as-const */
import React, { useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import { Skeleton, Center } from 'native-base'
import {
  StyledWaitlistTable,
  WaitlistTHead,
  WaitlistTr,
  WaitlistTbody,
  WaitlistTd,
  NoTurnsText,
  TableWrapper
} from './styled'
import TableRow from './TableRow'
import { useAppSelector } from 'hooks/redux'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import VipRow from './VipRow/VipRow'
import { RouteProp, useRoute } from '@react-navigation/native'

const tableTitles = [
  {
    title: 'customer',
    align: 'left' as 'left',
    paddingLeft: '24px'
  },
  {
    title: 'party size'
  },
  {
    title: 'Wait time'
  },
  {
    title: 'status'
  }
]

const WaitlistTable = () => {
  const customersWaiting = useAppSelector((store) => store.waitlist.waitlistData.waitlist)
  const isLoading = useAppSelector((store) => store.waitlist.isLoading)
  const [shouldUpdateTime, setShouldUpdateTime] = useState(false)
  const showVip = useRoute<RouteProp<{ params: { 'show-vip': string } }>>().params?.['show-vip'] === 'true'

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setShouldUpdateTime((prevValue) => !prevValue)
    }, 1000)

    return () => clearInterval(updateInterval)
  })

  return (
    <Skeleton height={250} w="80%" isLoaded={!isLoading} borderRadius={10}>
      <TableWrapper>
        <StyledWaitlistTable>
          <WaitlistTHead>
            <WaitlistTr>
              {tableTitles.map((title) => (
                <th
                  key={title.title.toString()}
                  style={{
                    textAlign: title.align ? title.align : 'center',
                    paddingLeft: title.paddingLeft ? title.paddingLeft : '0px'
                  }}
                >
                  {title.title}
                </th>
              ))}
            </WaitlistTr>
          </WaitlistTHead>
          <WaitlistTbody>
            {
            customersWaiting?.length > 0
              ? customersWaiting?.map((waitlistItem: PopulatedWaitlistItem) => {
                if (waitlistItem._id) {
                  return (
                    <TableRow
                      turn={waitlistItem}
                      shouldUpdateTime={shouldUpdateTime}
                    />
                  )
                }
                return showVip && (
                <VipRow turn={waitlistItem} />
                )
              })
              : (
                <WaitlistTr shadow={false}>
                  <WaitlistTd colSpan={tableTitles.length}>
                    <Center my="15%">
                      <CustomIcons
                        iconName={IconName.NoTurnsIcon}
                        iconProps={{
                          width: '60px',
                          height: '60px',
                          fill: colors.textColor
                        }}
                      />
                      <NoTurnsText>
                        There are no turns on the waitlist yet.
                      </NoTurnsText>
                    </Center>
                  </WaitlistTd>
                </WaitlistTr>
                )
            }
          </WaitlistTbody>
        </StyledWaitlistTable>
      </TableWrapper>
    </Skeleton>
  )
}

export default WaitlistTable
