/* eslint-disable @typescript-eslint/prefer-as-const */
import React, { useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import { Skeleton, Center, View } from 'native-base'
import {
  StyledWaitlistTable,
  WaitlistTHead,
  WaitlistTr,
  WaitlistTbody,
  WaitlistTd,
  NoTurnsText,
  TableWrapper,
  AddTurnButton,
  AddTurnButtonIcon
} from './styled'
import TableRow from './TableRow'
import { useAppSelector } from 'hooks/redux'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { showModal } from 'store/modules/Modals'
import { useDispatch } from 'store'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { setTurnToDrag } from 'store/modules/Turns'
import VipRow from './VipRow/VipRow'
import { RouteProp, useRoute } from '@react-navigation/native'

const tableTitles = [
  {
    title: ' ',
    width: '40px'
  },
  {
    title: 'customer',
    width: '90px',
    align: 'left' as 'left',
    paddingLeft: '24px'
  },
  {
    title: 'phone number',
    width: '70px',
    align: 'left' as 'left'
  },
  {
    title: 'party size',
    width: '40px'
  },
  {
    title: 'Wait time',
    width: '70px'
  },
  {
    title: 'status',
    width: '40px'
  },
  {
    title: '  ',
    width: '20px'
  }
]

const WaitlistTable = () => {
  const customersWaiting = useAppSelector((store) => store.waitlist.waitlistData.waitlist)
  const isLoading = useAppSelector((store) => store.waitlist.isLoading)
  const [shouldUpdateTime, setShouldUpdateTime] = useState(false)
  const [selectedDragRow, setSelectedDragRow] = useState('')
  const dispatch = useDispatch()
  const showVip = useRoute<RouteProp<{ params: { 'show-vip': string } }>>().params?.['show-vip'] === 'true'

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setShouldUpdateTime((prevValue) => !prevValue)
    }, 1000)

    return () => clearInterval(updateInterval)
  }, [])

  const handleDragEnd = (e: DropResult) => {
    if (customersWaiting.length <= 1) return
    setSelectedDragRow('')
    if (e.destination?.index === e.source?.index || typeof e.destination?.index !== 'number') return
    const tempData = Array.from(customersWaiting)
    const [sourceData] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination?.index ?? 0, 0, sourceData)
    dispatch(setTurnToDrag({ id: e.draggableId.toString(), newPosition: e.destination?.index ?? null }))
    dispatch(showModal('confirmDrag'))
  }

  return (
    <Skeleton height={250} w="80%" isLoaded={!isLoading} borderRadius={10}>
      <TableWrapper>
        <DragDropContext
          onDragStart={
          (e) => {
            if (customersWaiting.length <= 1) return
            setSelectedDragRow(e.draggableId)
          }
          }
          onDragEnd={handleDragEnd}
        >
          <StyledWaitlistTable>
            <WaitlistTHead>
              <WaitlistTr>
                {tableTitles.map((title) => (
                  <th
                    key={title.title.toString()}
                    style={{
                      width: title.width,
                      textAlign: title.align ? title.align : 'center',
                      paddingLeft: title.paddingLeft ? title.paddingLeft : '0px'
                    }}
                  >
                    {title.title}
                  </th>
                ))}
              </WaitlistTr>
            </WaitlistTHead>
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <WaitlistTbody
                  ref={provider.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provider.droppableProps}
                >
                  {
            customersWaiting?.length > 0
              ? customersWaiting?.map((waitlistItem: PopulatedWaitlistItem, index) => {
                if (waitlistItem._id) {
                  return (
                    <Draggable
                      key={waitlistItem._id.toString()}
                      draggableId={waitlistItem._id.toString()}
                      index={index}

                    >
                      {(provider) => (
                        <TableRow
                          selectedId={selectedDragRow}
                          provider={provider}
                          turn={waitlistItem}
                          shouldUpdateTime={shouldUpdateTime}
                        />
                      )}
                    </Draggable>
                  )
                }
                return showVip && (
                <Draggable
                  key={waitlistItem.waitlistId.toString()}
                  draggableId={waitlistItem.waitlistId.toString()}
                  index={index}
                  isDragDisabled
                >
                  {(provider) => (
                    <VipRow
                      provider={provider}
                      turn={waitlistItem}
                      isHidden={false}
                    />
                  )}
                </Draggable>
                )
              })
              : (
                <WaitlistTr shadow={false}>
                  <WaitlistTd colSpan={tableTitles.length}>
                    <Center my="15%">
                      <CustomIcons
                        iconName={IconName.NoTurnsIcon}
                        iconProps={{
                          width: '60px',
                          height: '60px',
                          fill: colors.textColor
                        }}
                      />
                      <NoTurnsText>
                        There are no turns on the waitlist yet.
                      </NoTurnsText>
                    </Center>
                  </WaitlistTd>
                </WaitlistTr>
                )
            }
                </WaitlistTbody>
              )}
            </Droppable>
          </StyledWaitlistTable>
        </DragDropContext>
      </TableWrapper>
      <View>
        <AddTurnButton onPress={() => dispatch(showModal('addTurn'))}>
          <AddTurnButtonIcon />
        </AddTurnButton>
      </View>
    </Skeleton>
  )
}

export default WaitlistTable
