import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
}

const RestaurantIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg viewBox="0 0 24 24" fill="transparent" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M20.178 15.3937L19.846 15.0533L18.6777 13.885L18.3558 13.5631L17.7027 12.9198C18.1254 11.8833 18.341 10.774 18.3373 9.65454C18.3343 7.79717 17.7348 5.98989 16.6271 4.49896C15.5195 3.00803 13.9622 1.91229 12.1848 1.37319C10.4074 0.834081 8.50381 0.880113 6.75454 1.5045C5.00527 2.12889 3.50279 3.29862 2.46848 4.84135C1.43417 6.38407 0.922713 8.21822 1.00946 10.0736C1.09622 11.9289 1.77659 13.7073 2.95034 15.1468C4.1241 16.5863 5.72917 17.6107 7.52907 18.0691C9.32897 18.5276 11.2285 18.3958 12.9479 17.6932L13.6009 18.3462L15.0818 19.818L15.4221 20.1583C15.965 20.6974 16.699 21 17.4641 21C18.2292 21 18.9632 20.6974 19.5061 20.1583L19.8466 19.818L20.187 19.4776C20.7269 18.9346 21.0292 18.1995 21.0275 17.4338C21.0259 16.6681 20.7203 15.9343 20.178 15.3937ZM9.67365 12.7354C9.0629 12.7363 8.46563 12.5559 7.95744 12.2172C7.44925 11.8784 7.053 11.3965 6.81886 10.8324C6.58472 10.2683 6.52322 9.64748 6.64215 9.04842C6.76108 8.44937 7.05509 7.89908 7.48695 7.46722C7.91881 7.03535 8.4691 6.74135 9.06815 6.62242C9.6672 6.50349 10.2881 6.56499 10.8522 6.79913C11.4162 7.03326 11.8982 7.42951 12.2369 7.9377C12.5757 8.44589 12.756 9.04317 12.7551 9.65391C12.7561 10.0589 12.6771 10.46 12.5226 10.8343C12.3681 11.2086 12.1412 11.5487 11.8548 11.8351C11.5685 12.1214 11.2284 12.3484 10.8541 12.5029C10.4797 12.6574 10.0786 12.7364 9.67365 12.7354V12.7354Z"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#A19A98'}
      strokeMiterlimit="10"
      fill={iconProps?.fill}
    />
  </Svg>
)

RestaurantIcon.defaultProps = {
  iconProps: {}
}

export default RestaurantIcon
