import axios from 'axios'
import { store } from 'store'
import { signOutThunk } from 'store/modules/auth'
import { getCurrentFirebaseToken } from './helpers/firebase'

const axiosInterceptors = () => {
  axios.interceptors.request.use(async (req) => {
    const token = await getCurrentFirebaseToken()
    if (token) {
      req.headers = {
        ...req.headers,
        authorization: 'Bearer ' + token
      }
    }
    return req
  },
  (error) => {
    Promise.reject(error)
  })

  axios.interceptors.response.use((res) => {
    if (res.data.statusCode === 401 || res.data.error === 'Unauthorized') {
      store.dispatch(signOutThunk())
    }
    return res
  }, (err) => {
    if (err && err.response && (err.response.data.statusCode === 401 || err.response.data.error === 'Unauthorized')) {
      return store.dispatch(signOutThunk())
    }
    if (err?.response?.data?.message) return Promise.reject(err.response.data.message)
    return Promise.reject(err.message)
  })
}

export default axiosInterceptors
