export const GET_PROFILE_DATA_PENDING = 'GET_PROFILE_DATA_PENDING'
export const GET_PROFILE_DATA_COMPLETED = 'GET_PROFILE_DATA_COMPLETED'
export const GET_PROFILE_DATA_REJECTED = 'GET_PROFILE_DATA_REJECTED'

export const UPDATE_TURN_PREFERENCES_DATA_PENDING = 'UPDATE_TURN_PREFERENCES_DATA_PENDING'
export const UPDATE_TURN_PREFERENCES_DATA_COMPLETED = 'UPDATE_TURN_PREFERENCES_DATA_COMPLETED'
export const UPDATE_TURN_PREFERENCES_DATA_REJECTED = 'UPDATE_TURN_PREFERENCES_DATA_REJECTED'

export const UPDATE_UNIT_DATA_PENDING = 'UPDATE_UNIT_DATA_PENDING'
export const UPDATE_UNIT_DATA_COMPLETED = 'UPDATE_UNIT_DATA_COMPLETED'
export const UPDATE_UNIT_DATA_REJECTED = 'UPDATE_UNIT_DATA_REJECTED'

export const UPDATE_HOST_DATA_PENDING = 'UPDATE_HOST_DATA_PENDING'
export const UPDATE_HOST_DATA_COMPLETED = 'UPDATE_HOST_DATA_COMPLETED'
export const UPDATE_HOST_DATA_REJECTED = 'UPDATE_HOST_DATA_REJECTED'

export const SAVE_SCHEDULE_DATA = 'SAVE_SCHEDULE_DATA'

export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'

export const IS_SCHEDULE_EDITED = 'IS_SCHEDULE_EDITED'
