import colors from '@instaseat/lib/themes/colors'
import { HStack, Text, VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding: 24px;
  align-items: center;
`

export const ListRow = styled(HStack)`
  justify-content: space-between;
  align-items: baseline;
  width: 90%;
  padding-bottom: 5px;
`

export const ListRowTitle = styled(Text)`
  text-transform: capitalize;
  font-family: 'Lato';
  color: ${colors.webModalGreyText};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  margin-right: 30px;
  flex: 1;
`

export const ListRowDetail = styled(ListRowTitle)<{capitalize?: boolean}>`
  font-style: italic;
  font-size: 12px;
  text-align: center;
  align-self: center;
  margin-right: 0;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`

export const TextArea = styled.TextInput.attrs({
  editable: false,
  multiline: true,
  numberOfLines: 4,
  placeholder: 'Notes',
  placeholderTextColor: colors.textColor + '80'
})`
  margin-top: 10px;
  background-color: ${colors.bgGrey};
  padding: 15px 20px;
  width: 90%;
  align-self: center;
  outline-style: none;
  font-family: 'Lato';
  font-size: 13px;
  line-height: 16px;
  border-radius: 1vmax;
  border: 1px solid #E4E4E4;
`
