import React, { useCallback } from 'react'
import {
  ButtonContainer, ButtonListBusiness,
  Label, BusinessTitle, BusinessContainer, ScheduleContainer,
  InputRightAddonStyled, ButtonWrapperLarge, AddBusinessSchedule, ScheduleTitle, ScheduleItems, DeleteIcon
} from './styled'
import { useFormContext } from 'react-hook-form'
import { ControllerInput } from 'components/addTurnModal/controllerInput'
import { SettingsFormValues } from '..'
import { Button, View, Spinner } from 'native-base'
import { RootState } from 'store'
import { showModal } from 'store/modules/Modals'
import { useDispatch, useSelector } from 'react-redux'
import { TouchableOpacity } from 'react-native'
import { deleteSchedule, isScheduleEdited } from 'store/modules/Hosts'

const WeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const BusinessData = () => {
  const { control, clearErrors, formState: { errors } } = useFormContext<SettingsFormValues>()
  const dispatch = useDispatch()
  const scheduleData = useSelector((store: RootState) => store?.host?.scheduleData)
  const isLoading = useSelector((store: RootState) => store.host.isLoading)

  const handleScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const renderScheduleData = useCallback(() => {
    const sorted = scheduleData.sort((a, b) =>
      WeekDays.indexOf(a.days) - WeekDays.indexOf(b.days))
    return sorted.map((day) => {
      if (day.days) {
        return (
          <ScheduleContainer>
            <View
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              w="100%"
            >
              <ScheduleTitle>{day?.days}</ScheduleTitle>
              <View
                borderRadius={99}
                w="20px"
                h="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#210F0B"
                opacity="0.5"
              >
                <TouchableOpacity onPress={() => {
                  dispatch(deleteSchedule(day.days))
                  dispatch(isScheduleEdited())
                }}
                >
                  <DeleteIcon />
                </TouchableOpacity>
              </View>
            </View>
            <ScheduleItems>
              {day?.businessOpeningHour ? day?.businessOpeningHour - (day?.businessOpeningHour > 12 ? 12 : 0) : 12}
              :00
              {' '}
              {day?.businessOpeningHour && day?.businessOpeningHour >= 12 ? 'pm' : 'am'}
              {' '}
              to
              {' '}
              {day?.businessClosingHour ? day?.businessClosingHour - (day?.businessClosingHour > 12 ? 12 : 0) : 12}
              :00
              {' '}
              {day?.businessClosingHour && day?.businessClosingHour >= 12 ? 'pm' : 'am'}
            </ScheduleItems>
            {
              day.splitShiftBusinessOpeningHour
                ? (
                  <ScheduleItems>
                    {day?.splitShiftBusinessOpeningHour
                      ? day?.splitShiftBusinessOpeningHour - (day?.splitShiftBusinessOpeningHour > 12 ? 12 : 0)
                      : 12}
                    :00
                    {' '}
                    {day?.splitShiftBusinessOpeningHour && day?.splitShiftBusinessOpeningHour >= 12 ? 'pm' : 'am'}
                    {' '}
                    to
                    {' '}
                    {day?.splitShiftBusinessClosingHour
                      ? day?.splitShiftBusinessClosingHour - (day?.splitShiftBusinessClosingHour > 12 ? 12 : 0)
                      : 12}
                    :00
                    {' '}
                    {day?.splitShiftBusinessClosingHour && day?.splitShiftBusinessClosingHour >= 12 ? 'pm' : 'am'}
                  </ScheduleItems>
                  )
                : ''
            }
          </ScheduleContainer>
        )
      }
      return ''
    })
  }, [dispatch, scheduleData])

  return (
    <BusinessContainer>
      <BusinessTitle>
        Business data
      </BusinessTitle>
      <ButtonContainer>
        <ButtonListBusiness>
          <ButtonWrapperLarge>
            <Label>Business Address</Label>
            <ControllerInput
              control={control}
              name="businessAddress"
              placeholder="3915 S Providence Rd,"
              isInvalid={'businessAddress' in errors}
              errorMsg={errors.businessAddress?.message}
              onFocus={clearErrors}
              RightComponent={
                <InputRightAddonStyled />
              }
            />
          </ButtonWrapperLarge>
          <View
            width="100%"
          >
            <BusinessTitle>
              Business schedule
            </BusinessTitle>
            {isLoading
              ? (
                <Spinner />
                )
              : (
                <AddBusinessSchedule>
                  {renderScheduleData()}
                </AddBusinessSchedule>
                )}
          </View>
          <View
            w="100%"
            display="flex"
            alignItems="flex-end"
            paddingBottom="30px"
          >
            <Button
              disabled={scheduleData.length >= 7}
              shadow={5}
              marginBottom="20px"
              height="40px"
              borderRadius="12px"
              opacity={scheduleData.length >= 7 ? '0.6' : 'none'}
              w="200px"
              onPress={() => {
                handleScroll()
                dispatch(showModal('schedule'))
              }}
            >
              + Business schedule
            </Button>
          </View>
        </ButtonListBusiness>
      </ButtonContainer>
    </BusinessContainer>
  )
}

export default BusinessData
