import React from 'react'
import Svg, { Path, SvgProps, Rect } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
}

const SearchIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...iconProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#CECECE"
      // eslint-disable-next-line max-len
      d="M3.5 12.0008C3.5 16.7089 7.29266 20.5016 12.0008 20.5016C14.1188 20.5016 16.0514 19.7341 17.537 18.461L23.2794 24.2034L24.204 23.2788L18.4615 17.5363C19.7343 16.0509 20.5016 14.1185 20.5016 12.0008C20.5016 7.29266 16.7089 3.5 12.0008 3.5C7.29266 3.5 3.5 7.29266 3.5 12.0008ZM4.80782 12.0008C4.80782 8.01196 8.01196 4.80782 12.0008 4.80782C15.9896 4.80782 19.1938 8.01196 19.1938 12.0008C19.1938 15.9896 15.9896 19.1938 12.0008 19.1938C8.01196 19.1938 4.80782 15.9896 4.80782 12.0008Z"
    />
    <Rect
      width="28"
      height="28"
    />
  </Svg>
)

SearchIcon.defaultProps = {
  iconProps: {}
}

export default SearchIcon
