import styled from 'styled-components'
import { View } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

export const ActionRowContainer = styled(View).attrs({
  w: '100%',
  h: '50px',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  bg: colors.secondary
})``
