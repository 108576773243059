import React from 'react'

import { ScreenContainer } from './styled'

import WaitlistTable from './waitlistTable'

const MobileWaitlistScreen = () => {
  return (
    <ScreenContainer>
      <WaitlistTable />
    </ScreenContainer>
  )
}

export default MobileWaitlistScreen
