import React from 'react'
import { CustomModal } from '../shared/modal'
import SuccessModalContent from './content/Content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import EditIcon from '@instaseat/lib/assets/icons/EditIcon'

const header = {
  title: 'Edit settings',
  IconComponent: <EditIcon />
}

const SuccessModal = () => {
  const showNotifyModal = useAppSelector((store) => store.modals.showModal) === 'success'
  const dispatch = useAppDispatch()
  return (
    <CustomModal
      showModal={showNotifyModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={<SuccessModalContent />}
    />
  )
}

export default SuccessModal
