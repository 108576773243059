import styled from 'styled-components'
import colors from '@instaseat/lib/themes/colors'
import { Pressable, Text } from 'native-base'

export const FiltersButton = styled(Pressable).attrs({
  w: '140px',
  h: '48px',
  bg: colors.bgGrey,
  mr: '10px',
  borderRadius: '8px',
  shadow: 4,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  flexDir: 'row'
})``

export const FiltersButtonText = styled(Text).attrs({
  fontFamily: 'Lato',
  fontSize: '16px',
  width: '85px'
})``
