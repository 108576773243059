/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { WaitlistTd } from '../styled'
import colors from '@instaseat/lib/themes/colors'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { Center, HStack, PresenceTransition, Pressable, Text } from 'native-base'
import { DraggableProvided } from 'react-beautiful-dnd'
import { EmptyVipText, IconWrapper, VipWaitlistTr } from './styled'
import { useDispatch } from 'store'
import { useAppSelector } from 'hooks/redux'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'
import VipActionRow from './VipActionRow'

interface IVipRowProps {
  turn: PopulatedWaitlistItem,
  provider: DraggableProvided,
  isHidden: boolean
}

const VipRow = ({ turn, provider, isHidden }: IVipRowProps) => {
  const [isSelected, setIsSelected] = useState(false)
  const selectedHistoryRow = useAppSelector((store) => store.waitlist.selectedHistoryTurn)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsSelected(selectedHistoryRow === turn.waitlistId?.toString())
  }, [turn._id, selectedHistoryRow])

  return (
    <VipWaitlistTr
      display={isHidden ? 'none' : 'table-row'}
      {...provider.draggableProps}
      ref={provider.innerRef}
      bordered={!!turn.movedWithDragAndDrop}
    >
      <WaitlistTd
        width="40px"
      >
        <Center pl={1}>
          <CustomIcons
            iconName={IconName.LogoIcon}
            iconProps={{
              width: '30px',
              height: '30px',
              fill: colors.primary
            }}
          />
        </Center>
      </WaitlistTd>
      <WaitlistTd width="90px" justifyContent='initial'>
        <HStack h='100%' alignItems='center' textAlign="start">
          <EmptyVipText>
            Empty VIP Turn
          </EmptyVipText>
        </HStack>
      </WaitlistTd>
      <WaitlistTd colSpan={2} width="130px" />
      <WaitlistTd style={{ textTransform: 'none', width: '70px' }}>
        <Text>
          {turn.waitTime + ' min'}
        </Text>
      </WaitlistTd>
      {!isSelected
        ? (
          <>
            <WaitlistTd width="40px" />
            <WaitlistTd width="20px">
              <IconWrapper>
                <Pressable onPress={() => {
                  dispatch(setSelectedHistoryTurn(turn.waitlistId?.toString() || ''))
                }}
                >
                  <CustomIcons
                    iconName={IconName.SeeMoreIcon}
                    iconProps={{
                      width: '24px',
                      height: '24px',
                      fill: colors.textColor
                    }}
                  />
                </Pressable>
              </IconWrapper>
            </WaitlistTd>
          </>
          )
        : (
          <WaitlistTd colSpan={2} width='60px' height="50px">
            <PresenceTransition
              visible={isSelected}
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 550
                }
              }}
            >
              <VipActionRow hideActionRow={() => dispatch(setSelectedHistoryTurn(''))} turnData={turn} />
            </PresenceTransition>
          </WaitlistTd>
          )}
    </VipWaitlistTr>
  )
}

export default VipRow
