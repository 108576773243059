import React from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { Center, View } from 'native-base'
import { DataText, Container, CardText } from './styled'
import { isOutdatedIOSVersion } from 'utils/helpers'

interface Props {
  icon: IconName,
  data: number | string,
  cardText: string,
  color: string
}

const WidgetCard = ({ icon, data, color, cardText }: Props) => {
  return (
    <Container mr={isOutdatedIOSVersion() ? '5px' : 0} mb={isOutdatedIOSVersion() ? '8px' : 0}>
      <Center flexDir="row" gap={2} height="50%" alignItems="flex-end">
        <View alignSelf='flex-end' mr={isOutdatedIOSVersion() ? '3px' : 0}>
          <CustomIcons iconName={icon} iconProps={{ fill: color, width: '16px', height: '16px' }} />
        </View>
        <DataText color={color}>
          {data || 0}
        </DataText>
      </Center>
      <CardText color={color}>
        {cardText}
      </CardText>
    </Container>
  )
}

export default WidgetCard
