import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ContentWrapper,
  FormButton,
  FormColumn,
  FormColumnWrap,
  FormRow,
  FormRowFlexEnd,
  PhoneLeftComponent,
  PreferenceContainer,
  InputLoading,
  QuoteRightComponent,
  MinText,
  EstimatedTimeText
} from './styled'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ControllerInput } from '../controllerInput'
import { YUPTurn } from '@instaseat/lib/interfaces/turn'
import { PillButton } from '../pillButton'
import { ControllerTextArea } from '../controllerTextArea'
import { useDispatch } from 'store'
import { addNewTurn, getCustomerNameByPhoneNumber, getEstimatedQuoteByParty } from 'store/modules/Turns'
import { useAppSelector } from 'hooks/redux'
import { ButtonText } from 'components/shared/modalButton'
import { hideModal } from 'store/modules/Modals'
import { regex, roundCustomerWaitTime } from '@instaseat/lib/utils/helpers/helpers'
import { debounce } from 'lodash'
import { isOutdatedIOSVersion } from 'utils/helpers'
import { View } from 'native-base'
import { showToast } from 'utils/toastHandler'
import { ToastStatus } from 'components/customToast'

interface AddTurnFormProps {
  isVisible: boolean
}

interface addTurnPayload extends Pick<YUPTurn, 'customerFirstName' | 'customerLastName' | 'customerPhone' | 'notes'> {
  party: string
  quote: string
  preferences: {[key: string]: boolean}
}

const newTurnFormValidation = yup.object({
  party: yup.string()
    .required('Party is required.')
    .matches(/^[1-9][0-9]?$|^100$/, 'The party size number exceeds the max number of people.'),
  quote: yup.string()
    .matches(/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9]?)?$/, 'Quote should be a number.')
    .min(0),
  notes: yup.string().max(145, 'Notes are too long.'),
  customerPhone: yup
    .string()
    .required('Phone Number is required.')
    .min(10, 'Phone number should have 10 or more digits')
    .max(12, 'Phone number should have 12 or less digits')
    .matches(regex.e164, 'Phone number badly formatted.'),
  customerFirstName: yup
    .string()
    .required('First Name is required.')
    .min(2, 'First Name is too short.')
    .max(30, 'First Name is too long.')
    .matches(
      /^[a-zñáéíóúü\-'’´&]+(([ ][a-zñáéíóúü\-'’´&]+)*)?$/i,
      "Just one space between words and this special characters (-'’´&) are allowed."
    )
    .matches(
      /^(?!.*[-'’´&][-'’´&]).+$/,
      'Special characters are not allowed together.'
    ),
  customerLastName: yup
    .string()
    .transform(input => input || null)
    .nullable()
    .min(2, 'Last Name is too short.')
    .max(30, 'Last Name is too long.')
    .matches(
      /^[a-zñáéíóúü\-'’´&]+(([ ][a-zñáéíóúü\-'’´&]+)*)?$/i,
      "Just one space between words and this special characters (-'’´&) are allowed."
    )
    .matches(
      /^(?!.*[-'’´&][-'’´&]).+$/,
      'Special characters are not allowed together.'
    )
})

const AddTurnForm = ({ isVisible }: AddTurnFormProps) => {
  const preferences = useAppSelector((store) => store.host.profileData.unit.turnOptions)
  const isLoading = useAppSelector((store) => store.turns.isFetching)
  const unit = useAppSelector((store) => store.host.profileData.unit._id)
  const host = useAppSelector((store) => store.host.profileData.host._id)
  const roundingValue = useAppSelector((store) => store.host.profileData.unit.roundingValue)
  const { newWaitTime } = useAppSelector(state => state.waitlist.waitlistData.waitlistInfo)
  const dispatch = useDispatch()
  const [quotePlaceholder, setQuotePlaceholder] = useState<string>()
  const [quoteLoading, setQuoteLoading] = useState<boolean>(false)
  const [disableName, setDisableName] = useState<boolean>(false)
  const [customerPhoneLoading, setCustomerPhoneLoading] = useState<boolean>(false)
  const prevIsVisible = useRef(false)

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    clearErrors,
    reset,
    watch,
    resetField,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => ({
      party: '',
      quote: '',
      notes: '',
      customerPhone: '',
      customerFirstName: '',
      customerLastName: '',
      preferences: Object.fromEntries(preferences.map((el) => [el, false]))
    }), [preferences]),
    resolver: yupResolver(newTurnFormValidation),
    context: {
      newWaitTime
    }
  })

  const watchedCustomerPhone = watch('customerPhone')
  const watchedParty = watch('party')
  const watchedQuote = watch('quote')
  const quoteIsCustom = watchedQuote && dirtyFields.quote && +watchedQuote !== +(quotePlaceholder ?? -1)

  const { isDirty, isValid } = useFormState({
    control
  })

  const formattedMinutes = useCallback((minutes: number | undefined) => {
    if (minutes === undefined) return '--'
    return minutes + ' min'
  }, [])

  const estimatedTime = useMemo(() => {
    const quote = +watchedQuote || Number(quotePlaceholder)
    if (!(newWaitTime >= 0) || !quote) return
    return 'The estimated wait time is about: ' +
    `${
      formattedMinutes(roundCustomerWaitTime(quote, roundingValue))
    }`
  }, [watchedQuote, newWaitTime, quotePlaceholder])

  // When party changes, the quote value will be updated to the server response if the field is empty or pristine.
  // A debounce is added to avoid calling the endpoint continuously
  const debouncedPartyOnChange = useMemo(
    () => debounce(async () => {
      if (!isVisible) return
      setQuoteLoading(true)
      setValue('quote', '', { shouldDirty: false, shouldTouch: false })
      setQuotePlaceholder(undefined)
      if (+watchedParty > 0) {
        const estimatedQuote = await dispatch<string>(getEstimatedQuoteByParty(watchedParty))
        if (+watchedParty <= 100 && estimatedQuote) {
          const estimatedQuoteAndWaitTime = +estimatedQuote + +newWaitTime
          setQuotePlaceholder(estimatedQuoteAndWaitTime.toString())
          if (!dirtyFields.quote) {
            setValue('quote', estimatedQuoteAndWaitTime.toString(), { shouldDirty: false, shouldTouch: false })
          }
        }
      }
      setQuoteLoading(false)
    }, 400)
    , [watchedParty, dirtyFields, setValue, setQuotePlaceholder, setQuoteLoading, isVisible])

  useEffect(() => {
    if (prevIsVisible.current === isVisible) {
      debouncedPartyOnChange()
    }
    prevIsVisible.current = isVisible
    return () => debouncedPartyOnChange.cancel()
  }, [watchedParty, isVisible])

  // When phone changes, the name values will be updated with the server response if the field is empty or pristine.
  // A debounce is added to avoid calling the endpoint continuously
  const debouncedPhoneOnChange = useMemo(
    () => debounce(async () => {
      const length = watchedCustomerPhone.length
      if (!isVisible) return
      setDisableName(false)
      resetField('customerFirstName')
      resetField('customerLastName')
      if (length < 10 || length > 12) return
      setCustomerPhoneLoading(true)
      const formattedPhone = (process.env.REACT_APP_COUNTRY_CODE || '+1') + watchedCustomerPhone
      const response =
       await dispatch<{firstName: string, lastName: string} | false>(getCustomerNameByPhoneNumber(formattedPhone))
      if (response) {
        setValue('customerFirstName', response.firstName, { shouldValidate: true })
        setValue('customerLastName', response.lastName, { shouldValidate: true })
        setDisableName(true)
      }

      setCustomerPhoneLoading(false)
    }, 400)
    , [watchedCustomerPhone, setValue, setCustomerPhoneLoading, setDisableName, isVisible])

  useEffect(() => {
    if (prevIsVisible.current === isVisible) {
      debouncedPhoneOnChange()
    }
    return () => debouncedPhoneOnChange.cancel()
  }, [watchedCustomerPhone, isVisible])

  const onSubmit = async (data: addTurnPayload) => {
    const turnOptions = Object.entries(data.preferences)
      .filter((turnOption) => turnOption[1] === true)
      .map((turnOption) => turnOption[0])

    const quote = data.quote ? (+data.quote - +newWaitTime) : undefined
    const isValidQuote = !data.quote || (data.quote && (+data.quote - +newWaitTime) >= 0)

    const payload = {
      ...data,
      turnOptions,
      party: +data.party,
      quote: isValidQuote ? quote : undefined,
      customOriginalWaitTime: !isValidQuote ? +data.quote : undefined,
      customerPhone: (process.env.REACT_APP_COUNTRY_CODE || '+1') + data.customerPhone,
      unit,
      host
    }
    const customerName = `${data.customerFirstName} ${data.customerLastName ?? ''}`.trim()
    const { success, errorMsg } = await dispatch<{success: boolean, errorMsg?: string}>(addNewTurn(payload))

    if (!success) {
      showToast({
        title: `Unable to enter ${customerName} at the waitlist`,
        description: `${errorMsg || 'Something went wrong'}.`,
        status: ToastStatus.error
      })
    } else {
      showToast({
        title: 'Add Customer',
        description: `${customerName} is at the waitlist.`,
        status: ToastStatus.success
      })
    }
    dispatch(hideModal())
  }

  const isDisabled = !isDirty || !isValid

  useEffect(() => {
    if (isVisible) {
      reset()
      setQuotePlaceholder(undefined)
    }
    !isVisible && resetField('party')
  }, [isVisible, reset])

  return (
    <ContentWrapper marginY={isOutdatedIOSVersion() ? '2%' : '0'}>
      <FormRow ml={isOutdatedIOSVersion() ? '15px' : 0}>
        <FormColumnWrap>
          <ControllerInput
            control={control}
            label='Party Number'
            name='party'
            isInvalid={'party' in errors}
            errorMsg={errors.party?.message}
            onFocus={clearErrors}
            numeric
            reduceMarginBottom={30}
          />
          {isOutdatedIOSVersion() && (
          <View size="20px" />
          )}
          <ControllerInput
            control={control}
            label={(quoteIsCustom ? 'Custom' : 'Proposed') + ' Quote'}
            placeholder={!quoteLoading ? quotePlaceholder ?? 'Quote' : ''}
            name='quote'
            isInvalid={'quote' in errors}
            errorMsg={errors.quote?.message}
            onFocus={clearErrors}
            RightComponent={<QuoteRightComponent><MinText /></QuoteRightComponent>}
            LeftComponent={quoteLoading ? <InputLoading /> : undefined}
            numeric
            reduceMarginBottom={30}
          />
          <EstimatedTimeText>{estimatedTime}</EstimatedTimeText>
        </FormColumnWrap>
        <FormColumn pr={isOutdatedIOSVersion() ? '20px' : 0}>
          <ControllerInput
            control={control}
            label='Phone Number'
            name='customerPhone'
            isInvalid={'customerPhone' in errors}
            errorMsg={errors.customerPhone?.message}
            onFocus={clearErrors}
            LeftComponent={<PhoneLeftComponent />}
            numeric
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <ControllerInput
            control={control}
            label='First Name'
            name='customerFirstName'
            isInvalid={'customerFirstName' in errors}
            errorMsg={errors.customerFirstName?.message}
            onFocus={clearErrors}
            LeftComponent={customerPhoneLoading ? <InputLoading /> : undefined}
            isDisabled={disableName}
          />
        </FormColumn>
        <FormColumn>
          <ControllerInput
            control={control}
            label='Last Name'
            name='customerLastName'
            isInvalid={'customerLastName' in errors}
            errorMsg={errors.customerLastName?.message}
            onFocus={clearErrors}
            LeftComponent={customerPhoneLoading ? <InputLoading /> : undefined}
            isDisabled={disableName}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <PreferenceContainer>
            {preferences.map((el) => (
              <PillButton
                control={control}
                name={`preferences.${el}`}
                isVisible={isVisible}
                key={el}
              />
            ))}
          </PreferenceContainer>
        </FormColumn>
        <FormColumn>
          <ControllerTextArea
            control={control}
            label='Notes'
            name='notes'
            isInvalid={'notes' in errors}
            errorMsg={errors.notes?.message}
            onFocus={clearErrors}
          />
        </FormColumn>
      </FormRow>
      <FormRowFlexEnd>
        <FormColumn>
          <FormButton
            onPress={handleSubmit(onSubmit)}
            isDisabled={isDisabled}
            isLoading={isLoading}
            mr={isOutdatedIOSVersion() ? '33%' : 0}
          >
            <ButtonText>Next</ButtonText>
          </FormButton>
        </FormColumn>
      </FormRowFlexEnd>
    </ContentWrapper>
  )
}

AddTurnForm.displayName = 'AddTurnForm'

export default AddTurnForm
