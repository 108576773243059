import React from 'react'
import { ContentWrapper, DescriptionText } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modules/Modals'
import { CheckCircleIcon } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

const SuccessModalContent = () => {
  const dispatch = useDispatch()
  const onPressHandle = () => dispatch(hideModal())

  return (
    <ContentWrapper space="40px">
      <CheckCircleIcon color={colors.success} size="xl" />
      <DescriptionText>
        The information has been successfully changed.
      </DescriptionText>
      <ModalButton onPress={onPressHandle} text="Done" />
    </ContentWrapper>
  )
}

export default SuccessModalContent
