import React from 'react'
import {
  ButtonContainer, ButtonWrapper,
  ButtonListPriority, Label,
  PriorityContainer, PriorityTitle, InputRightAddonStyled, ButtonWrapperPrice
} from './styled'
import { useFormContext } from 'react-hook-form'
import { ControllerInput } from 'components/addTurnModal/controllerInput'
import { SettingsFormValues } from '..'

const PrioritySettingsParameters = () => {
  const { control, clearErrors, formState: { errors } } = useFormContext<SettingsFormValues>()

  return (
    <PriorityContainer>
      <PriorityTitle>
        Priority Seating parameters
      </PriorityTitle>
      <ButtonContainer>
        <ButtonListPriority>
          <ButtonWrapper>
            <Label>Windows available</Label>
            <ControllerInput
              control={control}
              name="windowsAvailable"
              placeholder="2"
              isInvalid={'windowsAvailable' in errors}
              errorMsg={errors.windowsAvailable?.message}
              onFocus={clearErrors}
              numeric
              RightComponent={
                <InputRightAddonStyled />
              }
            />
          </ButtonWrapper>
          <ButtonWrapperPrice>
            <Label>Price</Label>
            <ControllerInput
              control={control}
              name="price"
              placeholder="50"
              isInvalid={'price' in errors}
              errorMsg={errors.price?.message}
              onFocus={clearErrors}
              RightComponent={(
                <InputRightAddonStyled>USD</InputRightAddonStyled>
              )}
            />
          </ButtonWrapperPrice>
        </ButtonListPriority>
      </ButtonContainer>
    </PriorityContainer>
  )
}

export default PrioritySettingsParameters
