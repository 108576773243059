import 'styled-components'
import { DefaultTheme } from 'styled-components/native'
import colors from './colors'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primary: string;
      secondary: string;
    }
  }
}

const instaseat: DefaultTheme = {
  colors: {
    ...colors
    // primaryDark: '#172B4D',
    // primaryLight: '#FFFFFF',
    // Tertiary — may be optional)
    // Success — for the accomplished goals and other successful operations
    // Warning — use it when you have to warn a user
    // Error — as the name suggests, elements displaying Error should use this color
    // Neutral — for all kinds of grayish elements
  }
}

export { instaseat }
