import React, { ReactElement } from 'react'
import { Modal } from 'native-base'
import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalHeaderTitleWrapper } from './styled'

export interface AddTurnModalLayoutProps {
  showModal: boolean,
  onClose: () => void,
  header: {
    IconComponent?: ReactElement,
    title: string
  },
  BodyComponent: ReactElement,
  footer?: React.ReactElement,
  initialRef?: React.RefObject<unknown>,
  closeOnOverlayClick?: boolean
}

const AddTurnModalLayout = ({
  showModal,
  onClose,
  header,
  BodyComponent,
  footer,
  initialRef,
  closeOnOverlayClick
}: AddTurnModalLayoutProps) => {
  return (
    <Modal
      isOpen={showModal}
      onClose={onClose}
      avoidKeyboard
      closeOnOverlayClick={closeOnOverlayClick}
      initialFocusRef={initialRef}
      focusable={false}
    >
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
          <ModalHeaderTitleWrapper space="10px">
            {header.IconComponent}
            {header.title}
          </ModalHeaderTitleWrapper>
        </ModalHeader>
        <ModalBody>
          {BodyComponent}
        </ModalBody>
        {footer && (
        <Modal.Footer>
          {footer}
        </Modal.Footer>
        )}
      </ModalContent>
    </Modal>
  )
}

AddTurnModalLayout.defaultProps = {
  footer: null,
  initialRef: undefined,
  closeOnOverlayClick: true
}

export { AddTurnModalLayout }
