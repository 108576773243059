import colors from '@instaseat/lib/themes/colors'
import { Text, View } from 'native-base'
import styled from 'styled-components/native'

export const WaitlistTitle = styled(Text)`
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 36px;
  color: ${colors.primary}
`

export const Label = styled(Text)`
  font-family: Lato;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
`

export const WaitlistContainer = styled(View)`
  min-height: 300px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  padding: 24px;
`

export const BusinessContainer = styled(View)`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  padding: 24px;
  margin-top: 25px;
`

export const ButtonContainer = styled(View)`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ButtonWrapper = styled(View)`
  height: 110px;
  padding-bottom: 24px;
  padding-right: 12px;
`

export const ButtonList = styled(View)`
  display: flex;
  flex-wrap: wrap;
  width: 76%;
  max-width: 450px;
  flex-direction: row;
  justify-content: flex-end;
`
export const InputRightAddonStyled = styled(Text)`
  padding-right: 8px;
  font-style: italic;
  opacity: 0.6;
`
