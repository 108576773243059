import React, { useEffect, useState } from 'react'
import { ContentWrapper, DescriptionText, WarnIcon } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { useDispatch } from 'react-redux'
import { View } from 'react-native'
import colors from '@instaseat/lib/themes/colors'
import { useAppSelector } from 'hooks/redux'
import { sortWaitlistOrder } from 'store/modules/Waitlist'
import { hideModal } from 'store/modules/Modals'
import { showToast } from 'utils/toastHandler'
import { ToastStatus } from 'components/customToast'

interface IConfirmDragModalContent {
  isVisible: boolean
}

const ConfirmDragModalContent = ({ isVisible }: IConfirmDragModalContent) => {
  const { turnToDrag } = useAppSelector((store) => store.turns)
  const { isLoading } = useAppSelector((store) => store.waitlist)
  const dispatch = useDispatch()
  const [isModalDisabled, setIsModalDisabled] = useState(false)

  const onPressHandle = async () => {
    setIsModalDisabled(true)
    const success = await dispatch(sortWaitlistOrder(turnToDrag.id, turnToDrag.newPosition))
    dispatch(hideModal())
    if (success) {
      showToast({
        title: 'Waitlist drag and drop',
        description: 'The turn has been moved.',
        status: ToastStatus.success
      })
    }
    if (!success) {
      showToast({
        title: 'Invalid movement',
        description: 'There was an error moving the turn.',
        status: ToastStatus.error
      })
    }
  }

  useEffect(() => {
    isVisible && setIsModalDisabled(false)
  }, [isVisible])

  return (
    <ContentWrapper space="40px">
      <View
        style={{
          backgroundColor: colors.white,
          borderRadius: 90,
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <WarnIcon />
      </View>
      <DescriptionText>
        Note that the waiting times estimated
        will not be accurate with respect to the actual waiting times.
      </DescriptionText>
      <ModalButton onPress={onPressHandle} text="Move anyway" isLoading={isLoading} isDisabled={isModalDisabled} />
    </ContentWrapper>
  )
}

export default ConfirmDragModalContent
