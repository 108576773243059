import colors from '@instaseat/lib/themes/colors'
import { Text, View, VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding: 24px 24px 32px;
`

export const ContentTitle = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const BoldContentTitle = styled(ContentTitle)`
  font-family: "Lato";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: "#4F4E4F";
`

export const DescriptionText = styled(ContentTitle)`
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 95%;
  align-self: center;
  padding-top: 30px;
  padding-bottom: 30px;
  color: "#4F4E4F";
`

export const TextAreaWrapper = styled(View)`
  align-self: center;
  width: 95%
`
