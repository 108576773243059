import React from 'react'
import type { StyleProp, TextStyle } from 'react-native'
import { H1 as H1Styled } from './styled'

const H1: React.FC<{
  style?: StyleProp<TextStyle>;
  testID?: string;
}> = ({ children, style, testID }) => (
  <H1Styled style={style} testID={testID}>
    {children}
  </H1Styled>
)

H1.defaultProps = {
  style: {},
  testID: ''
}

export default H1
