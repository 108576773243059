import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const GoToIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="7" height="13" viewBox="0 0 7 13" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M6.32735 6.45406C6.04927 6.18556 5.77704 5.92612 5.50562 5.66608C4.64194 4.83822 3.77814 4.01056 2.91419 3.18311C1.98226 2.29079 1.04999 1.39868 0.117384 0.506766C-0.0643706 0.332332 -0.0306454 0.0837727 0.186451 0.0148849C0.295302 -0.0197601 0.390017 0.00864029 0.472614 0.0882034C0.797081 0.400413 1.12236 0.711884 1.44844 1.02262L4.02512 3.49209L6.86353 6.21255C7.04529 6.38638 7.04529 6.52657 6.86474 6.7006C5.11626 8.38774 3.36797 10.075 1.61989 11.7624C1.27415 12.0962 0.527141 12.8483 0.497051 12.8884C0.463056 12.937 0.413521 12.9727 0.35655 12.9896C0.299578 13.0065 0.23854 13.0036 0.183421 12.9815C0.070127 12.9394 -0.00742102 12.8338 0.00873518 12.7168C0.0190921 12.6585 0.0464106 12.6044 0.0872941 12.5615C0.281705 12.3571 0.479548 12.1557 0.680825 11.9572C1.82104 10.8406 2.96226 9.72487 4.10449 8.60991C4.82404 7.90875 5.54924 7.21283 6.27141 6.51509C6.28838 6.49877 6.30312 6.48065 6.32735 6.45406Z"
      fill={iconProps?.fill || '#210f0b9e'}
      fillOpacity="0.6"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
  </Svg>
)

GoToIcon.defaultProps = {
  iconProps: {}
}

export default GoToIcon
