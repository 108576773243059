import colors from '@instaseat/lib/themes/colors'
import styled from 'styled-components/native'
import { View, Text, Divider, Image } from 'native-base'
import { TouchableOpacity } from 'react-native'

export const HeaderContainer = styled(View).attrs({
  shadow: 4
})`
display: flex;
flex-direction: row;
justify-content: space-evenly;
height: 70px;
width: 100%;
background-color: ${colors.white};
margin-bottom: 15px;
`

export const NavBarLogoImage = styled(Image).attrs({
  alt: 'InstaSeat Logo',
  size: 30,
  resizeMode: 'cover',
  borderRadius: 8,
  source: require('assets/logos/logo.png')
})``

export const UnitNameTouchable = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const UnitName = styled(Text)`
font-family: 'Lato';
font-style: normal;
font-weight: 400;
width:140px;
font-size: 17px;
line-height: 20px;
text-align: center;
color: rgba(33, 15, 11, 0.5);
`

export const StyledDivider = styled(Divider).attrs({
  w: 70,
  bgColor: colors.black,
  opacity: 0.3,
  alignSelf: 'center'
})``

export const HostContainer = styled(View).attrs({
  size: '35px',
  justifyContent: 'center',
  borderRadius: 'full',
  alignSelf: 'flex-end',
  borderColor: colors.primary,
  background: colors.white,
  borderWidth: 1
})``

export const HostInitials = styled(Text)`
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 12px;
text-align: center;
letter-spacing: 0.8px;
color: ${colors.primary};
`
