import colors from '@instaseat/lib/themes/colors'
import { Text, View } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(View)`
  display: flex;
  align-items: center;
  min-height: 350px;
`

export const ContentTitle = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const BoldContentTitle = styled(ContentTitle)`
  font-weight: 700;
`

export const DescriptionText = styled(ContentTitle)`
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 10px;
  width: 90%
`
export const Label = styled(Text)`
  font-family: Lato;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
`
export const ButtonWrapperLarge = styled(View)`
  height: 100px;
  width: 465px;
  padding-bottom: 24px;
  padding-right: 24px;
`
export const ButtonWrapper = styled(View)`
  display: flex;
  height: 100px;
  width: 235px;
  margin-bottom: 20px;
  padding-bottom: 24px;
  padding-right: 24px;
`

export const InputRightAddonStyled = styled(Text)`
  display: "flex";
  flex-direction: "row";
  padding-right: 8px;
  font-style: italic;
  opacity: 0.6;
`
export const AddBusinessSchedule = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 60px;
`

export const ScheduleContainer = styled(View)`
  padding-bottom: 15px;
  display: flex;
  align-items: flex-start;
`

export const ScheduleTitle = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 8px;
`

export const ScheduleItems = styled(Text)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding-bottom: 4px;
`

export const ButtonContainer = styled(View)`
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled(View)`
  display: flex;
  align-items: center;
  padding: 32px 84px;
`

export const ErrorLabel = styled(Text)`
  color: ${colors.errorColor};
  font-family: 'Lato';
  font-style: 'italic';
  font-size: 12px;
  textAlignVertical: text-top;
`

export const CustomTimeText = styled(Text)`
  font-family: 'Lato';
  font-style: italic;
  padding-left: 3px;
  font-size: 15px;
  text-transform: lowercase;
`
export const ButtonWrapperRadio = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  padding-right: 30px;
`

export const DayErrorContainer = styled(View)`
  flex-direction: row;
  gap: 4px;
`
