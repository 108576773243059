/* eslint-disable @typescript-eslint/prefer-as-const */
import React, { useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import { Skeleton, Center, ChevronLeftIcon, ChevronRightIcon, Text } from 'native-base'
import {
  StyledHistoryTable,
  WaitlistTHead,
  WaitlistTr,
  WaitlistTbody,
  NoTurnsText,
  TableWrapper,
  WaitlistTd,
  ArrowsContainer
} from './styled'
import TableRow from './TableRow'
import { useAppSelector } from 'hooks/redux'
import { socket } from 'utils/socketIO'
import _ from 'lodash'
import {
  sioHistoricalTurns,
  sioHistoricalTurnsItem,
  sioHistoricalTurnsPayload
} from '@instaseat/lib/interfaces/sioSubscriptions'
import { isWithinInterval } from 'date-fns'
import { isOutdatedIOSVersion } from 'utils/helpers'
import { TouchableOpacity } from 'react-native'

const tableTitles = [
  {
    title: 'date',
    width: '180px'
  },
  {
    title: 'customer',
    width: '180px',
    align: 'left' as 'left',
    paddingLeft: '40px'
  },
  {
    title: 'phone number',
    width: '180px',
    paddingLeft: '10px',
    align: 'left' as 'left'
  },
  {
    title: 'party size',
    width: '80px'
  },
  {
    title: 'status',
    width: '80px'
  },
  {
    title: ' ',
    width: '40px'
  }
]

const HistoryTable = () => {
  const [historicalTurns, setHistoricalTurns] = useState<sioHistoricalTurnsItem[]>([])
  const historyFilter = useAppSelector((store) => store.waitlist.historyFilter)
  const [paginateData, setPaginateData] = useState<sioHistoricalTurnsPayload>({
    skip: 0,
    limit: 8,
    totalPages: 1,
    currentPage: 1,
    startDate: historyFilter?.startDate,
    endDate: historyFilter?.endDate
  })
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)
  const isLoading = useAppSelector((store) => store.waitlist.isLoading)

  useEffect(() => {
    setIsPaginationLoading(true)
    if (!socket) return
    socket.emit('historical-turns', paginateData)
    socket.on(
      'historical-turns',
      ({ historicalTurns, totalPages, currentPage }: sioHistoricalTurns) => {
        setIsPaginationLoading(false)

        const duplicatedTurnsByPhone = historicalTurns.reduce((a, e) => {
          a[e.customer.phone] = ++a[e.customer.phone] || 0
          return a
        }, {} as { [key: string]: number })

        const uniqueTurns = historicalTurns.filter(
          turn => !duplicatedTurnsByPhone[turn.customer.phone]
        )

        const duplicatedTurns = _.sortBy(
          historicalTurns.filter(
            turn => duplicatedTurnsByPhone[turn.customer.phone]
          ),
          'updatedAt'
        ).reverse()

        const duplicatedTurnsRejoinAllowed = _.uniqBy(
          duplicatedTurns,
          obj => obj.customer.phone
        )

        const joinBlockedTurns = duplicatedTurns.map(turn => ({
          ...turn,
          rejoinBlocked: !duplicatedTurnsRejoinAllowed.includes(turn)
        }))

        const modifiedTurns = _.concat(uniqueTurns, joinBlockedTurns).sort(
          (turnA, turnB) => (turnA.updatedAt > turnB.updatedAt ? -1 : 1)
        )

        historyFilter?.startDate
          ? setHistoricalTurns(
            modifiedTurns.filter(turn =>
              isWithinInterval(
                new Date(turn.updatedAt).setHours(0, 0, 0, 0),
                {
                  start: new Date(historyFilter.startDate || '').setHours(0, 0, 0, 0),
                  end: new Date(historyFilter.endDate || '').setHours(0, 0, 0, 0)
                }
              )
            )
          )
          : setHistoricalTurns(modifiedTurns)
        setPaginateData({
          ...paginateData,
          totalPages,
          currentPage,
          ...historyFilter
        })
      }
    )
    return () => {
      socket.off('historical-turns')
    }
  }, [
    socket,
    paginateData.limit,
    paginateData.skip,
    paginateData.startDate,
    paginateData.endDate,
    historicalTurns.length
  ])

  useEffect(() => {
    setPaginateData({
      ...paginateData,
      skip: 0,
      currentPage: 1,
      ...historyFilter
    })
  }, [historyFilter])

  const isStartDisabled = paginateData.skip === 0
  const isFinishDisabled = historicalTurns.length < 8

  return (
    <Skeleton
      height={440}
      isLoaded={!isLoading && !isPaginationLoading}
      borderRadius={10}
      mt={isOutdatedIOSVersion() ? '30px' : 0}
    >
      <TableWrapper>
        <StyledHistoryTable>
          <WaitlistTHead>
            <WaitlistTr>
              {tableTitles.map(title => (
                <th
                  key={title.title.toString()}
                  style={{
                    width: title.width,
                    textAlign: title.align || 'center',
                    paddingLeft: title.paddingLeft || '0px'
                  }}
                >
                  {title.title}
                </th>
              ))}
            </WaitlistTr>
          </WaitlistTHead>
          <WaitlistTbody>
            {historicalTurns?.length > 0
              ? (
                  historicalTurns?.map(
                    turn =>
                      turn._id && (
                      <TableRow key={turn?.checkInTime.toString()} turn={turn} />
                      )
                  )
                )
              : (
                <WaitlistTr shadow={false}>
                  <WaitlistTd colSpan={tableTitles.length}>
                    <Center my="15%">
                      <CustomIcons
                        iconName={IconName.NoTurnsIcon}
                        iconProps={{
                          width: '60px',
                          height: '60px',
                          fill: colors.textColor
                        }}
                      />
                      <NoTurnsText>
                        There are still no turns on your history.
                      </NoTurnsText>
                    </Center>
                  </WaitlistTd>
                </WaitlistTr>
                )}
          </WaitlistTbody>
        </StyledHistoryTable>
      </TableWrapper>
      {paginateData.totalPages > 1 && (
        <ArrowsContainer>
          <TouchableOpacity
            disabled={isStartDisabled}
            onPress={() => {
              setPaginateData({
                ...paginateData,
                skip: paginateData.skip - 1
              })
            }}
          >
            <ChevronLeftIcon color={isStartDisabled ? 'gray.300' : 'black'} />
          </TouchableOpacity>
          <TouchableOpacity
            disabled={isFinishDisabled}
            onPress={() => {
              setPaginateData({
                ...paginateData,
                skip: paginateData.skip + 1
              })
            }}
          >
            <ChevronRightIcon color={isFinishDisabled ? 'gray.300' : 'black'} />
          </TouchableOpacity>
          <Text>
            {paginateData.currentPage}
            -
            {paginateData.totalPages}
          </Text>
        </ArrowsContainer>
      )}
    </Skeleton>
  )
}

export default HistoryTable
