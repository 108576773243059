import styled from 'styled-components/native'
import colors from '@instaseat/lib/themes/colors'

export const Button = styled.View`
  border-radius: 4;
  height: 40;
  margin-top: 10;
`

export const Container = styled.View`
  background-color: white;
  justify-content: center;
  margin-top: 45px;
`

export const Error = styled.Text`
  color: ${colors.errorColor};
  font-size: 12px;
  font-style: italic;
  height: 15px;
  display: block;
`

export const InputsContainer = styled.View`
  justify-content: space-between;
  min-height: 240px;
`

export const Input = styled.View`
  border-width: 2;
  border-color: ${colors.primaryBlack};
  padding: 10;
  border-radius: 4;
`

export const Label = styled.Text`
  color: ${colors.primaryBlack};
  font-style: italic;
  margin-bottom: 8px;
`

export const PasswordErrorContainer = styled.View`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`

export const ForgotPasswordText = styled.Text`
  align-self: end;
  font-size: 12;
  font-style: italic;
  font-family: 'Lato';
  color: #B21B55;
  margin-top: 12px;
  margin-bottom: 10;
  text-align: right;
`
