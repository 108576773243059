import React from 'react'
import { WarningIcon } from 'native-base'
import { Control, Controller, Path, UseFormClearErrors } from 'react-hook-form'
import { CustomTextArea } from 'components/shared/customTextArea'
import { ControllerTextAreaWrapper, ErrorLabel, Label } from './styled'

export interface ControllerTextAreaProps<TFormValues> {
  control: Control<TFormValues>
  label: string
  name: Path<TFormValues>
  isInvalid?: boolean
  errorMsg?: string
  onFocus?: UseFormClearErrors<TFormValues>
}

const ControllerTextArea = <TFormValues extends
{[key: string] : string | { [k: string]: boolean}}>({
    control,
    label,
    name,
    isInvalid,
    errorMsg,
    onFocus
  }:ControllerTextAreaProps<TFormValues>) => {
  return (
    <ControllerTextAreaWrapper isInvalid={isInvalid}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomTextArea
            onBlur={onBlur}
            onFocus={() => onFocus?.(name)}
            onChangeText={onChange}
            value={typeof value === 'string' ? value : undefined}
          />
        )}
        rules={{ required: true }}
      />
      <ErrorLabel
        leftIcon={<WarningIcon size={4} />}
      >
        {errorMsg}
      </ErrorLabel>
    </ControllerTextAreaWrapper>
  )
}

ControllerTextArea.defaultProps = {
  errorMsg: undefined,
  isInvalid: false,
  onFocus: undefined
}

export default ControllerTextArea
