import { TurnDetailsPopulatedTurn } from '@instaseat/lib/interfaces/turn'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'

export interface ITurnToDrag {
  id: string,
  newPosition: number | null
}

export enum TurnsActionConsts {
  CUSTOMER_PENDING_CONFIRMATION_PENDING='CUSTOMER_PENDING_CONFIRMATION_PENDING',
  CUSTOMER_PENDING_CONFIRMATION_FULFILLED='CUSTOMER_PENDING_CONFIRMATION_FULFILLED',
  CUSTOMER_PENDING_CONFIRMATION_REJECTED='CUSTOMER_PENDING_CONFIRMATION_REJECTED',
  CANCEL_BY_HOST_PENDING='CANCEL_BY_HOST_PENDING',
  CANCEL_BY_HOST_FULFILLED='CANCEL_BY_HOST_FULFILLED',
  CANCEL_BY_HOST_REJECTED='CANCEL_BY_HOST_REJECTED',
  SEAT_CUSTOMER_PENDING='SEAT_CUSTOMER_PENDING',
  SEAT_CUSTOMER_FULFILLED='SEAT_CUSTOMER_FULFILLED',
  SEAT_CUSTOMER_REJECTED='SEAT_CUSTOMER_REJECTED',
  ADD_NEW_TURN_PENDING='ADD_NEW_TURN_PENDING',
  ADD_NEW_TURN_FULFILLED='ADD_NEW_TURN_FULFILLED',
  ADD_NEW_TURN_REJECTED='ADD_NEW_TURN_REJECTED',
  SET_ADD_TURN_CONTEXT='SET_ADD_TURN_CONTEXT',
  GET_TURN_DETAILS_PENDING='GET_TURN_DETAILS_PENDING',
  GET_TURN_DETAILS_FULFILLED='GET_TURN_DETAILS_FULFILLED',
  GET_TURN_DETAILS_REJECTED='GET_TURN_DETAILS_REJECTED',
  SET_TURN_TO_DRAG='SET_TURN_TO_DRAG',
  RETURN_TURN_TO_WAILIST_PENDING='RETURN_TURN_TO_WAILIST_PENDING',
  RETURN_TURN_TO_WAILIST_FULFILLED='RETURN_TURN_TO_WAILIST_FULFILLED',
  RETURN_TURN_TO_WAILIST_REJECTED='RETURN_TURN_TO_WAILIST_REJECTED',
  SAVE_TURN_ID='SAVE_TURN_ID',
  GET_ESTIMATED_QUOTE_BY_PARTY_PENDING='GET_ESTIMATED_QUOTE_BY_PARTY_PENDING',
  GET_ESTIMATED_QUOTE_BY_PARTY_FULFILLED='GET_ESTIMATED_QUOTE_BY_PARTY_FULFILLED',
  GET_ESTIMATED_QUOTE_BY_PARTY_REJECTED='GET_ESTIMATED_QUOTE_BY_PARTY_REJECTED',
  GET_CUSTOMER_NAME_BY_PHONE_NUMBER_PENDING='GET_CUSTOMER_NAME_BY_PHONE_NUMBER_PENDING',
  GET_CUSTOMER_NAME_BY_PHONE_NUMBER_FULFILLED='GET_CUSTOMER_NAME_BY_PHONE_NUMBER_FULFILLED',
  GET_CUSTOMER_NAME_BY_PHONE_NUMBER_REJECTED='GET_CUSTOMER_NAME_BY_PHONE_NUMBER_REJECTED',
  REMOVE_EMPTY_VIP_PENDING='REMOVE_EMPTY_VIP_PENDING',
  REMOVE_EMPTY_VIP_FULFILLED='REMOVE_EMPTY_VIP_FULFILLED',
  REMOVE_EMPTY_VIP_REJECTED='REMOVE_EMPTY_VIP_REJECTED',
}

export type TurnsActionTypes = ActionType<typeof actions>;

export interface TurnsState {
  readonly turnDetails: TurnDetailsPopulatedTurn | undefined;
  readonly turnToDrag: ITurnToDrag;
  readonly isFetching: boolean;
  readonly error: string;
  readonly turnID: string;
}
