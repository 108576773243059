import React from 'react'
import Svg, { Line, Path, SvgProps } from 'react-native-svg'

interface props {
  iconProps?: SvgProps
}

const NoRestaurantsIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="42" height="41" viewBox="0 0 42 41" fill="transparent" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M37.7274 27.5891L37.23 27.0786L35.4798 25.3263L34.9977 24.8435L34.0194 23.8785C34.6527 22.3235 34.9757 20.6593 34.97 18.98C34.9652 16.1942 34.0669 13.4835 32.4076 11.2474C30.7483 9.01135 28.4156 7.36803 25.7533 6.55958C23.091 5.75112 20.2397 5.82028 17.6196 6.75686C14.9995 7.69343 12.749 9.44791 11.1997 11.7618C9.65049 14.0757 8.88434 16.8267 9.01419 19.6095C9.14403 22.3924 10.163 25.0598 11.921 27.219C13.6789 29.3782 16.0829 30.9149 18.7789 31.6028C21.4748 32.2907 24.32 32.0933 26.8955 31.0398L27.8738 32.0194L30.0922 34.2271L30.602 34.7376C31.4153 35.5462 32.515 36 33.6611 36C34.8072 36 35.9069 35.5462 36.7202 34.7376L37.23 34.2271L37.7399 33.7166C38.5491 32.9021 39.0024 31.7992 39 30.6503C38.9977 29.5013 38.54 28.4003 37.7274 27.5891V27.5891ZM21.9915 23.6015C21.0767 23.6028 20.182 23.3323 19.4207 22.8242C18.6595 22.3161 18.0659 21.5933 17.7151 20.7472C17.3643 19.9011 17.2721 18.9698 17.4502 18.0712C17.6282 17.1727 18.0685 16.3472 18.7154 15.6993C19.3622 15.0514 20.1864 14.6103 21.0837 14.4318C21.981 14.2532 22.9111 14.3453 23.7561 14.6963C24.6012 15.0474 25.3232 15.6416 25.8308 16.4037C26.3384 17.1659 26.6087 18.0617 26.6076 18.9778C26.6104 19.5857 26.4929 20.1881 26.2619 20.7502C26.0309 21.3123 25.691 21.8231 25.2618 22.2529C24.8327 22.6828 24.3227 23.0233 23.7614 23.2548C23.2001 23.4862 22.5986 23.604 21.9915 23.6015V23.6015Z"
      fill="#D2CECE"
    />
    <Path
      // eslint-disable-next-line max-len
      d="M2 37.5L38.2162 1.28384"
      stroke="#F1F0F0"
      stroke-width="3"
    />
    <Line
      x1="4.13469"
      y1="39.5357"
      x2="40.3508"
      y2="3.31949"
      stroke="#D2CECE"
      stroke-width="3"
    />
  </Svg>
)

NoRestaurantsIcon.defaultProps = {
  iconProps: {}
}

export default NoRestaurantsIcon
