import React, { useEffect, useState } from 'react'
import { BoldContentTitle, ContentTitle, ContentWrapper } from './styled'
import { ModalButton } from 'components/shared/modalButton'
import { WarningIcon } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

interface IRemoveVIPModalProps {
  onPress: () => void
  isLoading: boolean
  waitTime: string
  isVisible: boolean
}

const RemoveVIPModalContent = ({ isLoading, onPress, waitTime, isVisible }: IRemoveVIPModalProps) => {
  const [isRemoveDisabled, setIsRemoveDisabled] = useState(false)

  const onPressHandle = () => {
    setIsRemoveDisabled(true)
    onPress()
  }

  useEffect(() => {
    isVisible && setIsRemoveDisabled(false)
  }, [isVisible])

  return (
    <ContentWrapper>
      <WarningIcon color={colors.errorColor} size="xl" />
      <ContentTitle>
        A VIP Window with a wait time of
        {' '}
        <BoldContentTitle>{waitTime}</BoldContentTitle>
        {' '}
        will be removed.
      </ContentTitle>
      <ModalButton isLoading={isLoading} onPress={onPressHandle} text='Remove' isDisabled={isRemoveDisabled} />
    </ContentWrapper>
  )
}

RemoveVIPModalContent.displayName = 'RemoveVIPModalContent'

export default RemoveVIPModalContent
