import styled from 'styled-components'
import colors from '@instaseat/lib/themes/colors'
import { Text, View, Image } from 'native-base'

export const Container = styled(View).attrs({
  bg: colors.white,
  h: '100%',
  alignItems: 'center'
})``

export const TextContainer = styled(View).attrs({
  alignItems: 'left'
})``

export const TitleText = styled(Text).attrs({
  fontFamily: 'Lato',
  fontSize: '24px',
  marginBottom: '2%'
})``

export const StyledText = styled(Text).attrs({
  fontFamily: 'Lato',
  fontSize: '15px',
  marginLeft: '10%',
  marginRight: '10%',
  marginBottom: '1%'
})``

export const LogoImg = styled(Image).attrs({
  accessibilityLabel: 'Logo',
  width: '100%',
  resizeMode: 'contain',
  height: '45px',
  alt: 'InstaSeat',
  marginTop: '5%',
  marginBottom: '3%'
})`
`
