import colors from '@instaseat/lib/themes/colors'
import { FormControl } from 'native-base'
import styled from 'styled-components/native'

export const ControllerInputWrapper = styled(FormControl)<{reduceMarginBottom?: number}>`
  margin-inline: auto;
  flex: 1;
  margin-bottom: ${({ isInvalid, reduceMarginBottom }) => {
    const reduceMargin = reduceMarginBottom ?? 0
    return isInvalid ? `${30 - reduceMargin}px` : `${56 - reduceMargin}px`
}};
  min-width: 125px;
`

export const Label = styled(FormControl.Label)`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.labelDisabled};
`

export const ErrorLabel = styled(FormControl.ErrorMessage)`
  color: ${colors.errorColor};
  height: 18px;
  padding-left: 5px;
  font-family: 'Lato';
  font-style: 'italic';
  font-size: 12px;
`
