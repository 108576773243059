import React, { useCallback, useEffect, useMemo } from 'react'
import { View, useToast } from 'native-base'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import WaitlistScreen from 'routes/waitlist'
import Settings from 'routes/settings'
import NavBar from 'components/navBar'
import colors from '@instaseat/lib/themes/colors'
import HistoryScreen from 'routes/history'
import { sioHostWaitlist } from '@instaseat/lib/interfaces/sioSubscriptions'
import { useDispatch } from 'store'
import { socket, socketInit } from 'utils/socketIO'
import { setWaitlistData } from 'store/modules/Waitlist'
import ProfileScreen from 'routes/profile'
import { getCurrentFirebaseToken } from 'utils/helpers/firebase'
import linking from './linking'
import { useWindowDimensions } from 'react-native'
import MobileWaitlistScreen from 'routes/mobileWaitlist'
import MobileProfileScreen from 'routes/mobileProfile'
import MobileNavBar from 'components/mobileNavBar'

const Stack = createStackNavigator()

const HeaderComponent = (isMobile: boolean) => {
  return (
    <View bg={colors.white}>
      {isMobile ? <MobileNavBar /> : <NavBar />}
    </View>
  )
}

interface Props {
  isConnected: boolean | null
}

const MOBILE_WIDTH_LIMIT = 505

const Content = ({ isConnected }: Props) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const memoToast = useMemo(() => toast, [toast])
  const signedInAppInit = useCallback(async () => {
    const token = await getCurrentFirebaseToken()
    socketInit(token)
    socket.emit('waitlist')
    socket.on('waitlist', (payload: sioHostWaitlist) => {
      dispatch(setWaitlistData(payload))
    })

    return () => { socket.off('waitlist') }
  }, [dispatch])
  const { width } = useWindowDimensions()
  const isMobile = width < MOBILE_WIDTH_LIMIT

  useEffect(() => {
    signedInAppInit()
  }, [signedInAppInit])

  useEffect(() => {
    if (isConnected === false) {
      return memoToast.show({
        title: 'Offline',
        status: 'error',
        description: 'You are in offline mode',
        duration: null
      })
    }
    memoToast.closeAll()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected])

  return (
    <View height="100%">
      <NavigationContainer linking={linking}>
        <Stack.Navigator
          initialRouteName="WaitlistScreen"
          screenOptions={{
            title: 'InstaSeat',
            headerShadowVisible: false,
            header: () => HeaderComponent(isMobile)
          }}
        >
          {
            isMobile
              ? (
                <>
                  <Stack.Screen name="WaitlistScreen" component={MobileWaitlistScreen} />
                  <Stack.Screen name="ProfileScreen" component={MobileProfileScreen} />
                </>
                )
              : (
                <>
                  <Stack.Screen name="WaitlistScreen" component={WaitlistScreen} />
                  <Stack.Screen name="HistoryScreen" component={HistoryScreen} />
                  <Stack.Screen name="SettingsScreen" component={Settings} />
                  <Stack.Screen name="ProfileScreen" component={ProfileScreen} />
                </>
                )
          }
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}

export default Content
