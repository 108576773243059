import React from 'react'
import { CustomModal } from '../shared/modal'
import { AddTurnErrorModalContent } from './content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal, showModal } from 'store/modules/Modals'
import PlusIcon from '@instaseat/lib/assets/icons/PlusIcon2'

const header = {
  title: 'Add Customer',
  IconComponent: <PlusIcon />
}

const AddTurnErrorModal = () => {
  const showAddTurnErrorModal = useAppSelector((store) => store.modals.showModal) === 'addTurnError'
  const customerName = useAppSelector((store) => store.modals.modalText.contentText)
  const dispatch = useAppDispatch()
  return (
    <CustomModal
      showModal={showAddTurnErrorModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={(
        <AddTurnErrorModalContent
          content={customerName}
          onPress={() => dispatch(showModal('addTurn'))}
        />
)}
    />
  )
}

export { AddTurnErrorModal }
