import React from 'react'
import BusinessScheduleModalContent from './content/Content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import { AddTurnModalLayout } from 'components/addTurnModal/layout'
import EditIcon from '@instaseat/lib/assets/icons/EditIcon'

const header = {
  title: 'Business schedule',
  IconComponent: <EditIcon />
}

const BusinessScheduleModal = () => {
  const showNotifyModal = useAppSelector((store) => store.modals.showModal) === 'schedule'
  const dispatch = useAppDispatch()
  return (
    <AddTurnModalLayout
      showModal={showNotifyModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={<BusinessScheduleModalContent isVisible={showNotifyModal} />}
    />
  )
}

export { BusinessScheduleModal }
