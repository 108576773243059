/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react'
import { ContentWrapper, ListRow, ListRowDetail, ListRowTitle, TextArea } from './styled'
import { TurnDetailsPopulatedTurn } from '@instaseat/lib/interfaces/turn'
import { Skeleton } from 'native-base'
import { TurnStatus, TurnStatusToShow, TurnTypes } from '@instaseat/lib/enums/turn'
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns'
import { roundCustomerWaitTime } from '@instaseat/lib/utils/helpers/helpers'
import { useAppSelector } from 'hooks/redux'

interface ITurnDetailModalContentProps {
  isLoading: boolean
  data: TurnDetailsPopulatedTurn | undefined
}

const TurnDetailModalContent = ({ isLoading, data }: ITurnDetailModalContentProps) => {
  const roundingValue = useAppSelector((store) => store.host.profileData.unit.roundingValue)
  const dateToReadableFormat = useCallback((date: Date|undefined) => {
    if (!date) return '-/-/--  -- : --  hs'
    const dateFormatted = new Date(date)
      .toLocaleString('en-US', { dateStyle: 'short', hourCycle: 'h23', timeStyle: 'short' })
    return `${dateFormatted}  hs`
  }, [])

  const secToReadableFormatHours = useCallback((seconds: number | undefined) => {
    if (seconds === undefined) return '--:--'

    let result = ''
    if (seconds < 0) result = '-'

    seconds = Math.abs(seconds)
    const minutes = Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60)

    result += `${minutes}:`
    result += `${('0' + (Math.floor(seconds % 60))).slice(-2)} min`
    return result
  }, [])

  const minToReadableFormatHours = useCallback((minutes: number | undefined) => {
    if (minutes === undefined) return '--:--'

    let result = ''
    if (minutes < 0) result = '-'

    result += `${minutes} min`
    return result
  }, [])

  const waitTimeFormatted = useCallback(() => {
    if (!data) return

    let secondsDiff

    if (data.status === TurnStatus.customerWaiting) {
      const refTime = new Date(data.reinsertedAt || data.createdAt)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    if (data.status === TurnStatus.customerWaiting && data.type === TurnTypes.VIP && data.vipCreationDate) {
      const refTime = new Date(data.vipCreationDate)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    if (data.status === TurnStatus.pendingConfirmation && data.courtesyTimeExpiration) {
      const refTime = new Date(data.courtesyTimeExpiration)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }

    return secondsDiff
  }, [data])

  const courtesyTimeFormatted = useCallback(() => {
    if (!data || data.status === TurnStatus.customerWaiting) return
    if (data.status === TurnStatus.pendingConfirmation && data.courtesyTime) {
      return new Date().getTime() - new Date(data.courtesyTime).getTime()
    }
    if (data.status === TurnStatus.customerOnItsWay && data.isComingTime && data.courtesyTime) {
      return new Date(data.isComingTime).getTime() - new Date(data.courtesyTime).getTime()
    }
    if (data.seatTime && data.courtesyTime) {
      return differenceInSeconds(new Date(data.seatTime), new Date(data.courtesyTime))
    }
  }, [data])

  const formattedData = useMemo(() => ({
    'First Name': data?.customer.firstName || '---',
    'Last Name': data?.customer.lastName || '---',
    Status: TurnStatusToShow[data?.status || 'customerWaiting'],
    Type: data?.type,
    'Phone number': data?.customer.phone,
    'Party size': data?.party,
    'Current Wait Time': minToReadableFormatHours(data?.waitTime),
    'Original Wait Time': minToReadableFormatHours(data?.originalWaitTime),
    'Rounded Original Wait Time': minToReadableFormatHours(roundCustomerWaitTime(
      data?.originalWaitTime || 0,
      roundingValue)),
    'Elapsed Time': secToReadableFormatHours(waitTimeFormatted()),
    Quote: `${data?.quote || '---'} min`,
    'Elapsed Courtesy Time': secToReadableFormatHours(Math.floor((courtesyTimeFormatted() ?? 0) / 1000)),
    'Check in date': dateToReadableFormat(data?.checkInTime),
    'Seat date': dateToReadableFormat(data?.seatTime),
    'Cancelation date': dateToReadableFormat(data?.cancelTime),
    'Reinserted at': dateToReadableFormat(data?.reinsertedAt),
    Preferences: data?.turnOptions?.length ? data.turnOptions : ['---']
  }), [courtesyTimeFormatted,
    data?.checkInTime,
    data?.customer.firstName,
    data?.customer.lastName,
    data?.customer.phone,
    data?.party,
    data?.quote,
    data?.seatTime,
    data?.status,
    data?.turnOptions,
    data?.type,
    data?.reinsertedAt,
    data?.waitTime,
    data?.cancelTime,
    data?.originalWaitTime,
    dateToReadableFormat,
    secToReadableFormatHours,
    minToReadableFormatHours,
    waitTimeFormatted,
    roundCustomerWaitTime
  ])

  return (
    <ContentWrapper space="8px">
      <Skeleton.Text lines={12} isLoaded={!isLoading}>
        {Object.entries(formattedData).map(([key, data]) => (
          <ListRow key={key}>
            <ListRowTitle>{key}</ListRowTitle>
            {Array.isArray(data)
              ? <ListRowDetail capitalize>{data.join(' / ')}</ListRowDetail>
              : <ListRowDetail capitalize={!key.includes('time') && !key.includes('date')}>{data}</ListRowDetail>}
          </ListRow>
        ))}
      </Skeleton.Text>
      <Skeleton mt='10px' width="90%" height="100px" isLoaded={!isLoading}>
        {
          data?.canceledReason && (
          <>
            <ListRowTitle alignSelf="flex-start">Canceled reason</ListRowTitle>
            <TextArea editable={false} value={data?.canceledReason || '---'} />
          </>
          )
        }
        <ListRowTitle alignSelf="flex-start" mt="3%">Notes</ListRowTitle>
        <TextArea editable={false} value={data?.notes || '---'} />
      </Skeleton>
    </ContentWrapper>
  )
}

export default TurnDetailModalContent
