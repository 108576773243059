/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { CustomerNameText, IconWrapper, WaitlistTd, WaitlistTr } from './styled'
import colors from '@instaseat/lib/themes/colors'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { TurnStatus, TurnTypes } from '@instaseat/lib/enums/turn'
import { HStack, View } from 'native-base'
import { QuoteAndElapsedTimeCell } from './QuoteAndElapsedTimeCell'

interface ITableRowProps {
  turn: PopulatedWaitlistItem,
  shouldUpdateTime: boolean,
}

const TableRow = ({ turn, shouldUpdateTime }: ITableRowProps) => {
  const [isHidden, setIsHidden] = useState(false)
  const customerName = `${turn?.customer?.firstName} ${turn?.customer?.lastName}`.trim()

  const hideRowFromView = useCallback(() => {
    setTimeout(() => {
      setIsHidden(true)
    }
    , 1000)
  }
  , [])

  useEffect(() => {
    if (turn.status === TurnStatus.canceledByCustomer ||
        turn.status === TurnStatus.canceledByHost || turn.status === TurnStatus.seated) {
      hideRowFromView()
    }
    if (turn?.type === TurnTypes.VIP && turn?._id === undefined) {
      setIsHidden(true)
    }
  }
  , [turn, hideRowFromView])

  const getStatusIcon = useCallback((status: TurnStatus) => {
    switch (status) {
      case TurnStatus.customerWaiting:
        return (
          <CustomIcons
            iconName={IconName.ClockIcon}
            iconProps={{ fill: colors.tertiary, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.canceledByCustomer:
      case TurnStatus.canceledByHost:
        return (
          <CustomIcons
            iconName={IconName.CancelledIcon}
            iconProps={{ fill: colors.errorColor, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.seated:
        return (
          <CustomIcons
            iconName={IconName.CheckIcon}
            iconProps={{ fill: colors.success, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.customerOnItsWay:
        return (
          <CustomIcons
            iconName={IconName.HostOnItsWayIcon}
            iconProps={{ fill: colors.success, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.pendingConfirmation:
        return (
          <CustomIcons
            iconName={IconName.HostOnItsWayIcon}
            iconProps={{ fill: colors.tertiary, height: '24px', width: '24px' }}
          />
        )
      default:
        return (
          <CustomIcons
            iconName={IconName.ClockIcon}
            iconProps={{ fill: colors.tertiary, height: '24px', width: '24px' }}
          />
        )
    }
  }, [])

  return (
    <WaitlistTr
      display={isHidden ? 'none' : 'table-row'}
      bordered={!!turn.movedWithDragAndDrop}
    >
      <WaitlistTd justifyContent='initial'>
        <HStack h='100%' alignItems='center' textAlign="start">
          <View alignSelf="center" px="5px">
            <CustomIcons
              iconName={IconName.LogoIcon}
              iconProps={{
                width: '14px',
                height: '14px',
                fill: turn.type === TurnTypes.VIP ? colors.primary : 'transparent'
              }}
            />
          </View>
          <CustomerNameText
            style={{
              fontWeight: (turn.party !== null && turn.party >= 6) ? 'bold' : 'normal'
            }}
          >
            {customerName}
          </CustomerNameText>
        </HStack>
      </WaitlistTd>
      <WaitlistTd
        id={turn._id?.toString()}
        style={{
          fontWeight: (turn.party !== null && turn.party >= 6) ? 'bold' : 'normal',
          color: (turn.party !== null && turn.party >= 6) ? colors.tertiary : colors.textColor
        }}
      >
        {turn.party}
      </WaitlistTd>
      <WaitlistTd
        id={turn._id?.toString()}
        style={{ textTransform: 'none' }}
      >
        <QuoteAndElapsedTimeCell turn={turn} shouldUpdate={shouldUpdateTime} />
      </WaitlistTd>
      <WaitlistTd id={turn._id?.toString()}>
        <IconWrapper>
          {turn.status && getStatusIcon(turn.status)}
        </IconWrapper>
      </WaitlistTd>
    </WaitlistTr>
  )
}
export default TableRow
