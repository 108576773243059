import React, { useEffect } from 'react'
import { View } from 'native-base'
import colors from '@instaseat/lib/themes/colors'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { ButtonContainer, ButtonText, IconShadow } from './styled'

interface Props {
  buttonType: string,
  onPress: () => void,
  isDisabled?: boolean
}

const WaitlistActionButton = ({ onPress, buttonType, isDisabled }: Props) => {
  const [iconName, setIconName] = React.useState<IconName>(IconName.NotifyIcon)
  const [iconColor, setIconColor] = React.useState<string>(colors.textColor)

  useEffect(() => {
    switch (buttonType) {
      case 'notify':
        setIconName(IconName.NotifyIcon)
        setIconColor(colors.tertiary)
        break
      case 'cancel':
        setIconName(IconName.CancelledIcon)
        setIconColor(colors.errorColor)
        break
      case 'seat':
        setIconName(IconName.CheckIcon)
        setIconColor(colors.success)
        break
      default:
        break
    }
  }, [buttonType])

  return (
    <ButtonContainer onPress={() => !isDisabled && onPress()}>
      <View opacity={isDisabled ? 0.3 : 1}>
        <IconShadow />
        <CustomIcons
          iconName={iconName}
          iconProps={{
            width: '17px',
            height: '17px',
            fill: iconColor
          }}
        />
      </View>
      <ButtonText>
        {buttonType}
      </ButtonText>
    </ButtonContainer>
  )
}

WaitlistActionButton.defaultProps = {
  isDisabled: false
}

export default WaitlistActionButton
