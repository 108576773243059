import { LoadingSpinner } from '@instaseat/lib/components'
import colors from '@instaseat/lib/themes/colors'
import { ModalPressable } from 'components/shared/modalButton'
import { HStack, Text, View, VStack } from 'native-base'
import styled from 'styled-components/native'
import { isOutdatedIOSVersion } from 'utils/helpers'

export const ContentWrapper = styled(VStack)`
  padding-block: min(32px, 3%);
  padding-inline: min(60px, 6%);
`

export const FormRow = styled(HStack)`
  flex-wrap: wrap;
  justify-content: center;
`

export const FormRowFlexEnd = styled(FormRow)`
  justify-content: flex-end;
`

export const FormColumn = styled(HStack)`
  padding-inline: 5%;
  min-width: 230px;
  width: ${isOutdatedIOSVersion() ? '40%' : '50%'};
  flex-wrap: wrap;
  justify-content: center;
  margin-right:${isOutdatedIOSVersion() ? '15px' : 0};
  margin-left:${isOutdatedIOSVersion() ? '15px' : 0};
`

export const FormColumnWrap = styled(View)`
  flex-wrap: wrap;
  padding-inline: 5%;
  min-width: 180px;
  width: ${isOutdatedIOSVersion() ? '40%' : '50%'};
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  column-gap: 15px;
`

export const FormButton = styled(ModalPressable).attrs({
  borderRadius: '16px',
  width: '100%',
  size: 'lg'
})`
  width: 100%;
`

export const PhoneLeftComponent = styled(Text).attrs({ children: '+1' })`
  font-family: 'Lato';
  font-size: 13px;
  line-height: 200%;
  padding-inline: 10px;
  color: #0004;
  border-right-color: ${colors.secondary};
  border-right-width: 1px;
  margin-right: 10px;
  cursor: default;
`

export const QuoteRightComponent = styled(View)`
  font-family: 'Lato';
  display: block;
  padding-inline: 10px;
  border-left-color: ${colors.secondary};
  border-left-width: 1px;
  cursor: default;
`

export const MinText = styled(Text).attrs({ children: 'min' })`
  font-size: 13px;
  line-height: 200%;
  color: #0004;
`

export const PreferenceContainer = styled(View)`
  width: 100%;
  max-width: 350px;
  height: fit-content;
  flex-direction: row;
  flex-wrap: wrap;
`

export const InputLoading = styled(LoadingSpinner).attrs({ spinnerColor: colors.webModalText })`
  margin-left: 10px;
`

export const EstimatedTimeText = styled(Text).attrs({ shadow: 4 })`
  visibility: ${({ children }) => children ? 'visible' : 'hidden'};
  min-width: 90%;
  flex-shrink: 0;
  margin-bottom: 10px;
  padding-inline: 5px;
  color: ${colors.labelDisabled};
  height: 20px;
  font-family: 'Lato';
  font-style: 'italic';
  font-size: 12px;
  text-align: center;
  background-color: ${colors.bgGrey};
  border-radius: 5px;
  border: 1px ${colors.bone};
`
