import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const SeeMoreIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="4" height="20" viewBox="0 0 4 20" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.00065 20C2.92112 20 3.66732 19.2538 3.66732 18.3333C3.66732 17.4129 2.92112 16.6667 2.00065 16.6667C1.08018 16.6667 0.333984 17.4129 0.333984 18.3333C0.333984 19.2538 1.08018 20 2.00065 20Z"
      fill={iconProps?.fill || '#210f0b9e'}
      fillOpacity="0.7"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Path
      // eslint-disable-next-line max-len
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.00065 11.6667C2.92112 11.6667 3.66732 10.9205 3.66732 10C3.66732 9.07953 2.92112 8.33334 2.00065 8.33334C1.08018 8.33334 0.333984 9.07953 0.333984 10C0.333984 10.9205 1.08018 11.6667 2.00065 11.6667Z"
      fill={iconProps?.fill || '#210f0b9e'}
      fillOpacity="0.7"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Path
      // eslint-disable-next-line max-len
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.00065 3.33333C2.92112 3.33333 3.66732 2.58714 3.66732 1.66667C3.66732 0.746192 2.92112 0 2.00065 0C1.08018 0 0.333984 0.746192 0.333984 1.66667C0.333984 2.58714 1.08018 3.33333 2.00065 3.33333Z"
      fill={iconProps?.fill || '#210f0b9e'}
      fillOpacity="0.7"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
  </Svg>
)

SeeMoreIcon.defaultProps = {
  iconProps: {}
}

export default SeeMoreIcon
