import colors from '@instaseat/lib/themes/colors'
import { Pressable, Text, View } from 'native-base'
import styled from 'styled-components'

export const ButtonContainer = styled(Pressable).attrs({
  size: '45px',
  bg: colors.bgGrey,
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center'
})``

export const IconShadow = styled(View).attrs({
  borderRadius: 'full',
  size: '100%',
  shadow: 7,
  position: 'absolute'
})``

export const ButtonText = styled(Text).attrs({
  paddingTop: '3px',
  fontSize: '10px',
  textTransform: 'capitalize'
})`
  font-family: 'Roboto';
  color: ${colors.textColor};
`
