import React, { useCallback, useState } from 'react'
import { useSelector, RootState } from 'store'
import useAuthToken from 'hooks/use-auth-token'
import * as Sentry from '@sentry/react'
import { Content } from './content'
import { Auth } from './auth'
import AllModals from 'components/allModals'
import { useIsConnected } from 'react-native-offline'
import Offline from '@instaseat/lib/components/Offline'
import SplashScreenWeb from '../assets/logos/Splashscreen'
import { Status } from '@instaseat/lib/enums/status'
import { UnitDisabledScreen } from 'components/unitDisabledScreen'

const Navigator = () => {
  const { isSignedIn, user } = useSelector((store: RootState) => store.auth)
  const { status } = useSelector((store: RootState) => store.host.profileData.unit)
  const isConnected = useIsConnected()
  const [skip, setSkip] = useState(false)

  Sentry.setUser({ id: user?.firebaseUID, email: user?.email })

  useAuthToken()

  const handleRenderComponents = useCallback(() => {
    if (!skip && isConnected === false) return <Offline setSkip={setSkip} />

    if (isSignedIn == null) return <SplashScreenWeb />

    if (status !== Status.active) return <UnitDisabledScreen />

    return (
      <>
        <AllModals />
        {isSignedIn
          ? (<Content isConnected={isConnected} />)
          : (<Auth />)}
      </>
    )
  }, [isConnected, isSignedIn, skip, status])

  return (handleRenderComponents())
}

export default Navigator
