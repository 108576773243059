/* eslint-disable max-len */
import colors from '@instaseat/lib/themes/colors'
import { View } from 'native-base'
import React from 'react'
import Svg, { Path } from 'react-native-svg'

const SplashScreenWeb = () => (
  <View backgroundColor={colors.white} height='100%' justifyContent="center" alignItems="center">
    <Svg width="243" height="170" viewBox="0 0 243 170" fill="none">
      <Path d="M152.024 0H91.0967C86.0368 0.0156309 81.1885 2.03128 77.6106 5.60683C74.0326 9.18239 72.0156 14.0274 72 19.084V79.9709C72.0156 85.0275 74.0326 89.8725 77.6106 93.448C81.1885 97.0236 86.0368 99.0392 91.0967 99.0549H152.024C157.086 99.0444 161.937 97.0305 165.516 93.4538C169.095 89.8771 171.111 85.0291 171.121 79.9709V19.084C171.105 14.0274 169.088 9.18239 165.511 5.60683C161.933 2.03128 157.084 0.0156309 152.024 0V0ZM104.381 75.0715C103.474 75.9773 102.319 76.5941 101.061 76.8438C99.8036 77.0935 98.5001 76.965 97.3155 76.4744C96.1309 75.9839 95.1184 75.1533 94.4061 74.0878C93.6938 73.0223 93.3136 71.7696 93.3136 70.4882C93.3136 69.2068 93.6938 67.9541 94.4061 66.8886C95.1184 65.8231 96.1309 64.9925 97.3155 64.502C98.5001 64.0114 99.8036 63.8829 101.061 64.1326C102.319 64.3823 103.474 64.999 104.381 65.9049C104.984 66.5066 105.462 67.2211 105.788 68.0076C106.114 68.794 106.282 69.6369 106.282 70.4882C106.282 71.3395 106.114 72.1824 105.788 72.9688C105.462 73.7552 104.984 74.4697 104.381 75.0715ZM121.264 76.5532L94.3191 49.646L121.264 22.7388L148.209 49.646L121.264 76.5532ZM146.707 32.7944C145.8 33.7002 144.644 34.317 143.386 34.5667C142.129 34.8164 140.825 34.6879 139.641 34.1973C138.456 33.7068 137.444 32.8762 136.731 31.8107C136.019 30.7452 135.639 29.4925 135.639 28.2111C135.639 26.9297 136.019 25.677 136.731 24.6115C137.444 23.5459 138.456 22.7154 139.641 22.2248C140.825 21.7343 142.129 21.6057 143.386 21.8555C144.644 22.1052 145.8 22.7219 146.707 23.6278C147.309 24.2295 147.787 24.944 148.113 25.7305C148.439 26.5169 148.607 27.3598 148.607 28.2111C148.607 29.0624 148.439 29.9053 148.113 30.6917C147.787 31.4781 147.309 32.1926 146.707 32.7944Z" fill={colors.primary} />
      <Path d="M5.0034 127C4.01382 127 3.04647 127.293 2.22366 127.843C1.40086 128.392 0.759558 129.173 0.380862 130.087C0.00216618 131 -0.0969156 132.006 0.0961419 132.976C0.289199 133.945 0.76573 134.836 1.46547 135.536C2.16521 136.235 3.05673 136.711 4.02729 136.904C4.99786 137.097 6.00388 136.998 6.91813 136.62C7.83238 136.241 8.6138 135.6 9.16358 134.778C9.71336 133.956 10.0068 132.989 10.0068 132C9.98838 130.68 9.45533 129.419 8.52097 128.485C7.58662 127.551 6.32465 127.018 5.0034 127Z" fill={colors.primary} />
      <Path d="M9.45118 139.906H0.541992V169.365H9.45118V139.906Z" fill={colors.primary} />
      <Path d="M14.4541 140.006V169.023H22.6791V153.14C22.6791 149.037 24.8173 146.687 28.552 146.687C29.304 146.643 30.0567 146.761 30.7586 147.035C31.4606 147.308 32.0951 147.73 32.6189 148.271C33.1427 148.812 33.5434 149.46 33.7936 150.17C34.0438 150.88 34.1376 151.635 34.0686 152.385V169.023H42.2935V151.131C42.2935 142.869 36.891 139.18 31.517 139.18C29.9705 139.078 28.4217 139.341 26.9955 139.947C25.5693 140.553 24.3056 141.486 23.3063 142.67L22.6791 143.525V140.006H14.4541Z" fill={colors.primary} />
      <Path d="M44.9883 163.268C47.1978 167.542 51.4599 169.85 57.3186 169.85C63.1773 169.85 69.3353 167.385 69.3353 160.448C69.3353 153.51 63.4909 152.114 59.2287 150.989C56.7056 150.334 54.71 149.807 54.71 148.339C54.71 146.872 55.7791 146.331 57.5609 146.331C58.4102 146.32 59.2454 146.548 59.9712 146.989C60.6971 147.43 61.2844 148.066 61.6663 148.824L68.6653 145.049C67.4732 143.201 65.8257 141.691 63.8811 140.663C61.9365 139.635 59.7601 139.125 57.5609 139.18C51.859 139.18 46.2569 142.029 46.2569 148.567C46.2569 155.106 52.0586 156.502 56.2352 157.67C58.7868 158.382 60.811 158.952 60.811 160.391C60.811 161.388 60.1981 162.57 57.2616 162.57C56.1457 162.647 55.0349 162.362 54.0929 161.76C53.151 161.157 52.4278 160.267 52.0301 159.223L44.9883 163.268Z" fill={colors.primary} />
      <Path d="M128.164 163.268C130.374 167.542 134.621 169.85 140.48 169.85C146.339 169.85 152.511 167.385 152.511 160.448C152.511 153.51 146.667 152.114 142.404 150.989C139.881 150.334 137.886 149.807 137.886 148.339C137.886 146.872 138.941 146.331 140.737 146.331C141.588 146.319 142.426 146.547 143.154 146.987C143.882 147.428 144.472 148.064 144.856 148.824L151.841 145.049C150.655 143.195 149.009 141.68 147.063 140.652C145.117 139.623 142.937 139.116 140.737 139.18C135.106 139.18 129.433 142.029 129.433 148.567C129.433 155.106 135.234 156.502 139.411 157.67C141.977 158.382 144.001 158.952 144.001 160.391C144.001 161.388 143.374 162.57 140.437 162.57C139.32 162.647 138.206 162.363 137.262 161.761C136.318 161.158 135.592 160.268 135.192 159.223L128.164 163.268Z" fill={colors.primary} />
      <Path d="M71.7871 147.869H76.5482V158.724C76.5482 162.77 77.375 165.391 79.171 167.015C81.3235 168.966 85.0582 169.607 90.9027 169.052V161.644C88.237 161.787 86.498 161.644 85.5287 160.775C85.2647 160.506 85.0609 160.184 84.9307 159.831C84.8004 159.477 84.7467 159.1 84.7731 158.724V147.869H90.9027V140.006H84.7731V131.815L76.5482 134.279V139.977H71.7871V147.869Z" fill={colors.primary} />
      <Path d="M223.898 147.869H228.66V158.724C228.66 162.77 229.486 165.391 231.282 167.015C233.435 168.966 237.17 169.607 243 169.052V161.644C240.348 161.787 238.595 161.644 237.64 160.775C237.376 160.506 237.172 160.184 237.042 159.831C236.912 159.477 236.858 159.1 236.884 158.724V147.869H243V140.006H236.884V131.815L228.66 134.279V139.977H223.898V147.869Z" fill={colors.primary} />
      <Path d="M107.437 139.18C99.6684 139.18 93.5674 145.918 93.5674 154.508C93.5674 163.097 99.6684 169.85 107.437 169.85C109.033 169.931 110.627 169.659 112.106 169.053C113.584 168.448 114.911 167.523 115.99 166.345L116.589 165.647V169.023H124.828V140.006H116.66V143.368L116.061 142.684C114.977 141.494 113.639 140.561 112.147 139.955C110.655 139.348 109.045 139.083 107.437 139.18ZM109.219 162.1C108.218 162.142 107.22 161.974 106.289 161.604C105.358 161.235 104.516 160.674 103.816 159.958C103.117 159.241 102.577 158.386 102.23 157.447C101.884 156.508 101.74 155.506 101.807 154.508C101.807 152.543 102.588 150.659 103.978 149.27C105.368 147.88 107.253 147.1 109.219 147.1C111.185 147.1 113.07 147.88 114.46 149.27C115.851 150.659 116.631 152.543 116.631 154.508C116.698 155.506 116.554 156.508 116.208 157.447C115.862 158.386 115.321 159.241 114.622 159.958C113.922 160.674 113.08 161.235 112.149 161.604C111.218 161.974 110.22 162.142 109.219 162.1Z" fill={colors.primary} />
      <Path d="M201.961 139.18C194.192 139.18 188.105 145.918 188.105 154.508C188.105 163.097 194.192 169.85 201.961 169.85C203.557 169.931 205.151 169.659 206.63 169.053C208.108 168.448 209.435 167.523 210.514 166.345L211.113 165.647V169.023H219.338V140.006H211.17V143.368L210.571 142.684C209.487 141.496 208.152 140.565 206.662 139.959C205.173 139.353 203.567 139.087 201.961 139.18ZM203.743 162.1C202.742 162.143 201.744 161.974 200.813 161.604C199.882 161.235 199.04 160.674 198.34 159.958C197.641 159.241 197.1 158.386 196.754 157.447C196.408 156.508 196.263 155.506 196.33 154.508C196.287 153.507 196.446 152.507 196.799 151.569C197.152 150.631 197.691 149.775 198.385 149.051C199.078 148.327 199.911 147.751 200.833 147.357C201.755 146.964 202.747 146.761 203.75 146.761C204.753 146.761 205.745 146.964 206.667 147.357C207.589 147.751 208.422 148.327 209.115 149.051C209.809 149.775 210.348 150.631 210.701 151.569C211.054 152.507 211.213 153.507 211.17 154.508C211.237 155.507 211.092 156.51 210.745 157.45C210.398 158.39 209.856 159.247 209.155 159.963C208.454 160.68 207.61 161.241 206.677 161.609C205.745 161.977 204.745 162.145 203.743 162.1Z" fill={colors.primary} />
      <Path d="M170.401 139.18C168.36 139.101 166.324 139.442 164.42 140.183C162.516 140.923 160.785 142.047 159.335 143.485C157.884 144.922 156.745 146.643 155.988 148.539C155.231 150.435 154.872 152.467 154.935 154.507C154.935 163.539 161.563 169.85 171.057 169.85C176.459 169.85 180.65 167.941 183.501 164.151L176.944 160.376C175.391 161.802 173.35 162.577 171.242 162.542C167.365 162.542 164.856 161.117 163.787 158.168L163.616 157.698H184.998C185.202 156.637 185.302 155.559 185.297 154.479C185.155 145.775 178.811 139.18 170.401 139.18ZM177.086 151.772H163.402L163.53 151.331C163.978 149.9 164.871 148.649 166.08 147.76C167.289 146.872 168.751 146.393 170.251 146.393C171.752 146.393 173.213 146.872 174.422 147.76C175.631 148.649 176.525 149.9 176.972 151.331L177.086 151.772Z" fill={colors.primary} />
    </Svg>
  </View>
)

export default SplashScreenWeb
