/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { SafeAreaView, Platform, TouchableOpacity } from 'react-native'
import { Container, Title, Subtitle, SkipText } from './style'
import OfflineIcon from '../../assets/icons/OfflineIcon'

interface Props {
  setSkip?: (value: boolean) => void
}

const Offline = ({ setSkip }: Props) => {
  const isWeb = Platform.OS === 'web'

  return (
    <SafeAreaView>
      <Container>
        <OfflineIcon />
        <Title>
          No internet
          connection found.
        </Title>
        <Subtitle>
          Please check your connection or try again.
        </Subtitle>
        {
          isWeb && (
            <TouchableOpacity
              onPress={() => setSkip(true)}
            >
              <SkipText>
                Skip
              </SkipText>
            </TouchableOpacity>
          )
        }
      </Container>
    </SafeAreaView>
  )
}

Offline.defaultProps = {
  setSkip: () => {}
}

export default Offline
