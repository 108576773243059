import React from 'react'
import { ContentText, ContentWrapper, SuccessIcon } from './styled'
import { ModalButton } from 'components/shared/modalButton'

interface IAddTurnSuccessModalContentProps {
  content: string
  onPress: () => void
}

const AddTurnSuccessModalContent = ({ content, onPress }: IAddTurnSuccessModalContentProps) => (
  <ContentWrapper space="40px">
    <SuccessIcon />
    <ContentText>
      {`${content} is at the waitlist.`}
    </ContentText>
    <ModalButton onPress={onPress} text="Done" />
  </ContentWrapper>
)

export default AddTurnSuccessModalContent
