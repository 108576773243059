import React from 'react'
import { Button } from 'native-base'
import { ViewStyle, StyleProp } from 'react-native'

export type ButtonProps = {
  children?: React.ReactNode | string
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  onPress: () => void
  testID?: string
  accessibilityLabel?: string
}

const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  style,
  disabled,
  ...props
}) => {
  return (
    <Button
      disabled={disabled}
      opacity={disabled ? 0.6 : 1}
      style={style}
      {...props}
    >
      {children}
    </Button>
  )
}

PrimaryButton.defaultProps = {
  children: null,
  style: {},
  disabled: false,
  testID: 'PrimaryButton',
  accessibilityLabel: 'PrimaryButton'
}

export default PrimaryButton
