import React, { useEffect } from 'react'
import { HStack, Skeleton, View } from 'native-base'
import { TouchableOpacity } from 'react-native'
import {
  HeaderContainer,
  HostInitials,
  StyledDivider,
  UnitName,
  NavBarLogoImage,
  HostContainer,
  UnitNameTouchable
} from './styled'
import { useDispatch } from 'react-redux'
import { getProfileData } from 'store/modules/Hosts'
import { useAppSelector } from 'hooks/redux'
import { useNavigation, useRoute } from '@react-navigation/native'
import { RootStackNavigationType } from 'types/NavigationTypes'

const MobileNavBar = () => {
  const unitName = useAppSelector(state => state.host.profileData.unit.name)
  const hostData = useAppSelector(state => state.host.profileData.host)
  const navigation = useNavigation<RootStackNavigationType>()
  const dispatch = useDispatch()
  const route = useRoute()

  useEffect(() => {
    dispatch(getProfileData())
  }, [dispatch])

  return (
    <HeaderContainer>
      <HStack justifyContent="flex-start" alignItems="center">
        <Skeleton
          w="170px"
          isLoaded={!!unitName}
        >
          <UnitNameTouchable
            onPress={() => { navigation.navigate('WaitlistScreen') }}
            disabled={route.name === 'WaitlistScreen'}
          >
            <NavBarLogoImage />
            <UnitName>
              {unitName}
            </UnitName>
          </UnitNameTouchable>
        </Skeleton>
        <StyledDivider style={{ marginTop: '1%', transform: [{ rotate: '90deg' }], right: '5.5%', width: '50px' }} />
      </HStack>
      <View
        justifyContent="center"
        gap="5%"
      >
        <Skeleton rounded="full" size="35px" alignSelf="flex-end" isLoaded={hostData.firstName !== ''}>
          <TouchableOpacity
            onPress={() => { navigation.navigate('ProfileScreen') }}
            disabled={route.name === 'ProfileScreen'}
          >
            <HostContainer>
              <HostInitials>
                {hostData?.firstName?.charAt(0) + ' ' + hostData?.lastName?.charAt(0)}
              </HostInitials>
            </HostContainer>
          </TouchableOpacity>
        </Skeleton>
      </View>
    </HeaderContainer>
  )
}

export default MobileNavBar
