const colors = {
  primary: '#B21B55',
  itemSelected: '#B93165',
  secondary: '#E9E4E3',
  errorColor: '#CC3333',
  errorColorSolid: '#CC2F2F',
  textColor: '#210F0B',
  tertiary: '#D68E38',
  success: '#55B21B',
  white: '#FFFFFF',
  black: '#000000',
  labelDisabled: '#151522',
  bone: '#F0F0F0',
  bgGrey: '#F1F0F0',
  webModalText: '#4F4E4F',
  webModalGreyText: '#210F0BB2',
  primaryBlack: '#210F0B'
}

export default colors
