import React from 'react'
import Svg, { Path, SvgProps, Rect } from 'react-native-svg'
import colors from '@instaseat/lib/themes/colors'
interface props {
  iconProps?: SvgProps
  viewBox?: string
}

const ClockIcon = ({ iconProps, viewBox }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="25" height="25" viewBox={viewBox} fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM16.5907 18.2289C16.8088 18.0709 16.8244 17.7577 16.6367 17.5645C16.4716 17.3947 16.206 17.3786 16.0106 17.5124C15.0023 18.2031 13.7822 18.6071 12.4678 18.6071C9.00128 18.6071 6.1911 15.7969 6.1911 12.3304C6.1911 8.86387 9.00128 6.0537 12.4678 6.0537C15.4556 6.0537 17.9557 8.14123 18.5894 10.9373L16.6166 10.6505L18.3498 14.9998L21.2498 11.3241L19.511 11.0713C18.9236 7.6242 15.922 5 12.3076 5C8.2717 5 5 8.2717 5 12.3076C5 16.3434 8.2717 19.6151 12.3076 19.6151C13.9074 19.6151 15.3872 19.101 16.5907 18.2289ZM12.4211 13.2747C12.2212 13.5145 11.8528 13.5145 11.6529 13.2747L11.1096 12.6229L11.6529 11.9711C11.8528 11.7313 12.2212 11.7313 12.4211 11.9711L12.9644 12.6229L12.4211 13.2747ZM12.9644 12.6229L15.2488 9.8824C15.5202 9.55675 15.2887 9.06225 14.8647 9.06225H9.2093C8.78535 9.06225 8.55378 9.55675 8.82523 9.8824L11.1096 12.6229L8.82523 15.3634C8.55378 15.689 8.78535 16.1835 9.2093 16.1835H14.8647C15.2887 16.1835 15.5202 15.689 15.2488 15.3634L12.9644 12.6229Z"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Rect
      x="10.7083"
      y="12.637"
      width="1.72575"
      height="1.72575"
      transform="rotate(-45 10.7083 12.637)"
      fill={colors.tertiary}
    />
  </Svg>
)

ClockIcon.defaultProps = {
  iconProps: {},
  viewBox: '0 0 25 25'
}

export default ClockIcon
