import { Pressable, Text, View } from 'native-base'
import styled from 'styled-components/native'

export const CheckButtonPressable = styled(Pressable)`
  margin-inline: auto;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
`

export const PreferenceBox = styled(Text)<{selected: boolean}>`
  font-family: 'Lato';
  padding: 7px 3px;
  font-weight: 400;
  font-size: 13px;
  color: ${(props) => props.selected ? '#FFF' : 'black'};
`

export const PreferenceBoxContainer = styled(View)<{selected: boolean}>`
  display: flex;
  align-items: center;
  background-color:  ${(props) => props.selected ? 'black' : 'auto'};
  border-radius: 99;
  width: 36px;
  height: 36px;
  cursor: pointer;
`
