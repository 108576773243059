import colors from '@instaseat/lib/themes/colors'
import { IInputProps } from 'native-base'

const borderColor = '#E4E4E4'

export const hoverStyle: Omit<IInputProps, '_hover'> = {
  borderColor,
  backgroundColor: '#FFF'
}

export const focusStyle:Omit<IInputProps, '_focus'> = hoverStyle

export const inputStyle: (isFilled: boolean, isLeftComponent: boolean)=>IInputProps = (isFilled, isLeftComponent) => ({
  backgroundColor: isFilled ? '#FFF' : colors.bgGrey,
  px: isLeftComponent ? 'none' : '15px',
  py: '10px',
  borderColor,
  borderRadius: '12px',
  shadow: 4,
  isFullWidth: true,
  overflowY: 'unset',
  overflowX: 'unset'
})
