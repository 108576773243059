import React from 'react'
import type { StyleProp, TextStyle } from 'react-native'
import { Subtitle as SubtitleStyled } from './styled'

const Subtitle: React.FC<{
  style?: StyleProp<TextStyle>;
  testID?: string;
}> = ({ children, style, testID }) => (
  <SubtitleStyled style={style} testID={testID}>
    {children}
  </SubtitleStyled>
)

Subtitle.defaultProps = {
  style: {},
  testID: ''
}

export default Subtitle
