import { UserTypes } from '@instaseat/lib/enums/users'
import { Profile } from 'routes/profile'
import { Dispatch } from 'redux'
import axios from 'axios'
import {
  getProfileDataCompleted,
  getProfileDataPending,
  getProfileDataRejected,
  updateUnitPending,
  updateUnitCompleted,
  updateUnitRejected,
  updateTurnPreferencesPending,
  updateTurnPreferencesCompleted,
  updateTurnPreferencesRejected,
  updateHostPending,
  updateHostCompleted,
  updateHostRejected
} from './actions'
import { TurnPreferences, UnitToSave } from './types'
import { getAuth } from 'firebase/auth'

export const getProfileData =
  () => async (dispatch: Dispatch) => {
    dispatch(getProfileDataPending())
    try {
      const user = await getAuth().currentUser?.getIdTokenResult()
      const userType = user?.claims?.userType

      if (!userType) {
        throw new Error('Host/Manager not found')
      }

      const endpointURL = userType === UserTypes.manager
        ? `${process.env.REACT_APP_API_URL}/managers/profile`
        : `${process.env.REACT_APP_API_URL}/hosts/profile`

      const response = await axios.get(endpointURL)
      dispatch(getProfileDataCompleted(response.data))
    } catch (ex) {
      let error = ex
      if (axios.isAxiosError(ex)) {
        if (ex.response) {
          error = `${ex.message} - ${(ex.response.data as {message: string}).message}`
        }
      }
      dispatch(getProfileDataRejected(error))
    }
  }

export const updateUnit =
  (id: string, data: UnitToSave) => async (dispatch: Dispatch) => {
    dispatch(updateUnitPending())
    try {
      const response = await axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/units/${id}`,
        data
      })
      dispatch(updateUnitCompleted(response?.data))
      return response?.data?.message
    } catch (error) {
      dispatch(updateUnitRejected(error))
    }
  }

export const updateTurnPreferences =
(id: string, data: TurnPreferences) => async (dispatch: Dispatch) => {
  dispatch(updateTurnPreferencesPending())
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/turn-preferences/${id}`,
      data
    })
    await dispatch(updateTurnPreferencesCompleted(response.data))
    return response?.data?.message
  } catch (error) {
    dispatch(updateTurnPreferencesRejected(error))
  }
}

export const updateHostData =
  (id: string, data: Profile) => async (dispatch: Dispatch) => {
    dispatch(updateHostPending())
    try {
      const user = await getAuth().currentUser?.getIdTokenResult()
      const userType = user?.claims?.userType

      if (!userType) {
        throw new Error('Host/Manager not found')
      }

      const endpointURL = userType === UserTypes.manager
        ? `${process.env.REACT_APP_API_URL}/managers/${id}`
        : `${process.env.REACT_APP_API_URL}/hosts/${id}`

      const response = await axios({
        method: 'put',
        url: endpointURL,
        data
      })
      await dispatch(updateHostCompleted(response.data))
      return response?.data?.message
    } catch (error) {
      dispatch(updateHostRejected(error))
    }
  }
