import colors from '@instaseat/lib/themes/colors'
import { Text, View, VStack } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding-inline: 24px;
  padding-block: 32px;
`

export const ContentTitle = styled(Text)`
  color: ${colors.webModalText};
  font-family: 'Lato';
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const BoldContentTitle = styled(ContentTitle)`
  font-weight: 700;
`

export const ContentParty = styled(ContentTitle)`
  align-self: flex-start;
  text-align: left;
  color: ${colors.primary};
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`

export const PreferenceContainer = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const PreferencePill = styled(Text)`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #210F0B;
  width: 125px;
  padding-block: 3px;
  display: block;
  border-color: #210F0B;
  border-width: 1px;
  border-radius: 100vmax;
  cursor: default;
  margin-bottom: 8px;
`

export const InnerContentWrapper = styled(VStack)`
  padding-inline: 12px;
`

export const NotesText = styled(ContentTitle)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  width: 95%;
  align-self: center;
`

export const NotesTitle = styled(NotesText).attrs({ children: 'Notes' })`
  color: ${colors.primary};
  width: 95%;
  margin-top: 16px;
  align-self: center;
`

export const TinyText = styled(ContentTitle)`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 95%;
  padding-inline: 12px;
`
