/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import { Input } from 'native-base'
import { focusStyle, hoverStyle, inputStyle } from './styled'
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native'

export interface CustomTextInputProps {
  value?: string
  onChangeText?: ((text: string) => void)
  placeholder?: string
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
  onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
  LeftComponent?: React.ReactElement
  RightComponent?: React.ReactElement
  numeric?: boolean
  isDisabled?: boolean
}

const CustomTextInput = ({
  value,
  onChangeText,
  placeholder,
  onBlur,
  onFocus,
  LeftComponent,
  RightComponent,
  numeric,
  isDisabled
}: CustomTextInputProps) => {
  const [isFilled, setIsFilled] = useState(false)
  const onChangeTextHandler = useCallback((value: string) => {
    setIsFilled(!!value)
    onChangeText?.(value)
  }, [onChangeText])

  useEffect(() => { if (!value) setIsFilled(false) }, [value])

  return (
    <Input
      {...inputStyle(isFilled, !!LeftComponent)}
      _hover={!isDisabled && hoverStyle}
      _focus={focusStyle}
      placeholder={placeholder}
      value={value}
      keyboardType={numeric && 'number-pad'}
      onChangeText={onChangeTextHandler}
      onBlur={onBlur}
      onFocus={onFocus}
      leftElement={LeftComponent}
      rightElement={RightComponent}
      isDisabled={isDisabled}
    />
  )
}

CustomTextInput.defaultProps = {
  value: undefined,
  onChangeText: undefined,
  placeholder: undefined,
  onBlur: undefined,
  onFocus: undefined,
  LeftComponent: undefined,
  numeric: false,
  RightComponent: undefined,
  isDisabled: false
}

export { CustomTextInput }
