import React from 'react'
import Svg, { Path, Circle, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const EyeOpenedIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...iconProps}>
    <Circle cx="9" cy="9" r="9" fill="#B21B55" />
    <Path
      // eslint-disable-next-line max-len
      d="M14.1398 8.50898C13.038 5.9508 10.9653 4.36353 8.72891 4.36353C6.49254 4.36353 4.41982 5.9508 3.318 8.50898C3.28796 8.5778 3.27246 8.65208 3.27246 8.72716C3.27246 8.80225 3.28796 8.87653 3.318 8.94534C4.41982 11.5035 6.49254 13.0908 8.72891 13.0908C10.9653 13.0908 13.038 11.5035 14.1398 8.94534C14.1699 8.87653 14.1854 8.80225 14.1854 8.72716C14.1854 8.65208 14.1699 8.5778 14.1398 8.50898ZM8.72891 11.9999C6.99982 11.9999 5.36345 10.7508 4.41982 8.72716C5.36345 6.70353 6.99982 5.45443 8.72891 5.45443C10.458 5.45443 12.0944 6.70353 13.038 8.72716C12.0944 10.7508 10.458 11.9999 8.72891 11.9999ZM8.72891 6.54534C8.29738 6.54534 7.87555 6.67331 7.51675 6.91305C7.15796 7.15279 6.87831 7.49354 6.71317 7.89222C6.54803 8.29089 6.50483 8.72958 6.58901 9.15281C6.6732 9.57605 6.881 9.96481 7.18613 10.2699C7.49126 10.5751 7.88002 10.7829 8.30326 10.8671C8.72649 10.9512 9.16518 10.908 9.56385 10.7429C9.96253 10.5778 10.3033 10.2981 10.543 9.93932C10.7828 9.58052 10.9107 9.15869 10.9107 8.72716C10.9107 8.14851 10.6809 7.59355 10.2717 7.18438C9.86252 6.77521 9.30756 6.54534 8.72891 6.54534ZM8.72891 9.81807C8.51315 9.81807 8.30223 9.75409 8.12283 9.63422C7.94343 9.51435 7.80361 9.34397 7.72104 9.14463C7.63847 8.9453 7.61687 8.72595 7.65896 8.51434C7.70105 8.30272 7.80495 8.10834 7.95752 7.95577C8.11008 7.80321 8.30447 7.69931 8.51608 7.65721C8.7277 7.61512 8.94704 7.63673 9.14638 7.71929C9.34572 7.80186 9.51609 7.94169 9.63596 8.12109C9.75584 8.30048 9.81982 8.5114 9.81982 8.72716C9.81982 9.01649 9.70488 9.29397 9.5003 9.49855C9.29571 9.70314 9.01823 9.81807 8.72891 9.81807Z"
      fill="white"
    />
  </Svg>
)

EyeOpenedIcon.defaultProps = {
  iconProps: {}
}

export default EyeOpenedIcon
