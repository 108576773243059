import styled from 'styled-components/native'
import { Text, View, VStack } from 'native-base'
import colors from '@instaseat/lib/themes/colors'
import { TouchableOpacity } from 'react-native'

export const Container = styled(VStack)`
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  background-color: ${colors.white};
`

export const InformationWrapper = styled(VStack).attrs({ space: 'xl' })`
  width: 100%;
  align-items: center;
`

export const FieldContainer = styled(View)`
  width: 100%;
  max-width: 270px;
`

export const Label = styled(Text)`
  font-family: "Lato";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  opacity: 0.5;
  margin-bottom: 10px;
`

export const LogOutButton = styled(TouchableOpacity)`
  width: 100%;
  max-width: 270px;
  height: 40px;
  background: ${colors.white};
  border-width: 0.5px;
  border-color: "#210F0B";
  justify-content: center;
  border-radius: 12px;
`

export const LogOutText = styled(Text)`
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`
