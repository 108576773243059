import React from 'react'
import { CustomModal } from '../shared/modal'
import TurnDetailModalContent from './content/Content'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import SolidLogoIcon from '@instaseat/lib/assets/icons/SolidLogoIcon'

const header = {
  title: 'Turn Detail',
  IconComponent: <SolidLogoIcon />
}

const TurnDetailModal = () => {
  const showTurnDetailModal = useAppSelector((store) => store.modals.showModal) === 'turnDetail'
  const { isFetching, turnDetails } = useAppSelector((store) => store.turns)
  const dispatch = useAppDispatch()
  return (
    <CustomModal
      showModal={showTurnDetailModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={(
        <TurnDetailModalContent
          isLoading={isFetching}
          data={turnDetails}
        />
        )}
    />
  )
}

export { TurnDetailModal }
