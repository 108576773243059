/* eslint-disable max-len */
import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
import colors from '../../themes/colors'

const SolidLogoIcon = (props: SvgProps) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <Path fillRule="evenodd" clipRule="evenodd" d="M3.33333 0C1.49238 0 0 1.49238 0 3.33333V12.6667C0 14.5076 1.49238 16 3.33333 16H12.6667C14.5076 16 16 14.5076 16 12.6667V3.33333C16 1.49238 14.5076 0 12.6667 0H3.33333ZM10.8853 3.55764C11.07 3.43426 11.287 3.36841 11.5091 3.36841C11.8069 3.36841 12.0925 3.4867 12.3031 3.69727C12.5136 3.90784 12.6319 4.19343 12.6319 4.49121C12.6319 4.71329 12.5661 4.93037 12.4427 5.11501C12.3193 5.29966 12.144 5.44357 11.9388 5.52855C11.7336 5.61354 11.5079 5.63577 11.2901 5.59245C11.0723 5.54912 10.8722 5.44219 10.7152 5.28516C10.5581 5.12813 10.4512 4.92807 10.4079 4.71026C10.3646 4.49246 10.3868 4.2667 10.4718 4.06154C10.5568 3.85637 10.7007 3.68101 10.8853 3.55764ZM8.28154 12.3509L3.93066 8.00194L8.28154 3.64914L12.6324 8.00194L8.28154 12.3509ZM4.7725 10.1052C4.55043 10.1052 4.33335 10.1711 4.1487 10.2945C3.96406 10.4178 3.82015 10.5932 3.73516 10.7984C3.65018 11.0035 3.62794 11.2293 3.67127 11.4471C3.71459 11.6649 3.82153 11.8649 3.97856 12.022C4.13558 12.179 4.33565 12.2859 4.55345 12.3293C4.77126 12.3726 4.99701 12.3504 5.20218 12.2654C5.40735 12.1804 5.5827 12.0365 5.70608 11.8518C5.82946 11.6672 5.89531 11.4501 5.89531 11.228C5.89531 10.9302 5.77701 10.6447 5.56644 10.4341C5.35588 10.2235 5.07029 10.1052 4.7725 10.1052Z" fill={colors.primary} />
  </Svg>
)

export default SolidLogoIcon
