import React, { useRef } from 'react'
import { TextInput } from 'react-native'
import UnlockIcon from '@instaseat/lib/assets/icons/UnlockIcon'
import { IHostPasswordSubmitData } from '@instaseat/lib/interfaces/host'
import { useDispatch, useSelector } from 'react-redux'
import ForgotModalContent from './content'
import ForgotPasswordSuccess from './content/success'
import ForgotPasswordError from './content/error'
import { CustomModal } from '../shared/modal'
import { hideModal } from 'store/modules/Modals'
import { clearErrors, forgotPassword, signOutThunk } from 'store/modules/auth'
import { RootState } from 'store'
import { useAppSelector } from 'hooks/redux'

const ForgotPasswordModal = () => {
  const showForgotPasswordModal = useSelector((store: RootState) => store.modals.showModal) === 'forgotPassword'
  const { isFetching, error, success: hasSuccess } = useSelector((store: RootState) => store.auth.forgotPassword)
  const { isSignedIn } = useSelector((store: RootState) => store.auth)
  const dispatch = useDispatch()
  const textInputRef = useRef<TextInput>(null)
  const { headerText, buttonText } = useAppSelector((store) => store.modals.modalText)

  const header = {
    title: headerText || 'Recover password',
    IconComponent: <UnlockIcon />
  }

  const handleSubmit = (data: IHostPasswordSubmitData) => {
    dispatch(forgotPassword({ email: data.email }))
  }

  const handleOnCloseModal = () => {
    dispatch(hideModal())
    dispatch(clearErrors())
    if (hasSuccess && isSignedIn) return dispatch(signOutThunk())
  }

  const getBodyContent = () => {
    if (hasSuccess) {
      return (<ForgotPasswordSuccess customText={headerText} onNextClick={handleOnCloseModal} />)
    } else {
      if (error) {
        return (<ForgotPasswordError onTryAgainClick={handleOnCloseModal} />)
      } else {
        return (
          <ForgotModalContent
            isFetching={isFetching}
            buttonText={buttonText}
            error={error}
            ref={textInputRef}
            submit={handleSubmit}
          />
        )
      }
    }
  }

  return (
    <CustomModal
      showModal={showForgotPasswordModal}
      onClose={handleOnCloseModal}
      header={header}
      BodyComponent={getBodyContent()}
      initialRef={textInputRef}
      closeOnOverlayClick={false}
    />
  )
}

export { ForgotPasswordModal }
