export enum TurnStatus {
  customerWaiting='customerWaiting',
  pendingConfirmation='pendingConfirmation',
  customerOnItsWay='customerOnItsWay',
  seated='seated',
  canceledByCustomer='canceledByCustomer',
  canceledByHost='canceledByHost',
  deleted='deleted'
}

export const TurnStatusToShow = {
  customerWaiting: 'Customer waiting',
  pendingConfirmation: 'Pending confirmation',
  customerOnItsWay: 'Customer on its way',
  seated: 'Seated',
  canceledByCustomer: ' Canceled by customer',
  canceledByHost: 'Canceled by host',
  deleted: 'Deleted'
}

export const TurnTypesToShow = {
  free: 'Free',
  VIP: 'VIP'
}

export enum TurnTypes {
  free= 'free',
  VIP= 'VIP',
}
