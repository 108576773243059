import React from 'react'
import { Center } from 'native-base'
import colors from '@instaseat/lib/themes/colors'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { ModalButton } from 'components/shared/modalButton'
import { ContentWrapper, Label } from './styled'
import { useDispatch } from 'react-redux'
import { signOutThunk } from 'store/modules/auth'

interface ForgotPasswordSuccessProps {
  onNextClick: () => void;
  customText: string;
}

const ForgotPasswordSuccess = ({ onNextClick, customText }: ForgotPasswordSuccessProps) => {
  const dispatch = useDispatch()
  const handleButton = () => {
    if (customText) {
      dispatch(signOutThunk())
    }
    onNextClick()
  }

  return (
    <ContentWrapper space="24px">
      <Center h="70%">
        <CustomIcons
          iconName={IconName.CheckIcon}
          iconProps={{
            fill: colors.success,
            width: 40,
            height: 40
          }}
        />
        <Label style={{ marginTop: 20 }}>
          You will receive an email to change your password.
        </Label>
      </Center>
      <ModalButton onPress={handleButton} text={customText ? 'Log Out' : 'Next'} />
    </ContentWrapper>
  )
}

ForgotPasswordSuccess.displayName = 'ForgotPasswordSuccess'

export default ForgotPasswordSuccess
