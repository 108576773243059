import React, { useMemo } from 'react'
import { CustomModal } from '../shared/modal'
import RemoveVIPModalContent from './content/Content'
import { useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import { removeEmptyVIP } from 'store/modules/Turns'
import { useDispatch } from 'store'
import { showToast } from 'utils/toastHandler'
import { ToastStatus } from 'components/customToast'
import RemoveIcon from '@instaseat/lib/assets/icons/RemoveIcon'

const header = {
  title: 'Remove Empty VIP',
  IconComponent: <RemoveIcon iconProps={{ width: '20px' }} />
}

const RemoveVIPModal = () => {
  const showRemoveVIPModal = useAppSelector((store) => store.modals.showModal) === 'removeVIP'
  const waitlistId = useAppSelector((store) => store.waitlist.selectedTurn?.waitlistId)?.toString()
  const waitTime = useAppSelector((store) => store.waitlist.selectedTurn?.waitTime) || 0
  const isLoading = useAppSelector((store) => store.turns.isFetching)
  const dispatch = useDispatch()

  const formattedWaitTime = useMemo(() => waitTime + ' min', [waitTime])

  const onPress = async () => {
    const success = waitlistId && await dispatch(removeEmptyVIP(waitlistId))
    if (!success) {
      showToast({
        title: 'You can\'t remove this VIP Window right now!',
        description: 'This action cannot be done.',
        status: ToastStatus.error
      })
    } else {
      showToast({
        title: 'Empty VIP Removed',
        description: `VIP Window with waitime of ${formattedWaitTime} was removed`,
        status: ToastStatus.success
      })
    }
    dispatch(hideModal())
  }

  return (
    <CustomModal
      showModal={showRemoveVIPModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={(
        <RemoveVIPModalContent
          onPress={onPress}
          isLoading={isLoading}
          waitTime={formattedWaitTime}
          isVisible={showRemoveVIPModal}
        />
      )}
    />
  )
}

export { RemoveVIPModal }
