import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const DragIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M5.84634 3.812L10.0275 0L14.2597 3.862C14.1203 3.873 14.0471 3.883 13.9748 3.883H11.808C11.5492 3.883 11.5332 3.898 11.5332 4.16C11.5332 5.51533 11.5332 6.871 11.5332 8.227C11.5332 8.49 11.5593 8.513 11.8281 8.513H15.8658C16.1547 8.513 16.1587 8.508 16.1597 8.213C16.1597 7.49967 16.1597 6.78633 16.1597 6.073C16.1597 5.993 16.1697 5.912 16.1757 5.831L16.2409 5.799C16.3503 5.922 16.4576 6.047 16.569 6.168L19.8252 9.731C20.0559 9.983 20.0589 9.986 19.8302 10.237C18.6746 11.5037 17.518 12.7703 16.3603 14.037C16.3192 14.083 16.269 14.121 16.1858 14.196C16.1747 14.08 16.1607 14.002 16.1597 13.924C16.1597 13.204 16.1537 12.484 16.1597 11.764C16.1597 11.564 16.0965 11.493 15.8919 11.494C14.5316 11.501 13.1723 11.494 11.8121 11.494C11.5452 11.494 11.5161 11.523 11.5161 11.784C11.5161 13.1327 11.5161 14.4813 11.5161 15.83C11.5161 16.088 11.5332 16.104 11.795 16.104H13.9628C14.036 16.104 14.1093 16.113 14.2427 16.121L10.0094 19.999L5.78114 16.134C5.9286 16.12 6.00785 16.106 6.08208 16.106C6.80501 16.106 7.52761 16.106 8.24988 16.106C8.50869 16.106 8.52474 16.089 8.52574 15.829C8.52574 14.4803 8.52574 13.1317 8.52574 11.783C8.52574 11.521 8.50067 11.496 8.2248 11.496H4.17409C3.91227 11.496 3.89723 11.512 3.89723 11.773C3.89723 12.493 3.89723 13.213 3.89723 13.934C3.89723 14.006 3.88519 14.078 3.87415 14.209L0 9.991L3.86914 5.775C3.88218 5.913 3.89622 5.992 3.89622 6.07C3.89622 6.797 3.90324 7.525 3.89622 8.252C3.89622 8.452 3.96343 8.52 4.16507 8.519C5.53202 8.51233 6.89931 8.51233 8.26693 8.519C8.46255 8.519 8.52875 8.446 8.52775 8.257C8.52374 6.88033 8.52374 5.50367 8.52775 4.127C8.52775 3.942 8.46054 3.88 8.27797 3.882C7.54868 3.889 6.81839 3.882 6.0891 3.882C6.01587 3.882 5.94164 3.873 5.8674 3.869L5.84634 3.812Z"
      fill={iconProps?.fill || '#210f0b9e'}
      fillOpacity="0.7"
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
  </Svg>
)

DragIcon.defaultProps = {
  iconProps: {}
}

export default DragIcon
