import React from 'react'
import { AddTurnForm } from './addTurnForm'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import PlusIcon from '@instaseat/lib/assets/icons/PlusIcon2'
import { AddTurnModalLayout } from './layout'

const header = {
  title: 'Add Customer',
  IconComponent: <PlusIcon />
}

const AddTurnModal = () => {
  const showAddTurnModal = useAppSelector((store) => store.modals.showModal) === 'addTurn'
  const dispatch = useAppDispatch()

  return (
    <AddTurnModalLayout
      showModal={showAddTurnModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={<AddTurnForm isVisible={showAddTurnModal} />}
      closeOnOverlayClick={false}
    />
  )
}

export { AddTurnModal }
