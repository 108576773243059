import React from 'react'
import SplashScreenWeb from '../../assets/logos/Splashscreen'
import { useDispatch } from 'react-redux'
import { signOutThunk } from 'store/modules/auth'
import { ButtonText, SignOutButton, Title, Wrapper } from './styled'
import { useSelector } from 'store'

const UnitDisabledScreen = () => {
  const dispatch = useDispatch()
  const { name } = useSelector(store => store.host.profileData.unit)
  return (
    <>
      <SplashScreenWeb />
      <Wrapper>
        <Title>
          {name}
          {' '}
          is disabled.
          If you consider that this is an error, please send an email to contact@instaseat.com
        </Title>
        <SignOutButton
          onPress={() => dispatch(signOutThunk())}
        >
          <ButtonText>Sign Out</ButtonText>
        </SignOutButton>
      </Wrapper>
    </>
  )
}

export { UnitDisabledScreen }
