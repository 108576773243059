import { action } from 'typesafe-actions'
import { AuthHostUser } from '@instaseat/lib/interfaces/auth-user'
import { AuthActionConsts, ISignInFormFulfilled } from './types'
import { Host } from '@instaseat/lib/interfaces/host'

export const setIsSignedIn = (data: boolean) =>
  action(AuthActionConsts.SET_IS_SIGNED_IN, data)

export const signOut = () =>
  action(AuthActionConsts.SIGN_OUT)

export const saveUserInformation = (data: AuthHostUser) =>
  action(AuthActionConsts.SAVE_USER_INFORMATION, data)

export const saveFirebaseUID = (data: string) =>
  action(AuthActionConsts.SAVE_FIREBASE_UID, data)

export const signInHostPending = () =>
  action(AuthActionConsts.SIGN_IN_HOST_PENDING)

export const signInHostFulfilled = (data: ISignInFormFulfilled) =>
  action(AuthActionConsts.SIGN_IN_HOST_FULFILLED, data)

export const signInHostRejected = (error: string) =>
  action(AuthActionConsts.SIGN_IN_HOST_REJECTED, error)

export const clearSignInHostFlags = () =>
  action(AuthActionConsts.RESET_SIGN_IN_HOST_FLAGS)

export const forgotPasswordPending = () =>
  action(AuthActionConsts.FORGOT_PASSWORD_PENDING)

export const forgotPasswordFulfilled = () =>
  action(AuthActionConsts.FORGOT_PASSWORD_FULFILLED)

export const forgotPasswordRejected = (error: string) =>
  action(AuthActionConsts.FORGOT_PASSWORD_REJECTED, error)

export const getUserDataPending = () =>
  action(AuthActionConsts.GET_USER_DATA_PENDING)

export const getUserDataFulfilled = (data: Host) =>
  action(AuthActionConsts.GET_USER_DATA_FULFILLED, data)

export const getUserDataRejected = (error: string) =>
  action(AuthActionConsts.GET_USER_DATA_REJECTED, error)

export const clearErrors = () =>
  action(AuthActionConsts.RESET_FORGOT_PASSWORD_FLAGS)
