import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { signInHostWithCredentials, forgotPassword } from './thunks'
import { Host } from '@instaseat/lib/interfaces/host'

export enum AuthActionConsts {
  SIGN_IN_HOST_PENDING='SIGN_IN_HOST_PENDING',
  SIGN_IN_HOST_FULFILLED='SIGN_IN_HOST_FULFILLED',
  SIGN_IN_HOST_REJECTED='SIGN_IN_HOST_REJECTED',
  RESET_SIGN_IN_HOST_FLAGS='RESET_SIGN_IN_HOST_FLAGS',
  SET_IS_SIGNED_IN='SET_IS_SIGNED_IN',
  SIGN_OUT='SIGN_OUT',
  SAVE_USER_INFORMATION='SAVE_USER_INFORMATION',
  SAVE_FIREBASE_UID='SAVE_FIREBASE_UID',
  GET_USER_DATA_PENDING='GET_USER_DATA_PENDING',
  GET_USER_DATA_FULFILLED='GET_USER_DATA_FULFILLED',
  GET_USER_DATA_REJECTED='GET_USER_DATA_REJECTED',
  FORGOT_PASSWORD_PENDING='FORGOT_PASSWORD_PENDING',
  FORGOT_PASSWORD_FULFILLED='FORGOT_PASSWORD_FULFILLED',
  FORGOT_PASSWORD_REJECTED='FORGOT_PASSWORD_REJECTED',
  RESET_FORGOT_PASSWORD_FLAGS='RESET_FORGOT_PASSWORD_FLAGS'
}

export type AuthActionTypes = ActionType<typeof actions>;
export type SignOutActionType = ActionType<typeof actions.signOut>
export type AuthThunksTypes = typeof signInHostWithCredentials
export type AuthThunkForgotPasswordType = typeof forgotPassword

export interface AuthState {
  readonly isSignedIn: boolean | null;
  readonly isFetching: boolean;
  readonly error: string;
  readonly user?: {
    firstName?: string;
    lastName?: string;
    email: string;
    firebaseUID: string;
  };
  readonly forgotPassword: {
    isFetching: boolean;
    error: string;
    success: boolean;
  };
  readonly currentUser?: Host;
}

export type ISignInFormFulfilled = {
  email: string;
  firebaseUID: string;
}
