import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
interface props {
  iconProps?: SvgProps
}

const CustomersIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M9.00522 2.81499C9.00351 3.36956 8.8375 3.91118 8.52815 4.37146C8.2188 4.83174 7.77999 5.19002 7.26714 5.40106C6.75429 5.6121 6.1904 5.66643 5.64669 5.55719C5.10299 5.44795 4.60385 5.18004 4.21231 4.78729C3.82077 4.39455 3.5544 3.89458 3.44684 3.35054C3.33928 2.8065 3.39534 2.24278 3.60796 1.73059C3.82058 1.21839 4.18022 0.78069 4.64145 0.472762C5.10267 0.164834 5.64481 0.000490923 6.19938 0.000488281C6.56843 0.00100909 6.93376 0.0742263 7.27451 0.215959C7.61526 0.357691 7.92475 0.565164 8.18531 0.826524C8.44586 1.08788 8.65238 1.39801 8.79306 1.7392C8.93374 2.08038 9.00584 2.44594 9.00522 2.81499"
      fillOpacity="0.2"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Path
      // eslint-disable-next-line max-len
      d="M12.0662 10.3961C12.0668 10.7504 11.9265 11.0905 11.6764 11.3414C11.4262 11.5923 11.0867 11.7336 10.7323 11.7342H1.66701C1.49173 11.7344 1.31814 11.6998 1.15627 11.6326C0.994404 11.5653 0.847458 11.4667 0.723915 11.3423H0.723313C0.484811 11.1026 0.3457 10.7815 0.333834 10.4435C0.321969 10.1056 0.438227 9.77556 0.659327 9.51965C0.659734 9.51882 0.660304 9.51808 0.661008 9.51748L0.663658 9.51484L0.7879 9.38999L4.31661 5.8501H4.31721L5.25767 6.7938L6.19968 7.73894L7.14181 6.79428L8.08275 5.8501L11.7272 9.5057C11.7287 9.50721 11.7301 9.50881 11.7314 9.51051C11.9475 9.75482 12.0664 10.0699 12.0656 10.3961"
      fillOpacity="0.2"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Path
      // eslint-disable-next-line max-len
      d="M15.5742 3.51861C15.572 4.21183 15.3645 4.88886 14.9778 5.4642C14.5911 6.03955 14.0426 6.4874 13.4016 6.7512C12.7605 7.015 12.0556 7.08292 11.376 6.94637C10.6964 6.80982 10.0724 6.47493 9.58302 5.984C9.09359 5.49306 8.76063 4.86811 8.62618 4.18805C8.49172 3.508 8.56181 2.80336 8.82758 2.16311C9.09336 1.52287 9.5429 0.975741 10.1194 0.590831C10.696 0.20592 11.3736 0.000491583 12.0669 0.000488281C12.5282 0.00113929 12.9848 0.0926608 13.4108 0.269827C13.8367 0.446992 14.2236 0.706333 14.5493 1.03303C14.875 1.35973 15.1331 1.74739 15.309 2.17387C15.4848 2.60035 15.5749 3.0573 15.5742 3.51861"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
    <Path
      // eslint-disable-next-line max-len
      d="M19.3999 12.995C19.4006 13.4379 19.2253 13.863 18.9126 14.1766C18.5999 14.4903 18.1755 14.6669 17.7326 14.6676H6.4009C6.1818 14.6679 5.96482 14.6247 5.76248 14.5406C5.56014 14.4565 5.37646 14.3332 5.22203 14.1778H5.22128C4.92315 13.8781 4.74926 13.4768 4.73443 13.0543C4.7196 12.6318 4.86492 12.2193 5.1413 11.8994C5.14181 11.8984 5.14252 11.8975 5.1434 11.8967L5.14671 11.8934L5.30201 11.7374L9.7129 7.3125H9.71365L10.8892 8.49212L12.0667 9.67355L13.2444 8.49273L14.4206 7.3125L18.9761 11.882C18.978 11.8839 18.9797 11.8859 18.9814 11.888C19.2515 12.1934 19.4002 12.5873 19.3992 12.995"
      fill={iconProps?.fill || '#210f0b9e'}
      stroke={iconProps?.fill !== 'transparent' ? 'transparent' : '#210f0b9e'}
      strokeMiterlimit="10"
    />
  </Svg>
)

CustomersIcon.defaultProps = {
  iconProps: {}
}

export default CustomersIcon
