import styled from 'styled-components/native'
import { View, Text, Pressable } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

export const VipActionRowContainer = styled(View)`
  width: 100%;
  height: 50px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${colors.primary}22;
`

export const ButtonContainer = styled(Pressable)`
  flex-basis: 45px;
  height: 45px;
  background-color: ${colors.bgGrey};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`

export const ButtonText = styled(Text)`
  padding-top: 3px;
  font-size: 10px;
  text-transform: capitalize;
  color: ${colors.textColor};
`
