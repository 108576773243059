import React from 'react'

import { ScreenContainer, ContentContainer } from './styled'

import WaitlistTable from './waitlistTable'

import UnitSideBarInfo from 'components/unitInfoSidebar'

const WaitlistScreen = () => {
  return (
    <ScreenContainer>
      <ContentContainer>
        <UnitSideBarInfo />
        <WaitlistTable />
      </ContentContainer>
    </ScreenContainer>
  )
}

export default WaitlistScreen
