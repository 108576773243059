import { io, Socket } from 'socket.io-client'
import { store } from 'store'
import { signOutThunk } from 'store/modules/auth'
import { getCurrentFirebaseToken } from './helpers/firebase'

let socket: Socket
let socketReconnectionAttempts = 1

export const socketInit = (token: string) => {
  const socketUrl: string = process.env.NODE_ENV === 'test' ? '' : process.env.REACT_APP_SOCKET_IO_URL as string
  socket = io(socketUrl, {
    auth: { token },
    transports: ['websocket']
  })

  socket.on('connect', () => {
    console.log('Socket.io connected 🟢')
  })

  socket.on('disconnect', () => {
    console.log('Waitlist screen disconnected to socket.io  🔴')
  })

  socket.on('connect_error', (error) => {
    console.info(`SOCKET IO Connection Error - ${error}`)
    if (error.message === 'Unauthorized') {
      if (socketReconnectionAttempts < 4) {
        return setTimeout(async () => {
          console.log('Socket.io reconnection attempt number ' + socketReconnectionAttempts)
          socket.auth = { token: await getCurrentFirebaseToken() }
          socket.connect()
          socketReconnectionAttempts++
        }, 500)
      }
      console.log('Socket.io disconnected')
      socketReconnectionAttempts = 1
      return store.dispatch(signOutThunk())
    }
  })

  socket.on('error', (error) => {
    console.info('SOCKET IO Server Error -', error)
  })
}

export const disconnectSocket = () => socket && socket.disconnect()

export { socket }
