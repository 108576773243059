import { Dispatch } from 'redux'
import { IHostSubmitData, IHostPasswordSubmitData } from '@instaseat/lib/interfaces/host'
import { ThunkAction } from '@instaseat/lib/types/redux-thunk'
import { auth } from 'utils/helpers/firebase'
import { signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { AuthActionTypes } from './types'
import {
  signInHostPending,
  signInHostFulfilled,
  signInHostRejected,
  signOut,
  forgotPasswordPending,
  forgotPasswordFulfilled,
  forgotPasswordRejected
} from './actions'

export const signInHostWithCredentials =
  (data: IHostSubmitData): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
    return async (dispatch: Dispatch) => {
      dispatch(signInHostPending())
      try {
        const response = await signInWithEmailAndPassword(auth, data.email, data.password)
        const user = response.user
        dispatch(signInHostFulfilled({
          email: user.email || '',
          firebaseUID: user.uid
        }))
      } catch (error) {
        dispatch(signInHostRejected('Authentication failed.'))
      }
    }
  }

export const forgotPassword =
  (data: IHostPasswordSubmitData): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
    return async (dispatch: Dispatch) => {
      dispatch(forgotPasswordPending())
      sendPasswordResetEmail(auth, data.email)
        .then(() => {
          dispatch(forgotPasswordFulfilled())
        })
        .catch((err) => {
          console.log(`forgotPassword error: ${err}`)
          dispatch(forgotPasswordRejected('There was an error trying to recover your password.'))
        })
    }
  }

export const signOutThunk = (): ThunkAction<void, unknown, unknown, AuthActionTypes> => {
  return async (dispatch: Dispatch) => {
    await getAuth().signOut()
    dispatch(signOut())
  }
}
