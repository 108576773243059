import React from 'react'
import { Toast } from 'native-base'
import { CustomToast, ToastStatus } from 'components/customToast'

export interface IShowAppToastProps {
  title: string,
  description: string;
  status?: ToastStatus
}

export const showToast = ({ title, description, status }: IShowAppToastProps) => {
  Toast.show({
    render: ({
      id
    }) => {
      return React.createElement(CustomToast, {
        id,
        title,
        description,
        status
      })
    }
  })
}
