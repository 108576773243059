const linking = {
  enabled: true,
  prefixes: [],
  config: {
    screens: {
      // Public
      Download: 'download',
      Terms: 'terms',
      Privacy: 'privacy',
      // Auth
      Auth: 'auth'
    }
  }
}

export default linking
