import React, { useCallback, useState } from 'react'
import { WarningIcon } from 'native-base'
import { Control, Controller, FieldValues, Path, UseFormClearErrors } from 'react-hook-form'
import { CustomTextInput } from 'components/shared/customTextInput'
import { ControllerInputWrapper, ErrorLabel, Label } from './styled'

export interface ControllerInputProps<TFormValues extends FieldValues> {
  control: Control<TFormValues>
  label?: string
  name: Path<TFormValues>
  placeholder?: string
  isInvalid?: boolean
  errorMsg?: string
  onFocus?: UseFormClearErrors<TFormValues>
  LeftComponent?: React.ReactElement
  RightComponent?: React.ReactElement
  numeric?: boolean
  isDisabled?: boolean
  reduceMarginBottom?: number
  errorOnBlur?: boolean
}

const ControllerInput = <TFormValues extends
{[key: string] : string | number | null | { [k: string]: boolean}}>({
    control,
    label,
    name,
    placeholder,
    isInvalid,
    errorMsg,
    onFocus,
    LeftComponent,
    RightComponent,
    numeric,
    isDisabled,
    reduceMarginBottom,
    errorOnBlur
  }:ControllerInputProps<TFormValues>) => {
  const [showError, setShowError] = useState(false)
  const onChangeText = useCallback((onChange) => (value: string) => {
    const filteredValue = numeric
      ? value.replace(/[^0-9]|^0*/g, '')
      : value.replace(/(^\s)|(\s(?=\s$))/g, '')
        .replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    onChange(filteredValue || '')
  }, [numeric])

  return (
    <ControllerInputWrapper
      isInvalid={isInvalid && (!errorOnBlur || showError)}
      reduceMarginBottom={reduceMarginBottom}
    >
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          const val = value == null ? '' : typeof value === 'string' ? value : value?.toString()
          return (
            <CustomTextInput
              placeholder={placeholder ?? label}
              onBlur={(e) => {
                const input = e.target as unknown as HTMLInputElement
                onChangeText(onChange)(input.value.trim())
                onChange(input.value)
                errorOnBlur && setShowError(true)
                onBlur()
              }}
              onFocus={() => {
                errorOnBlur && setShowError(false)
                onFocus?.(name)
              }}
              onChangeText={onChangeText(onChange)}
              value={val}
              LeftComponent={LeftComponent}
              RightComponent={RightComponent}
              numeric={numeric}
              isDisabled={isDisabled}
            />
          )
        }}
        rules={{ required: true }}
      />
      <ErrorLabel
        leftIcon={<WarningIcon size={4} />}
      >
        {errorMsg}
      </ErrorLabel>
    </ControllerInputWrapper>
  )
}

ControllerInput.defaultProps = {
  placeholder: undefined,
  errorMsg: undefined,
  isInvalid: false,
  onFocus: undefined,
  LeftComponent: undefined,
  numeric: false,
  RightComponent: undefined,
  label: undefined,
  isDisabled: false,
  reduceMarginBottom: undefined,
  errorOnBlur: true
}

export default ControllerInput
