import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'
import colors from '../../themes/colors'

interface props {
  iconProps?: SvgProps
}

const NotifyIcon = ({ iconProps }: props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...iconProps}>
    <Path
      // eslint-disable-next-line max-len
      d="M8.5 17.0002C13.1944 17.0002 17 13.1947 17 8.50024C17 3.80582 13.1944 0.000244141 8.5 0.000244141C3.80558 0.000244141 0 3.80582 0 8.50024C0 13.1947 3.80558 17.0002 8.5 17.0002ZM12.9366 5.4347C13.4172 4.37567 12.3179 3.2917 11.2657 3.78724L2.81889 7.76549C1.76776 8.26055 1.90166 9.79659 3.0226 10.1023L5.93403 10.8963L6.72015 13.7788C7.02817 14.9082 8.58056 15.0325 9.06436 13.9665L12.9366 5.4347ZM4.0715 8.83358L11.2896 5.43402L7.98492 12.7154L7.34359 10.3638C7.22713 9.93682 6.89354 9.60323 6.46653 9.48677L4.0715 8.83358Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={iconProps.fill ? iconProps.fill : colors.tertiary}
    />
  </Svg>
)

NotifyIcon.defaultProps = {
  iconProps: {}
}

export default NotifyIcon
