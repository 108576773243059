/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import colors from '@instaseat/lib/themes/colors'
import { View, Pressable, PresenceTransition } from 'native-base'
import { CustomerNameText, WaitlistTd } from './styled'
import { TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { returnTurnToWaitlist, saveTurnID } from 'store/modules/Turns'
import { setModalText, showModal } from 'store/modules/Modals'

interface Props {
  visible: boolean,
  onPress: () => void,
  turnId: string,
  firstName: string,
  lastName: string
}

const ReturnToWaitlistButton = ({ visible, onPress, turnId, firstName, lastName }: Props) => {
  const dispatch = useDispatch()

  const handleReturnTurn = () => {
    dispatch(saveTurnID(turnId))
    dispatch(setModalText({ contentText: `${firstName} ${lastName}`.trim() }))
    dispatch(showModal('return'))
  }

  return (
    <WaitlistTd colSpan={3} width='204px' height="50px">
      <PresenceTransition
        visible={visible}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 550
          }
        }}
      >
        <View
          w='100%'
          h='50px'
          flexDirection='row'
          justifyContent='space-around'
          alignItems='center'
          bg={colors.secondary}
        >
          <TouchableOpacity
            onPress={() => handleReturnTurn()}
          >
            <CustomerNameText style={{ textDecorationLine: 'underline' }}>
              Return to waitlist
            </CustomerNameText>
          </TouchableOpacity>
          <Pressable onPress={() => onPress()} p="10">
            <CustomIcons
              iconName={IconName.CancelledIcon}
              iconProps={{
                fill: colors.webModalGreyText,
                height: '14px',
                width: '14px'
              }}
            />
          </Pressable>
        </View>
      </PresenceTransition>
    </WaitlistTd>
  )
}

export default ReturnToWaitlistButton
