import styled from 'styled-components/native'
import { Box } from 'native-base'

export const Container = styled.View`
  align-items: center;
  background-color: white;
  justify-content: center;
  flex: 1;
  overflow-x: hidden;
`

export const Header = styled.View`
  align-items: center;
  background-color: white;
  margin-bottom: 0;
`

export const BoxStyled = styled(Box).attrs({
  borderColor: 'coolGray.50',
  borderRadius: 'lg',
  minH: '460px',
  minW: '475px',
  pb: '32px',
  pl: '100px',
  pr: '100px',
  pt: '24px'
})`
  box-shadow: 0px 0px 15px rgba(0,0,0, 0.2);
`
