import colors from '@instaseat/lib/themes/colors'
import React, { useEffect } from 'react'
import {
  Container,
  SaveChangesButtonContainer
} from './styled'
import { useAppSelector } from 'hooks/redux'
import { getProfileData, updateTurnPreferences, updateUnit } from 'store/modules/Hosts'
import WaitlistParameters from './waitlist-parameters'
import BusinessData from './business-data'
import PrioritySettingsParameters from './priority-settings-parameters'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, View } from 'native-base'
import { showModal } from 'store/modules/Modals'
import { useDispatch } from 'store'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { UserTypes } from '@instaseat/lib/enums/users'
import { useGetUserType } from 'utils/helpers/firebase'

export type SettingsFormValues = {
  courtesyTime: number;
  fourteenTopsOrLessQuote: number;
  fourTopsOrLessDeviation: number;
  fifteenOrMoreTopsQuote: number;
  fifteenOrMoreTopsDeviation: number;
  windowsAvailable: number;
  price: number;
  businessAddress: string;
  roundingValue: number;
}

interface Props {
  navigation: {
    replace: (value: string) => void
  }
}

const validator = yup.object({
  price: yup
    .string()
    .required('This field is required')
    .matches(
      /^(\d*\.{0,1}\d{0,2}$)/,
      'Only numbers up to two decimals are allowed'
    ),
  courtesyTime: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .max(999, 'This value is too long'),
  fourteenTopsOrLessQuote: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .max(999, 'This value is too long'),
  fourTopsOrLessDeviation: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .max(999, 'This value is too long'),
  fifteenOrMoreTopsQuote: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .max(999, 'This value is too long'),
  fifteenOrMoreTopsDeviation: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .max(999, 'This value is too long'),
  windowsAvailable: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .max(99, 'This value is too long'),
  businessAddress: yup
    .string()
    .required('This field is required')
    .max(100, 'This value is too long'),
  roundingValue: yup
    .number()
    .required('This field is required')
    .typeError('This field must be a number')
    .min(1)
})

const Settings = ({ navigation }: Props) => {
  const dispatch = useDispatch()
  const scheduleData = useAppSelector((store) => store?.host?.scheduleData)
  const isScheduleEdited = useAppSelector((store) => store?.host?.isScheduleEdited)
  const formData = useAppSelector((store) => store?.host?.profileData)
  const isLoading = useAppSelector((store) => store?.host?.isLoading)
  const userType = useGetUserType()

  const handleScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    dispatch(getProfileData())
    dispatch(setSelectedHistoryTurn(''))
  }, [dispatch])

  const defaultValues = {
    courtesyTime: formData?.unit?.turnPreferences?.courtesyTime || 0,
    fourteenTopsOrLessQuote: formData?.unit?.turnPreferences?.fourteenTopsOrLessQuote || 0,
    fourTopsOrLessDeviation: formData?.unit?.turnPreferences?.fourTopsOrLessDeviationTime || 0,
    fifteenOrMoreTopsQuote: formData?.unit?.turnPreferences?.fifteenTopsOrMoreQuote || 0,
    fifteenOrMoreTopsDeviation: formData?.unit?.turnPreferences?.fifteenTopsOrMoreDeviationTime || 0,
    windowsAvailable: formData?.unit?.turnPreferences?.maxVipPlaces || 0,
    price: formData?.unit?.turnPreferences?.vipPrice?.amount / 100 || 0,
    businessAddress: formData?.unit?.address || '',
    businessHours: scheduleData || [],
    roundingValue: formData.unit.roundingValue || 0
  }

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
    resolver: yupResolver(validator)
  })

  const { reset } = methods

  useEffect(() => {
    if (userType && userType !== UserTypes.manager) {
      navigation.replace('WaitlistScreen')
    }
  }, [userType])

  useEffect(() => {
    reset(defaultValues)
  }, [formData])

  const onSubmit = async (data: SettingsFormValues) => {
    const turnPayload = {
      courtesyTime: Number(data.courtesyTime),
      maxVipPlaces: Number(data.windowsAvailable),
      vipPrice: {
        amount: Number(data.price) * 100,
        currency: 'USD'
      },
      fourteenTopsOrLessQuote: Number(data.fourteenTopsOrLessQuote),
      fifteenTopsOrMoreQuote: Number(data.fifteenOrMoreTopsQuote),
      fourTopsOrLessDeviationTime: Number(data.fourTopsOrLessDeviation),
      fifteenTopsOrMoreDeviationTime: Number(data.fifteenOrMoreTopsDeviation)
    }

    const unitPayload = {
      ...formData.unit,
      turnPreferences: formData?.unit?.turnPreferences?._id,
      roundingValue: data.roundingValue,
      address: data.businessAddress,
      businessHours: scheduleData
    }

    const responseUnit: string = await dispatch(updateUnit(formData?.unit?._id, unitPayload))
    const responseTurnPreferences: string =
      await dispatch(updateTurnPreferences(formData?.unit?.turnPreferences?._id, turnPayload))

    if (responseUnit === 'UPDATED' && responseTurnPreferences === 'UPDATED') {
      dispatch(showModal('success'))
      dispatch(getProfileData())
    } else {
      dispatch(showModal('error'))
    }
    handleScroll()
  }

  const onSubmitError = () => {
    throw new Error('Submit Error')
  }

  const { isDirty, errors } = methods?.formState

  const isDisabled = !isScheduleEdited && (!isDirty || Object.entries(errors).length > 0)

  if (userType !== UserTypes.manager) return <View />

  return (
    <Container h="100%" bg={colors.white}>
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <WaitlistParameters />
        <BusinessData />
        <PrioritySettingsParameters />
        <SaveChangesButtonContainer>
          <Button
            isLoading={isLoading}
            borderRadius="12px"
            w="260px"
            opacity={isDisabled ? '0.6' : 'none'}
            disabled={isDisabled}
            onPress={methods.handleSubmit(onSubmit, onSubmitError)}
          >
            Save changes
          </Button>
        </SaveChangesButtonContainer>
      </FormProvider>
    </Container>
  )
}

export default Settings
