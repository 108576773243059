import colors from '@instaseat/lib/themes/colors'
import { ScrollView, View } from 'native-base'
import styled from 'styled-components/native'

export const Container = styled(ScrollView)`
  background-color: ${colors.white};
  height: 100;
  padding: 48px 30px;
`
export const SaveChangesButtonContainer = styled(View)`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 40px;
`
