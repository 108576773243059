import { action } from 'typesafe-actions'
import * as Constants from './constants'
import { HostProfileData, ManagerProfileData, ScheduleDataPayload } from './types'

export const getProfileDataPending = () =>
  action(Constants.GET_PROFILE_DATA_PENDING)

export const getProfileDataCompleted = (data: HostProfileData & ManagerProfileData) =>
  action(Constants.GET_PROFILE_DATA_COMPLETED, data)

export const getProfileDataRejected = (error: unknown) =>
  action(Constants.GET_PROFILE_DATA_REJECTED, error)

export const updateTurnPreferencesPending = () =>
  action(Constants.UPDATE_TURN_PREFERENCES_DATA_PENDING)

export const updateTurnPreferencesCompleted = (
  data: HostProfileData & ManagerProfileData
) => action(Constants.UPDATE_TURN_PREFERENCES_DATA_COMPLETED, data)

export const updateTurnPreferencesRejected = (error: unknown) =>
  action(Constants.UPDATE_TURN_PREFERENCES_DATA_REJECTED, error)

export const updateUnitPending = () =>
  action(Constants.UPDATE_UNIT_DATA_PENDING)

export const updateUnitCompleted = (data: HostProfileData & ManagerProfileData) =>
  action(Constants.UPDATE_UNIT_DATA_COMPLETED, data)

export const updateUnitRejected = (error: unknown) =>
  action(Constants.UPDATE_UNIT_DATA_REJECTED, error)

export const updateHostPending = () =>
  action(Constants.UPDATE_HOST_DATA_PENDING)

export const updateHostCompleted = (data: HostProfileData & ManagerProfileData) =>
  action(Constants.UPDATE_HOST_DATA_COMPLETED, data)

export const updateHostRejected = (error: unknown) =>
  action(Constants.UPDATE_HOST_DATA_REJECTED, error)

export const saveScheduleData = (data: ScheduleDataPayload[]) =>
  action(Constants.SAVE_SCHEDULE_DATA, data)

export const deleteSchedule = (data: string) =>
  action(Constants.DELETE_SCHEDULE, data)

export const isScheduleEdited = () =>
  action(Constants.IS_SCHEDULE_EDITED)
