
import styled from 'styled-components/native'
import webStyled from 'styled-components'
import { Text } from 'native-base'
import colors from '@instaseat/lib/themes/colors'

const customColors = {
  tdTextColor: '#707070'
}

interface TrProps {
  display?: string
  shadow?: boolean
  bordered?: boolean
}

export const VipWaitlistTr = webStyled.tr<TrProps>`
  display: ${(props) => props.display || 'table-row'};
  border: ${(props) => props.bordered ? `1px solid ${colors.tertiary}` : 0};
  color: ${colors.textColor};
  text-transform: capitalize;
  box-shadow: ${(props) => props.shadow ? '0px 0px 3px 0px rgba(0, 0, 0, 0.1)' : 'none'};
  box-sizing: border-box;
  background-color: ${colors.primary}1A;
`

export const EmptyVipText = styled(Text)`
  text-align: initial;
  font-family: 'Lato';
  color: ${customColors.tdTextColor};
  padding-left: 24px;
`
