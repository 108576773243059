import { sioHostWaitlist } from '@instaseat/lib/interfaces/sioSubscriptions'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { action } from 'typesafe-actions'
import * as Constants from './constants'
import { IHistoryFilter } from './types'

export const getWaitlistDataPending = () =>
  action(Constants.GET_WAITLIST_DATA_PENDING)

export const getWaitlistDataCompleted = (data: sioHostWaitlist) =>
  action(Constants.GET_WAITLIST_DATA_COMPLETED, data)

export const getWaitlistDataRejected = (error: unknown) =>
  action(Constants.GET_WAITLIST_DATA_REJECTED, error)

export const selectTurn = (data: PopulatedWaitlistItem) =>
  action(Constants.SELECT_TURN, data)

export const historyFilter = (data: IHistoryFilter) =>
  action(Constants.SET_HISTORY_FILTER, data)

export const sortWaitlistOrderPending = () =>
  action(Constants.SORT_WAITLIST_ORDER_PENDING)

export const sortWaitlistOrderCompleted = (data: PopulatedWaitlistItem[]) =>
  action(Constants.SORT_WAITLIST_ORDER_COMPLETED, data)

export const sortWaitlistOrderRejected = (error: unknown) =>
  action(Constants.SORT_WAITLIST_ORDER_REJECTED, error)

export const selectedHistoryTurn = (data: string) =>
  action(Constants.SET_SELECTED_HISTORY_TURN, data)
