import colors from '@instaseat/lib/themes/colors'
import { Button, Text } from 'native-base'
import styled from 'styled-components/native'

export const ModalPressable = styled(Button)`
  background-color: ${colors.primary};
  border-radius: 1vmax;
  align-self: center;
  width: 90%;
  color: white;
  text-align: center;
  padding-block: 10px;
`

export const ButtonText = styled(Text)`
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`
